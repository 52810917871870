import React, { useEffect, useState } from "react";
import TrustDesign from "../../SaveandEdit/Trustedby/trustedesing";
import Memecoin from "../Memecoin";
import Theme from "../../Theme";
import { useContext } from "react";
import MyContext from "../../../context/MyContext";
import ContentDesign from "../../ContentDesign";
import MainNavBar from "../../MainNavBar";
import aboutright from '../../../assets/images/aboutright.png'
import aboutleft from '../../../assets/images/aboutleft.png'
import { SketchPicker } from "react-color";
import MemeVisionDesing from "./Memecoinvisiondesing";
import axios from "axios";
import { useAccount } from 'wagmi';

const MemecoinAboutEdit = () => {

  const {
    memecoinname, SetMemecoinName,
    memecoinsymbol, setMemecoinSymbol,
    memecoinNavbar, setMemecoinNavbar,
    memecoinAbout, setMemecoinAbout,
    memeCaption, memecoinValue,
    mainnavbarbutton, contentdesing,
    memecoinavisionleftimage, setMemecoinavisionleftimage,
    memecoinavisionrightimage, setMemecoinavisionrightimage,
    newBackgroundcolor, setNewbackgroundcolor,
    memeaboutbgcolor, setMemeaboutbgcolor,
    memeaboutbgimage, setmemeAboutbgimage,
    setMemeaboutbackground, uniqueID, selectMemeCoinTemplates,
    template3heading, setTemplate3Heading,
    t5about1, setT5Aboutt1,
    t5about2, setT5About2,
    memetwitterlink,setMemetwitterlink,
    memetelegramlink,setMemetelegramlink,
    memeinstagramlink,setMemeinstagramlink,
    memewarpcastlink,setMemewarpcastlink,
    buy1,setBuy1,
    memeabout,setMemeabout,
    telegraminage,setTelegraminage,
      twitterimage,setTwitterimage,
      instagramimage,setInstagramimage,
      warpcastimage,setWarpcastimage,
      youtubeimage,setYoutubeimage,
      tiktokimage,setTiktokimage,
    

  } = useContext(MyContext);

  const [isHeaderDesignText, setisHeaderDesignText] = useState(false);

  // API to store meme about content data. For testing purpose using use effect

  const { address } = useAccount()

  useEffect(() => {
    const updateMemeAboutContentData = async () => {
      const response = await axios.post('https://trendifyweb.ai/pyapi/updateMemeAboutContentData', {
        params: {
          address: address,
          siteid: uniqueID,
          memecoinAbout: memecoinAbout,
          memecoinavisionleftimage: memecoinavisionleftimage,
          memecoinavisionrightimage: memecoinavisionrightimage,
          memeaboutbgcolor: memeaboutbgcolor,
          memeaboutbgimage: memeaboutbgimage,
          memecoinname: memecoinname,
          memecoinsymbol: memecoinsymbol, 
          template3heading: template3heading,
          t5about1: t5about1,
          t5about2: t5about2,
          memetwitterlink: memetwitterlink,
          memetelegramlink: memetelegramlink,
          memeinstagramlink: memeinstagramlink,
          memewarpcastlink: memewarpcastlink
        }
      })
      console.log('memeAboutContentAPIresponse', response)
    }
    updateMemeAboutContentData()
  }, [memeaboutbgcolor])




  useEffect(() => {
    function handleClickOutside(event) {
      const target = event.target;
      const divToExclude = document.getElementById('one');

      // Check if the click occurred outside of the specific div
      if (divToExclude && !divToExclude.contains(target)) {
        setisHeaderDesignText(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isHeaderDesignText]);
  const HandelMemecoinAbout = (e) => {
    setMemecoinAbout(e.target.value)
  }
  const handleRightImage = (index) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...memecoinavisionrightimage];
        updatedTrustLogo[index] = event.target.result;
        setMemecoinavisionrightimage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };

  const handleLeftImage = (index) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...memecoinavisionleftimage];
        updatedTrustLogo[index] = event.target.result;
        setMemecoinavisionleftimage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };

  const handelchangecolor = (titlecolor) => {
    setMemeaboutbgcolor(titlecolor.hex)

  };
  const handeltitle = () => {
    setisHeaderDesignText(!isHeaderDesignText)
  }

  const handleBackgrounddImage = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...memeaboutbgimage];
        updatedTrustLogo[index] = event.target.result;
        setmemeAboutbgimage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  }

  const handleBuyImage = (index) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...buy1];
        updatedTrustLogo[index] = event.target.result;
        setBuy1(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };

  const handleTwitterImage = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...twitterimage];
        updatedTrustLogo[index] = event.target.result;
        setTwitterimage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };
  const handleTelegramImage = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...telegraminage];
        updatedTrustLogo[index] = event.target.result;
        setTelegraminage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  }

  const handleInastagramImage = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...instagramimage];
        updatedTrustLogo[index] = event.target.result;
        setInstagramimage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };
  const handlewarpcastimage = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...warpcastimage];
        updatedTrustLogo[index] = event.target.result;
        setWarpcastimage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  }

  return (
    <>
      <div className='container-fluid d-flex flex-column' style={{ height: '100vh', overflow: 'auto' }}>
        <div>
          <MainNavBar />
        </div>
        <div className='flex-grow-1 d-flex flex-row mt-1' style={{ overflow: 'hidden' }}>
          <div style={{ width: '400px' }} >
            {mainnavbarbutton == false ?
              <div className='editing_wrapper__Wijfx h-100  overflow-auto'>
                <ContentDesign headerText="About" />
                {contentdesing == false ?
                  <div >
                    {selectMemeCoinTemplates == 1 ? <div className="editing_content__RXzxk">
                      <div className="form_form__Sku4X" >
                        <div className="form_form__Sku4X">
                          <div className="form_group__kDXNb">
                            <div className="form_form__Sku4X">
                              <div class="form_row__2+BJ3">

                                <div className="form_group__kDXNb">
                                  <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                  <div class="form_field__d31bq" >
                                      <span>Heading</span>
                                      <textarea placeholder="Heading" style={{ height: '30px', }} value={memeabout} onChange={(e)=>setMemeabout(e.target.value)} >
                                      </textarea>
                                    </div>
                                    <div class="form_field__d31bq" >
                                      <span>Caption</span>
                                      <textarea placeholder="Caption" style={{ height: '220px', }} value={memecoinAbout} onChange={(e) => HandelMemecoinAbout(e)} >
                                      </textarea>
                                    </div>
                                    <div class='mt-4'>
                                      <span>Images</span>
                                      <div className="media_preview__okAyH">
                                        <label>Left Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {memecoinavisionleftimage.length > 0 ?
                                            <div>
                                              {memecoinavisionleftimage.map((image, index) => (
                                                <img
                                                  onClick={() => handleLeftImage(0)}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => handleLeftImage(0)} style={{ height: 30, width: 40 }} src={memecoinavisionleftimage.length > 0 ? memecoinavisionleftimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>
                                      <br />
                                      <div className="media_preview__okAyH">
                                        <label>Right Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {memecoinavisionrightimage.length > 0 ?
                                            <div>
                                              {memecoinavisionrightimage.map((image, index) => (
                                                <img
                                                  onClick={() => handleRightImage(0)}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => handleRightImage(0)} style={{ height: 30, width: 40 }} src={memecoinavisionrightimage.length > 0 ? memecoinavisionrightimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>

                                      
                                    </div>
                                    <br />
                                    <span>Background Image</span>
                                    <div className="media_preview__okAyH">
                                      <label>Background Image</label>
                                      <div className="media_value__\+4KKG ">
                                        {memeaboutbgimage.length > 0 ?
                                          <div>
                                            {memeaboutbgimage.map((image, index) => (
                                              <img
                                                onClick={() => { handleBackgrounddImage(0); setMemeaboutbackground(1); }}
                                                key={index}
                                                style={{ height: 30, width: 40 }}
                                                src={image}
                                                alt={`Image ${index}`}
                                                className="media_image__kN9DM"
                                              />
                                            ))}</div>
                                          :
                                          <img onClick={() => { handleBackgrounddImage(0); setMemeaboutbackground(1); }} style={{ height: 30, width: 40 }} src={memeaboutbgimage.length > 0 ? memeaboutbgimage : ''} alt="" className="media_image__kN9DM" />
                                        }
                                      </div>
                                    </div>

                                    <br />
                                      <span >Background Color</span>

                                      <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemeaboutbackground(0); }}>
                                        <label> Background Color</label>
                                        <div className="media_value__\+4KKG ">

                                          <div style={{ height: 30, width: 40, backgroundColor: memeaboutbgcolor.length == 0 ? '' : memeaboutbgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                        </div>


                                      </div>
                                      {isHeaderDesignText == false ? null :
                                        <div id='one' style={{ position: 'relative', }}>
                                          <SketchPicker color={memeaboutbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                      }

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>




                    </div> : null}

                    {selectMemeCoinTemplates == 2 ?
                      <div className="editing_content__RXzxk">
                        <div className="form_form__Sku4X" >
                          <div className="form_form__Sku4X">
                            <div className="form_group__kDXNb">
                              <div className="form_form__Sku4X">
                                <div class="form_row__2+BJ3">

                                  <div className="form_group__kDXNb">
                                    <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                      <div class="form_field__d31bq" >
                                        <span>Caption</span>
                                        <textarea placeholder="Caption" style={{ height: '220px', }} value={memecoinAbout} onChange={(e) => HandelMemecoinAbout(e)} >
                                        </textarea>
                                      </div>
                                      <div class='mt-4'>
                                        <span>Images</span>

                                        <br />
                                        <div className="media_preview__okAyH">
                                          <label>Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinavisionrightimage.length > 0 ?
                                              <div>
                                                {memecoinavisionrightimage.map((image, index) => (
                                                  <img
                                                    onClick={() => handleRightImage(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleRightImage(0)} style={{ height: 30, width: 40 }} src={memecoinavisionrightimage.length > 0 ? memecoinavisionrightimage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>
                                         <br/>
                                         <span>Background Image</span>
                                         <div className="media_preview__okAyH">
                                        <label>Background Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {memeaboutbgimage.length > 0 ?
                                            <div>
                                              {memeaboutbgimage.map((image, index) => (
                                                <img
                                                  onClick={() => { handleBackgrounddImage(0); setMemeaboutbackground(1); }}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => { handleBackgrounddImage(0); setMemeaboutbackground(1); }} style={{ height: 30, width: 40 }} src={memeaboutbgimage.length > 0 ? memeaboutbgimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>
                                        <br />
                                        <span >Background Color</span>

                                        <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemeaboutbackground(0); }}>
                                          <label> Background Color</label>
                                          <div className="media_value__\+4KKG ">

                                            <div style={{ height: 30, width: 40, backgroundColor: memeaboutbgcolor.length == 0 ? '' : memeaboutbgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                          </div>


                                        </div>
                                        {isHeaderDesignText == false ? null :
                                          <div id='one' style={{ position: 'relative', }}>
                                            <SketchPicker color={memeaboutbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                        }
                                      </div>
                                      <br />
                                     

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div> : null}

                    {selectMemeCoinTemplates == 3 ?
                      <div className="editing_content__RXzxk">
                        <div className="form_form__Sku4X" >
                          <div className="form_form__Sku4X">
                            <div className="form_group__kDXNb">
                              <div className="form_form__Sku4X">
                                <div class="form_row__2+BJ3">

                                  <div className="form_group__kDXNb">
                                    <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                    <div class="form_field__d31bq" >
                                        <span>Heading</span>
                                        <textarea placeholder="Text" style={{ height: '20px', }} value={memeabout} onChange={(e) => setMemeabout(e.target.value)} >
                                        </textarea>
                                      </div>
                                      <br/>
                                      <div class="form_field__d31bq" >
                                        <span>Caption</span>
                                        <textarea placeholder="Caption" style={{ height: 'fit-content', }} value={memecoinAbout} onChange={(e) => HandelMemecoinAbout(e)} >
                                        </textarea>
                                      </div>
                                      <div class='mt-4'>
                                        
                                      <br />
                                      <label>Background Image</label>
                                      <div className="media_preview__okAyH">
                                        <label>Background Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {memeaboutbgimage.length > 0 ?
                                            <div>
                                              {memeaboutbgimage.map((image, index) => (
                                                <img
                                                  onClick={() => { handleBackgrounddImage(0); setMemeaboutbackground(1); }}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => { handleBackgrounddImage(0); setMemeaboutbackground(1); }} style={{ height: 30, width: 40 }} src={memeaboutbgimage.length > 0 ? memeaboutbgimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>

                                        <br />
                                        <span >Background Color</span>

                                        <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemeaboutbackground(0); }}>
                                          <label>Background Color</label>
                                          <div className="media_value__\+4KKG ">

                                            <div style={{ height: 30, width: 40, backgroundColor: memeaboutbgcolor.length == 0 ? '' : memeaboutbgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                          </div>


                                        </div>
                                        {isHeaderDesignText == false ? null :
                                          <div id='one' style={{ position: 'relative', }}>
                                            <SketchPicker color={memeaboutbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                        }
                                      </div>
                                     

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div> : null}

                    {selectMemeCoinTemplates == 4 ? <div className="editing_content__RXzxk">
                      <div className="form_form__Sku4X" >
                        <div className="form_form__Sku4X">
                          <div className="form_group__kDXNb">
                            <div className="form_form__Sku4X">
                              <div class="form_row__2+BJ3">

                                <div className="form_group__kDXNb">
                                  <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                    <div class="form_field__d31bq" >
                                      <span>Heading</span>
                                      <textarea placeholder="Heading" style={{ height: '60px', }} value={template3heading} onChange={(e) => setTemplate3Heading(e.target.value)} >
                                      </textarea>
                                    </div>

                                    <div class="form_field__d31bq" >
                                      <span>Caption</span>
                                      <textarea placeholder="Caption" style={{ height: '220px', }} value={memecoinAbout} onChange={(e) => HandelMemecoinAbout(e)} >
                                      </textarea>
                                    </div>
                                    <div class='mt-4'>
                                      <span>Meme Image</span>
                                      <div className="media_preview__okAyH">
                                        <label>Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {memecoinavisionleftimage.length > 0 ?
                                            <div>
                                              {memecoinavisionleftimage.map((image, index) => (
                                                <img
                                                  onClick={() => handleLeftImage(0)}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => handleLeftImage(0)} style={{ height: 30, width: 40 }} src={memecoinavisionleftimage.length > 0 ? memecoinavisionleftimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>
                                      <br />
                                    <span>Background Image</span>
                                    <div className="media_preview__okAyH">
                                      <label>Background Image</label>
                                      <div className="media_value__\+4KKG ">
                                        {memeaboutbgimage.length > 0 ?
                                          <div>
                                            {memeaboutbgimage.map((image, index) => (
                                              <img
                                                onClick={() => { handleBackgrounddImage(0); setMemeaboutbackground(1); }}
                                                key={index}
                                                style={{ height: 30, width: 40 }}
                                                src={image}
                                                alt={`Image ${index}`}
                                                className="media_image__kN9DM"
                                              />
                                            ))}</div>
                                          :
                                          <img onClick={() => { handleBackgrounddImage(0); setMemeaboutbackground(1); }} style={{ height: 30, width: 40 }} src={memeaboutbgimage.length > 0 ? memeaboutbgimage : ''} alt="" className="media_image__kN9DM" />
                                        }
                                      </div>
                                    </div>
                                      <br />
                                     
                                      <span >Background Color</span>

                                      <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemeaboutbackground(0); }}>
                                        <label> Background Color</label>
                                        <div className="media_value__\+4KKG ">

                                          <div style={{ height: 30, width: 40, backgroundColor: memeaboutbgcolor.length == 0 ? '' : memeaboutbgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                        </div>


                                      </div>
                                      {isHeaderDesignText == false ? null :
                                        <div id='one' style={{ position: 'relative', }}>
                                          <SketchPicker color={memeaboutbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                      }
                                    </div>
                                    

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>




                    </div> : null}
                    {selectMemeCoinTemplates == 6 ? <div className="editing_content__RXzxk">
                      <div className="form_form__Sku4X" >
                        <div className="form_form__Sku4X">
                          <div className="form_group__kDXNb">
                            <div className="form_form__Sku4X">
                              <div class="form_row__2+BJ3">

                                <div className="form_group__kDXNb">
                                  <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                    <div class="form_field__d31bq" >
                                      <span>Caption</span>
                                      <textarea placeholder="Caption" style={{ height: '220px', }} value={memecoinAbout} onChange={(e) => HandelMemecoinAbout(e)} >
                                      </textarea>
                                    </div>
                                    <div class="form_field__d31bq" >
                                      <span>Meme Name</span>
                                      <textarea placeholder="Name" style={{ height: 'fit-content', }} value={memecoinname} onChange={(e) => SetMemecoinName(e.target.value)} >
                                      </textarea>
                                    </div>
                                    <div class="form_field__d31bq" >
                                      <span>Meme Symbol</span>
                                      <textarea placeholder="Symbol" style={{ height: 'fit-content', }} value={memecoinsymbol} onChange={(e) => setMemecoinSymbol(e.target.value)} >
                                      </textarea>
                                    </div>
                                    <br/>
                                    <div class="form_field__d31bq" >
                                        <span>Twitter</span>
                                        <textarea placeholder="Link" style={{ height: "fit-content" }} value={memetwitterlink} onChange={(e) => setMemetwitterlink(e.target.value)} >
                                        </textarea>
                                        <br/>
                                        <div className="media_preview__okAyH">
                                        <label>Twitter Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {twitterimage.length > 0 ?
                                            <div>
                                              {twitterimage.map((image, index) => (
                                                <img
                                                  onClick={() => { handleTwitterImage(0) }}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => { handleTwitterImage(0) }} style={{ height: 30, width: 40 }} src={twitterimage.length > 0 ? twitterimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div> 
                                      </div>
                                      <br/>
                                      <div class="form_field__d31bq" >
                                        <span>Telegram</span>
                                        <textarea placeholder="Link" style={{ height: "fit-content" }} value={memetelegramlink} onChange={(e) => setMemetelegramlink(e.target.value)} >
                                        </textarea>
                                        <br/>
                                        <div className="media_preview__okAyH">
                                        <label>Telegram Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {telegraminage.length > 0 ?
                                            <div>
                                              {telegraminage.map((image, index) => (
                                                <img
                                                  onClick={() => { handleTelegramImage(0) }}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => { handleTelegramImage(0) }} style={{ height: 30, width: 40 }} src={telegraminage.length > 0 ? telegraminage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>
                                      </div>
                                      <br/>
                                      <div class="form_field__d31bq" >
                                        <span>Instagram</span>
                                        <textarea placeholder="Link" style={{ height: "fit-content" }} value={memeinstagramlink} onChange={(e) => setMemeinstagramlink(e.target.value)} >
                                        </textarea>
                                        <br/>
                                        <div className="media_preview__okAyH">
                                        <label>Instagram Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {instagramimage.length > 0 ?
                                            <div>
                                              {instagramimage.map((image, index) => (
                                                <img
                                                  onClick={() => { handleInastagramImage(0) }}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => { handleInastagramImage(0) }} style={{ height: 30, width: 40 }} src={instagramimage.length > 0 ? instagramimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>
                                      </div>
                                      <br/>
                                      <div class="form_field__d31bq" >
                                        <span>Warpcast</span>
                                        <textarea placeholder="Link" style={{ height: "fit-content" }} value={memewarpcastlink} onChange={(e) => setMemewarpcastlink(e.target.value)} >
                                        </textarea>
                                        <br/>
                                        <div className="media_preview__okAyH">
                                        <label>Warpcast Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {warpcastimage.length > 0 ?
                                            <div>
                                              {warpcastimage.map((image, index) => (
                                                <img
                                                  onClick={() => { handlewarpcastimage(0) }}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => { handlewarpcastimage(0) }} style={{ height: 30, width: 40 }} src={warpcastimage.length > 0 ? warpcastimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div> 
                                      </div>
                                    <div class='mt-4'>
                                      <span>Images</span>
                                      <div className="media_preview__okAyH">
                                        <label>Meme Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {memecoinavisionleftimage.length > 0 ?
                                            <div>
                                              {memecoinavisionleftimage.map((image, index) => (
                                                <img
                                                  onClick={() => handleLeftImage(0)}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => handleLeftImage(0)} style={{ height: 30, width: 40 }} src={memecoinavisionleftimage.length > 0 ? memecoinavisionleftimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>


                                      <br />
                                      <span>Background Image</span>
                                    <div className="media_preview__okAyH">
                                      <label>Background Image</label>
                                      <div className="media_value__\+4KKG ">
                                        {memeaboutbgimage.length > 0 ?
                                          <div>
                                            {memeaboutbgimage.map((image, index) => (
                                              <img
                                                onClick={() => { handleBackgrounddImage(0); setMemeaboutbackground(1); }}
                                                key={index}
                                                style={{ height: 30, width: 40 }}
                                                src={image}
                                                alt={`Image ${index}`}
                                                className="media_image__kN9DM"
                                              />
                                            ))}</div>
                                          :
                                          <img onClick={() => { handleBackgrounddImage(0); setMemeaboutbackground(1); }} style={{ height: 30, width: 40 }} src={memeaboutbgimage.length > 0 ? memeaboutbgimage : ''} alt="" className="media_image__kN9DM" />
                                        }
                                      </div>
                                    </div>
                                      <br/>
                                      <span >Background Color</span>

                                      <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemeaboutbackground(0); }}>
                                        <label>Background Color</label>
                                        <div className="media_value__\+4KKG ">

                                          <div style={{ height: 30, width: 40, backgroundColor: memeaboutbgcolor.length == 0 ? '' : memeaboutbgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                        </div>


                                      </div>
                                      {isHeaderDesignText == false ? null :
                                        <div id='one' style={{ position: 'relative', }}>
                                          <SketchPicker color={memeaboutbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                      }
                                    </div>
                                    <br />
                                    

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>




                    </div> : null}

                    {selectMemeCoinTemplates == 5 ? <div className="editing_content__RXzxk">
                      <div className="form_form__Sku4X" >
                        <div className="form_form__Sku4X">
                          <div className="form_group__kDXNb">
                            <div className="form_form__Sku4X">
                              <div class="form_row__2+BJ3">

                                <div className="form_group__kDXNb">
                                  <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                    <div class="form_field__d31bq" >
                                      <span>Helmet</span>
                                      <textarea placeholder="Title" style={{ height: 'fit-content', }} value={t5about1} onChange={(e) => setT5Aboutt1(e.target.value)} >
                                      </textarea>
                                    </div>
                                    <div class="form_field__d31bq" >
                                      <span>Caption</span>
                                      <textarea placeholder="Caption" style={{ height: '100px', }} value={t5about2} onChange={(e) => setT5About2(e.target.value)} >
                                      </textarea>
                                    </div>
                                    <div class='mt-4'>
                                      <span>Images</span>

                                      <br />
                                      <div className="media_preview__okAyH">
                                        <label> Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {memecoinavisionrightimage.length > 0 ?
                                            <div>
                                              {memecoinavisionrightimage.map((image, index) => (
                                                <img
                                                  onClick={() => handleRightImage(0)}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => handleRightImage(0)} style={{ height: 30, width: 40 }} src={memecoinavisionrightimage.length > 0 ? memecoinavisionrightimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>

                                      <br/>
                                      <div className="media_preview__okAyH">
                                        <label> Buy Tool</label>
                                        <div className="media_value__\+4KKG ">
                                          {buy1.length > 0 ?
                                            <div>
                                              {buy1.map((image, index) => (
                                                <img
                                                  onClick={() => handleBuyImage(0)}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => handleBuyImage(0)} style={{ height: 30, width: 40 }} src={buy1.length > 0 ? buy1 : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>
                                      <br/>
                                      <span>Background Image</span>
                                      <div className="media_preview__okAyH">
                                      <label>Background Image</label>
                                      <div className="media_value__\+4KKG ">
                                        {memeaboutbgimage.length > 0 ?
                                          <div>
                                            {memeaboutbgimage.map((image, index) => (
                                              <img
                                                onClick={() => { handleBackgrounddImage(0); setMemeaboutbackground(1); }}
                                                key={index}
                                                style={{ height: 30, width: 40 }}
                                                src={image}
                                                alt={`Image ${index}`}
                                                className="media_image__kN9DM"
                                              />
                                            ))}</div>
                                          :
                                          <img onClick={() => { handleBackgrounddImage(0); setMemeaboutbackground(1); }} style={{ height: 30, width: 40 }} src={memeaboutbgimage.length > 0 ? memeaboutbgimage : ''} alt="" className="media_image__kN9DM" />
                                        }
                                      </div>
                                    </div>
                                      <br />
                                      <span >Background Color</span>

                                      <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemeaboutbackground(0); }}>
                                        <label>Background Color</label>
                                        <div className="media_value__\+4KKG ">

                                          <div style={{ height: 30, width: 40, backgroundColor: memeaboutbgcolor.length == 0 ? '' : memeaboutbgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                        </div>


                                      </div>
                                      {isHeaderDesignText == false ? null :
                                        <div id='one' style={{ position: 'relative', }}>
                                          <SketchPicker color={memeaboutbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                      }
                                    </div>
                                    <br />
                                    

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>




                    </div> : null}


                  </div>
                  : <MemeVisionDesing />}

              </div> : <div className='editing_wrapper__Wijfx h-100  overflow-auto'><Theme /></div>}
          </div>
          <div className=' h-100 overflow-auto editing_wrapper__Wijfxnew' style={{ width: 'calc(100% - 400px)' }}>
            <Memecoin />
          </div>
        </div>
      </div>
      <div>
        
      </div>
    </>
  )
}

export default MemecoinAboutEdit