// Create a new file, e.g., context.js
import React, { createContext, useContext, useState } from 'react';
import arrow from '../assets/images/arrowMeme.png'
import twitter from '../assets/images/twittermeme.png'
import telegram from '../assets/images/Telegrammeme.png'
import tiktok from '../assets/images/Tiktokmeme.png'
import youtube from '../assets/images/Youtubememe.png'
import instagram from '../assets/images/Instagram.png'
import warpcast from '../assets/images/Wmeme.png'
import Contract from '../assets/images/Contract.png'
import Database from '../assets/images/Database.png'
import Circulating from '../assets/images/Circulating.png'
import Burned from '../assets/images/Burned.png'
import Liquidity from '../assets/images/Liquidity.png'
import Renounced from '../assets/images/Renounced.png'
import { Images1 } from '../components/MemeCoin/Images';
import { Images2 } from "../components/MemeCoin/Images";
import { Images3 } from '../components/MemeCoin/Images'
import { Images4 } from "../components/MemeCoin/Images";
import { Images5 } from "../components/MemeCoin/Images";
import { Images6 } from "../components/MemeCoin/Images";
import { Images7 } from "../components/MemeCoin/Images";
import { Images8 } from "../components/MemeCoin/Images";
import { Images9 } from "../components/MemeCoin/Images";
import { Images10 } from '../components/MemeCoin/Images';
import logo from "../assets/images/FaviconNew.png"
import MyContext from '../context/MyContext';
import '../assets/CSS/Donwloadtemple.css'
import Logo from '../assets/images/FaviconNew.png'
import { NewmaskImage } from '../components/MemeCoin/Images';
import Newtelegram from '../assets/images/Newtelegram.png'

import { Newlogo } from '../components/MemeCoin/Images'
import ServiceIcon from '../assets/images/servicesicon.svg';



import Twitter from '../assets/images/TwitterT1.png'
import { Template1mainimage } from '../components/Base64/Images'
import { Tokenomic1 } from '../components/Base64/Images'
import { Tokenomics2 } from '../components/Base64/Images'
import { Tokenomics3 } from '../components/Base64/Images'
import ContractImage1 from '../assets/images/ContractIamge1T1.png'
import ContractImage2 from '../assets/images/ContractImage1T1.png'
import ContractImage3 from '../assets/images/ContractImageIamge1T1.png'
import { Template1Footer } from '../components/Base64/Images'
import { Template1Solly } from '../components/Base64/Images'
import styles from '../assets/CSS/Template1.module.css';

import { Template2Logo } from '../components/Base64/Images'
import { Template2telegram } from '../components/Base64/Images'
import { Template2twitter } from '../components/Base64/Images'
import Template2unicorn from '../assets/images/Template2unicorn.png'
import Template2M from '../assets/images/Template2M.png'
import Template2Forg from '../assets/images/Template2forg.png'
import { Template2sectionbg } from '../components/Base64/Images'
import Template2Section3bg from '../assets/images/Template2Section3bg.png'
import Template2SectionMain from '../assets/images/Template2SectionMainbg.png'



import { Template3BG } from '../components/Base64/Images'
import { Template3Container } from '../components/Base64/Images'
import { Template3Sunppd } from '../components/Base64/Images'
import Template3Telegram from '../assets/images/TelegramT3.png'
import Template3Twitter from '../assets/images/Template3x.png'
import Template3Chart from '../assets/images/Template3Chart.png'
import Template3Svg from '../assets/images/Template3Svg.png'
import Template3BrownContainer from '../assets/images/Template3BrownContainer.png'
import Template3Vector from '../assets/images/Template3Vector.png'
import { NewTemplate3Maks } from '../components/Base64/Images'
import Template3MaskSvg from '../assets/images/Template3Makssvg.png'
import Template3footersvg from '../assets/images/Template3Footersvg.png'
import Template3footerbg from '../assets/images/Template3Footerbg.jpg'
import Template3Footercontainer1 from '../assets/images/Template3Footercontainer1.png'
import Template3FooterContainer2 from '../assets/images/Template3FooterContainer2.png'
import Template3Footertwitter from '../assets/images/Template3Footertwitter.png'
import { Template3FooterSunppd } from '../components/Base64/Images'
import { Template3FooterEmail } from '../components/Base64/Images'
import { Template3SectionSnuppd } from '../components/Base64/Images'
import Template3Section3svg from '../assets/images/Template3Section3svg.png'
import { Template3DogImage } from '../components/Base64/Images'


import { T4Navlogo } from '../components/MemeCoin/Images.js'
import { T4MainSectionImage } from '../components/MemeCoin/Images.js'
import { T4Memelogo } from '../components/MemeCoin/Images.js'
import { T4MemeImage1 } from '../components/MemeCoin/Images.js'
import { T4twitter } from '../components/MemeCoin/Images.js'
import { T4telegram } from '../components/MemeCoin/Images.js'
import { T4Instagram } from '../components/MemeCoin/Images.js'
import { T4trend } from '../components/MemeCoin/Images.js'
import { T4Section3bgImage } from '../components/MemeCoin/Images.js'
import { T4MemeImage2 } from '../components/MemeCoin/Images.js'
import { T4MemeImage3 } from '../components/MemeCoin/Images.js'
import { T4footertelegram } from '../components/MemeCoin/Images.js'
import { T4footerTwitter } from '../components/MemeCoin/Images.js'
import { T4footerInstagram } from '../components/MemeCoin/Images.js'
import { T4footertrend } from '../components/MemeCoin/Images.js'



import { T5image1 } from '../components/MemeCoin/Images.js'
import { T5image2 } from '../components/MemeCoin/Images.js'
import T5image3 from '../assets/images/T5image3.png'
import T5image4 from '../assets/images/T5image4.png'
import T5image5 from '../assets/images/T5image5.png'
import T5image6 from '../assets/images/T5image6.png'
import T5image7 from '../assets/images/T5image7.png'
import T5image8 from '../assets/images/T5image8.png'
import T5image9 from '../assets/images/T5image9.png'
import T5image10 from '../assets/images/T5image10.png'
import T5image11 from '../assets/images/T5image11.png'
import T5image12 from '../assets/images/T5image12.png'
import T5image13 from '../assets/images/T5image13.png'
import T5image14 from '../assets/images/T5image14.png'
import T5image15 from '../assets/images/T5image15.png'
import T5image16 from '../assets/images/T5image16.png'
import T5image17 from '../assets/images/T5image17png.png'
import { T5image18 } from '../components/MemeCoin/Images.js'
import T5image19 from '../assets/images/T5image19.png'
import { T5image20 } from '../components/MemeCoin/Images.js'
import T5image21 from '../assets/images/T5image21.png'
import { T5image22 } from '../components/MemeCoin/Images.js'
import T5image23 from '../assets/images/T5image23.png'




const PublishContext = createContext();


export const PublishProvider = ({ children }) => {



    const { googletransalte, featureimages, setFeatureImages,
        introImages, setIntroIamge,
        buttonshowdoblur, setButtonshowdoblur,
        buttonshowdowcolor, setButtonshowdocolor,
        buttonshowdospread, setButtonshowdospread,
        buttonshowdoleft, setButtonshowdoleft,
        buttonshowdoTop, setButtonshowdoTop,
        featureIcon1, setfeatureIcon1,
        featureIcon2, setfeatureIcon2,
        featureIcon3, setfeatureIcon3,
        featureIcon4, setfeatureIcon4,
        memecoin, setMemecoin, t4stepimage,
        newnavbarmenu, setNewnavbarmenu,
        alignMedia, setAlignMedia,
        imagePosition, setImagePosition,
        selectedFeature, setSelectedFeature,
        buttonvarticalpadding, setButtonvarticalpadding,
        buttonhorizantlepadding, setButtonhorizantlepadding,
        Animated, setAnimated,
        selectedStats, setSelectedStats,
        selectedLogo, setSelectedLogo,
        selectedFaq, setSelectedFaq,
        selectedFooter, setSelectedFooter,
        showdovalue, setshowdovalue,
        butttonshowdo, setButtonshowdo,
        buttonborder, setbuttonborder,
        buttonborderradius, setButtonborderradios,
        buttonbordercolor, setbuttonbordercolor,
        buttoncolor, setButtoncolor,
        button1font, setButton1font,
        buttontext, setbuttontext, Section6,
        selectedHero, setSelectedHero,
        statsbordercolor, setStatsbordercolor,
        statsshowdowrange, setStatsshowdowrange,
        statsborder, setStatsborder,
        statsborderradius, setStatsborderradius,
        statsbackgroundcolor, setStatsbackgroundcolor,
        statevaluecolor, setStatsvaluecolor,
        statslabelcolor, setStatslabelcolor,
        captioncolor, setCaptioncolor,
        selectedLi, setSelectedLi,
        checked, setChecked,
        conatcmenuborder, setcontactmenuborder,
        contactheadingcolor, setContactheadingcolor,
        contenttextheadingcolor, setContenttextheadingcolor,
        contenttextinfocolor, setContenttextinfocolor,
        statethree, setStatsthree,
        statetwo, setStatstwo,
        stateOne, setStatsOne,
        footerMargintop, setFooterMarginTop,
        footerMarginBottom, setFooterMarginBottom,
        faqMargintop, setFaqMarginTop,
        faqMarginBottom, setFaqMarginBottom,
        statsMarginBottom, setStatsMarginBottom,
        statsMarginTop, setStatsMarginTop,
        serviceMarginBottom, setServiceMarginBottom,
        serviceMarginTop, setServiceMarginTop,
        trustmargintop, setTrustmargintop,
        trustmarginbottom, setTrustmarginBottom,
        marginTop, setMarginTop,
        marginBottom, setmarginBottom,
        intromargintop, setIntromargintop,
        intromarginbottom, setIntromarginbottom,
        showdowrange, setShowdorange,
        footerbodytextfont, setFooterbodytextfont,
        faqtitletextfont, setFaqtitletextfont,
        faqbodytextfont, setFaqbodytextfont,
        statsbodytextfont, setStatsbodytextfont,
        statstitletextfont, setStatstitletextfont,
        serviceBodytextfont, setserviceBodytextfont,
        servicetitletextfont, setServicestitletextfont,
        trusttitletextfont, setTrusttitletextfont,
        trusttextfont, setTrusttextfont,
        introinfotextfont, setintroinfotextfont,
        introtextfont, setIntrotextfont,
        newfooterhead, setNewfooterhead,
        newfooteritem, setNewfooteritem,
        faqCaption, setFaqCaption,
        footerLinkgroups, setfooterLinkgroups,
        statscaption, setStatsCaption,
        servicecaption, setServicesCaption,
        trustCaption, setTrustCaption,
        introcaption, setIntrocaption,
        footerlegalhead, setFooterleagalhead,
        footerCompanyhead, setFooterCompanyhead,
        footerfeaturehead, setFooterfeaturehead,
        headertextfont, setHeadertextfont,
        selectedOption1, setSelectedOption1,
        intromenutextcolor, setIntromenutextcolor,
        intromenubgcolor, setIntromenubgcolor,
        newBackgroundcolor, setNewbackgroundcolor,
        colorheaderDiv, setcolorheaderdiv,
        newservicesImage, setNewserviceImage,
        headernotice, setheadernotice,
        newlogo, setNewlogo,
        newtrustlogo, setNewtrustlogo,
        newfootersocialIcon, setNewfootersocialIcon,
        newFaq, setNewFaq,
        newlegacy1, setNewlgacy1,
        newlegacy, setNewlgacy,
        removelegacy, setRemovelegacy,
        removelegacynum, setremoveleagacynum,
        trustLogo, settTrustlogos,
        newservicesinfo, setNewservicesinfo,
        newservicesheading, setNewservicesheading,
        introbuttonapprovel, setIntrobuttonapprovel,
        addintrobutton, setAddintrobutton,
        addbuttonapprovel, setAddbuttonapprovel,
        removeSocialIcon1, setRemoveSocialIcon1,
        removeSocialIcon2, setRemoveSocialIcon2,
        removeSocialIcon3, setRemoveSocialIcon3,
        removeSocialIcon4, setRemoveSocialIcon4,
        navAddbutton, setNavaddbutton,
        footeryear, setFooteryear,
        faqtitle, setFaqtitle,
        faqIntro, setFaqIntro,
        newIntroImage, setNewIntroImage,
        leagacydiv, setLeagacydiv,
        addnavmenu, setAddnavmenu,
        navadddiv, setNavnavadddiv,
        removelogos, setRemovelogos,
        removeFaq1, setRemovefaq1,
        removeFaq2, setRemovefaq2,
        removeFaq3, setRemovefaq3,
        removeFaq4, setRemovefaq4,
        statsnumremove1, setStatsnumremove1,
        statsnumremove2, setStatsnumremove2,
        statsnumremove3, setStatsnumremove3,
        serviceremove1, settServiceremove1,
        serviceremove2, settServiceremove2,
        serviceremove3, settServiceremove3,
        serviceremove4, settServiceremove4,
        introbtn1remove, setIntrobtnremove1,
        introbtn2remove, setIntrobtnremove2,
        navbarremove, setNavbarremove,
        navremovebtn1, setNavremovebtn1,
        navremovebtn2, setNavremovebtn2,
        navbuttonincraese, setNavbuttonincrease,
        inttrobtn1, setIntrobtn1,
        introbtn2, setIntrobtn2,
        statsintro, setStatsinto,
        statsnumber1, setStatsnumber1,
        statsnumber2, setStatsnumber2,
        statsnumber3, setStatsnumber3,
        statsnumber4, setStatsnumber4,
        statsnumber5, setStatsnumber5,
        statsnumber6, setStatsnumber6,
        navbtn1, setNavbtn1,
        navbtn2, setNavbtn2,
        faqA1, setfaqA1,
        faqA2, setfaqA2,
        faqA3, setfaqA3,
        faqA4, setfaqA4,
        faqQ1, setFaqQ1,
        faqQ2, setFaqQ2,
        faqQ3, setFaqQ3,
        faqQ4, setFaqQ4,
        serviceHeading1, setServiceHeading1,
        serviceinfo1, setServiceinfo1,
        serviceHeading2, setServiceHeading2,
        serviceinfo2, setServiceinfo2,
        serviceHeading3, setServiceHeading3,
        serviceinfo3, setServiceinfo3,
        serviceHeading4, setServiceHeading4,
        serviceinfo4, setServiceinfo4,
        servicesIntro1, setServicesIntro1,
        servicesIntro, setservicesIntro,
        menu1, setmenu1,
        headerdivtextcolor, setHeaderDivTextColor,
        headerdivtext, setHeaderDivText,
        headerdivbgcolor, setHeaderdivbgcolor,
        navbartitle, setNavbartitle,
        headerdiv, setHeaderdiv,
        section1, setsection1,
        section2, setsection2,
        section3, setsection3,
        section4, setsection4,
        section5, setsection5,
        section6, setsection6,
        section7, setsection7,
        contentdesing, setContentdesing,
        mainnavbarbutton, setMainnavbarbutton,
        images, setImages,
        serachtearm1, setSearchteam,
        searchTerm, setSearchTerm,
        visibleComponents, setVisibleComponents,
        footercompany, setfootercompany,
        footerlegal, setfooterlegal,
        footerfeature, setfooterfeature,
        serv_list_obj, Setserv_list_obj,
        introo, setIntroo,
        footerfeatures, setfooterfeatures,
        navbarmenu, setnavbarmenu,
        footertext, setfootertext,
        footercolor, setfootercolor,
        showWebsite, setShowWebsite,
        loading, setLoading,
        location, setLocation,
        title, setTitle,
        intro, setIntro,
        about, setAbout,
        services, setServices,
        portfolio, setPortfolio,
        reviews, setReviews,
        contact, setContact,
        faq, setFaq,
        menu, setmenu,
        byTheNumber, setByTheNumber,
        navbartextcolor, setnavbartextcolor,
        welcometilte, setwelcometitle,
        titlecolor, settitlecolor,
        introcolor, setintrocolor,
        introtext, setintrotext,
        legacycolor, setlegacycolor,
        featurecolor, setfeaturecolor,
        featuretext, setfeaturetext,
        faqcolor, setfaqcolor,
        faqtext, setfaqtext,
        legacytext, setlegacytext,
        serviceheader, setserviceheader,
        legacy, setleagacy,
        trustcolor, settrustcolor,
        trusttext, settrusttextcolor,
        trust, settrust,
        trsuttitle, settrusttile,

        memecoinname, SetMemecoinName,
        memecoinsymbol, setMemecoinSymbol,
        memecoinNavbar, setMemecoinNavbar,
        memecoinAbout, setMemecoinAbout,
        memeCaption, memecoinValue,
        memecoincontractaddress, setMemecoincontractAddress,
        memecoinInitialsupply, setMemecoinInitialsupply,
        memecoinCirculatingSupply, setMemecoinCirculatingSupply,
        memecoinSupplyBurned, setMemecoinSupplyBurned,
        memecoinLiquidity, setMemecoinLiquidity,
        memecoinlogoImage, setMemecoinlogoImage,
        memecoinaboutbgImage, memecoinAminitionImage,
        memecoinaboutleftimage, memecoinaboutrightimage,
        memecoinavisionleftimage, memecoinavisionrightimage,
        memecointokenomicsimage, memecoinContractImage,
        memecoinDatabaseImage, memecoinCirculatingImage,
        memecoinBurnedImage, memecoinLiquidityImage,
        memecoinRenouncedImage, memenavbgcolor,
        memeaboutbgcolor, memecointokonomicsbgimage,
        memecoinanvfont, memenavtextcolor,
        memecoinabouttextcolor, memecoinaboutfont,
        memecoinsection, memecoinvisionfont,
        memecoinvisiontextcolor, memecointokenfont,
        memecointoketextcolor, memecoinstepfont,
        memecoinsteptextcolor, randomId, setRandomId,
        iSremove1, setIsremove1,
        iSremove2, setIsremove2,
        iSremove3, setIsremove3,
        iSremove4, setIsremove4,
        iSremove5, setIsremove5,
        iSremove6, setIsremove6,
        newaddtokest, newiconsImage,
        step1, setStep1,
        step2, setStep2,
        step3, setStep3,
        step4, setStep4,
        stepheading, setStepheading,
        memeherobutton,
        memefooterabout, setMemefooterabout,
        memetwitterlink, setMemetwitterlink,
        memetelegramlink, setMemetelegramlink,
        memeYoutubelink, setMemeYoutubelink,
        memetiktoklink, setMemetiktoklink,
        memeinstagramlink, setMemeinstagramlink,
        memewarpcastlink, setMemewarpcastlink,
        setMemecoinsection, memeaboutbgimage,
        memestepbgimage, memefooterbgimage,
        memenavbarbgcolor, memebackground,
        memeherobgcolor, memeherobackground,
        memeaboutbackground, memetokenomicsbgcolor,
        memetokenomicsbackground, memestepbgcolor,
        memestepbackground, Backgroundimage, memefooterbgcolor,
        tokenvalubgcolor,
        memefootrbackground, memenavfontsize,
        memeMainfontsize, memeaboutfontSize,
        memetokenfontSize, memestepfontSize,
        memefooterfontSize, memefootertextcolor,
        memefootertextfont,


        template1footerabout, setTemplate1footerAbout,
        template1tokenomisupply, setTemplate1tokenomisupply,
        template1tokenomicontract, setTemplate1tokenomicontract,
        template1tokenomiTax, setTemplate1tokenomiTax,
        template1footerImage, setTemplate1FooterImage,
        template1stepcontract, setTemplatestepcontract,
        template1stepAdd, setTemplate1Add,

        template1tokenomics, setTemplate1Tokenomics,
        template1tokenomicssub1, setTemplate1tokenomicssub1,
        template1tokenomicssub2, setTemplate1tokenomicssub2,
        template1tokenomicssub3, setTemplate1tokenomicssub3,
        template1tokenimage2, setTemplate1tokenimage2,
        template1tokenimage3, setTemplate1tokenimage3,
        template1Info, setTemplate1Info,
        template1caption, setTemplate1caption,
        selectMemeCoinTemplates,

        template2tokenAddress, setTemplate2tokenAddress,
        template2info, setTemplate2info,
        template2memecaption, setTemplate2memecaption,
        template2caption, setTemplate2Caption,
        template2twitterlink, setTemplate2twitterlink,
        template2telegramlink, setTemplate2telegramlink,
        template2tokentax, setTemplate2tokentax,
        template2tokenlp, setTemplate2tokenlp,
        template2tokenMeme, setTemplate2tokenMeme,
        template2tokentatesupply, setTemplate2tokentatesupply,
        template2tokensupply, setTemplate2tokensupply,
        template2tokenheading, setTemplate2tokenheading,
        template2step1, setTemplate2step1,
        template2step3, setTemplate2step3,
        template2step2, setTemplate2step2,
        template2step4, setTemplate2step4,
        template2stepheading, setTemplate2stepheading,
        template2footerlogo, setTemplate2footerlogo,
        template2footeringo, setTemplate2footerinfo,
        template2coingecko, setTemplate2coingecko,
        template2cmc, setTemplate2cmc,
        template2uniswap, setTemplateuniswap,
        template3memeaddress, setTemplate3memeadress,
        template3email, setTemplate3Email,
        memestepbackgroundt3, setMemestepbackgroundt3,
        template3footerinfo, setTemplate3Footerinfo,
        template3footerheading, setTemplate3footerheading,
        template3stepsection2bg, setTemplate3stepsection2bg,
        template3stepsection2color, setTemplate3section2color,
        template3stepsection2, setTemplate3stepsection2,
        template3stepquestion, setTemplate3question,
        template3step3section2heading, setTemplate3stepsection2heading,
        template3stepsection1, setTemplate3stepsection1,
        template3stepimage, setTemplate3stepimage,
        template3section1, setTemplate3section1,
        template3Section2, setTemplate3section2,
        template3Section3, setTemplate3Section3,
        template3Section4, setTemplate3Section4,
        template3telegramlink, setTemplate3telegramlink,
        template3twitterlink, setTemplate3twitterlink,
        template3uniswaplink, setTemplate3uniswaplink,
        template3heading, setTemplate3Heading,
        statsbackgroundcolorT3,
        memecloudimage, memetokencaption, memetokenbuy,
        t4navbutton,
        t4memeaddress, t4caption,
        t4mainbutton1, t4mainbutton2,
        t4token, t4step1,
        t4step2, t4step3,
        t4step4, t4step5,
        t4footer1, t4footer2,
        t4footer3, t4footer4,

        t5Nav1, t5nav2,
        t5about1, t5about2,

        t5Token1,
        t5Token3, t5Token4,
        t5Token5, t5Token6,

        t5step1, t5step2,
        t5step3, t5step4,
        step1heading, step2heading,
        step3heading, step4heading,
        buy, buy1, t1social1, t1social,
        t1token, t1token1,
        t4statsbackgrounsd, t1footer,
        stepimage1, stepimage2, stepimage3,
        memebuybuttonbackground, buybuttonbgcolor,
        buybuttnbgImage, t1stepheading,
        telegramtext, twittertext,
        telegraminage, twitterimage,
        youtubeimage, instagramimage,
        tiktokimage, warpcastimage,

        stepnumber1, setStepnumber1,
        stepnumber2, setStepnumber2,
        stepnumber3, setStepnumber3,
        stepnumber4, setStepnumber4,
        stepheading1, setStepheading1,
        stepheading2, setStepheading2,
        stepheading3, setStepheading3,
        stepheading4, setStepheading4,
        contractRenounced, memeabout,
        t4stepheading1, t4stepheading2,
        t4stepheading3, t4stepheading4,
        uniswapimage,coingeckoimage,
        cmcimage,
        template2stepheading1,template2stepheading2,
        template2stepheading3,template2stepheading4,
        template2tokenbutton,template2mainbutton1,
        template2mainbutton2,twittertextt4,Dextoolstext,
        tokenheading3T3,tokenheading1T3,
        tokenheading2T3,tokenheading4T3,
        tokenheadingt3,
    } = useContext(MyContext);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsopen4] = useState(false)
    const [isOpen, setIsopen] = useState(false);

    const randomIndex = Math.floor(Math.random() * 5);
    const randomIndex1 = Math.floor((Math.random() * 5) + 5);


    const hexToRgb = (hex) => {
        const bigint = parseInt(hex.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `${r}, ${g}, ${b}`;
    };


    const test = template2footerlogo.split('');
    const test1 = test.splice(3, 0, `${memecoinsymbol}`);
    const test2 = test.join('');
    const Step3test1 = step3.slice(0, 11);
    const Step3test2 = step3.slice(12,)
    console.log('template2footerlogo', test2);

    //    console.log('template2step1',template2step1.split(''));
    for (var i = 0; i < template2step4.length; i++) {
        console.log('template2step1', template2step4[i], i)
    };
    const step1Test1 = template2step1.slice(0, 21);
    const step1Test2 = template2step1.slice(22, 41);
    const step1Test3 = template2step1.slice(42, 84);
    const step1Test4 = template2step1.slice(85, 127);
    const step1Test5 = template2step1.slice(128, 165);
    const Step1Test6 = template2step1.slice(166, 203);
    const Step1Test7 = template2step1.slice(204,);




    console.log('Download', selectedLi, selectedHero, selectedLogo, selectedFeature, selectedStats, navbarmenu,);

    const publishWebsite = async () => {



        // ---------------------------------------Navbar-------------------------/
        const Navbarmenu = navbarmenu.map((menu) => {
            return menu
        })

        const NavTemplate1 = `
            <!DOCTYPE html>
            <html>
            <head>
              <title>${navbartitle}</title>
               <link rel="icon" type="image/x-icon" href="${logo}">
              <style>
              nav {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: transparent;
                position: relative;
                padding: 0 !important;
                width:100%
              }
              .logo{
                display: flex;
                align-items: center;
                justify-content: start;
              }
              .logo a img{
                height: 40px;
                width: 40px;
              }
            
              nav .nav-item li {
                display: inline-block;
                list-style: none;
                margin: 5px;
              }
            
              nav .nav-item li a {
                display: inline-block;
                color: #000;
                font-size: 20px;
                text-decoration: none;
              }
              .navbar-nav .nav-item a:hover{
                text-decoration: underline;
              }
              .buttoncolor{
                margin: 2px;
                color: rgb(0, 0, 0);
                border: none;
                cursor: pointer;
                display: inline-flex;
                outline: none;
                padding: 0.7em 0.8em;
                position: relative;
                font-size: 14px;
                background:#f0f0f0;
                box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0);
                box-sizing: border-box;
                transition: all 0.2s ease-in-out;
                align-items: center;
                border-color: rgb(227, 227, 227);
                border-style: solid;
                border-width: 0;
                border-radius: 8px;
                justify-content: center;
                text-decoration: none;
              }
              nav .nav-item .nav-link button:hover{
                background-color: lightslategray;
              }
            
              /* =====================Subheader============== */
              .Subheader{
                background-color: #fafafa;
                height: 7px;
              background: var(--bg-header);
              box-shadow: 0 1px #eaeaea;
              left: 0;
              position: -webkit-sticky;
              position: sticky;
              right: 0;
              top: 0;
              z-index: 100;
             }
            
              /* =========== Welcome to cofee hub=========== */
              /* .buttoncolor{
                background-color:#f8f9fa;
              } */
              .menu{
                border-radius: 20px;
                border: 1px solid #f1f3f5;
              }
              .heading{
                font-size: 56px;
                font-weight: 600;
                color: #212529;
              }
              .heading2{
                font-size: 29px;
                font-weight: 600;
                color: #212529;
              }
              .card{
                background-color: #f8f9fa;
                background-image: linear-gradient(45deg, rgb(255, 255, 255) 0%, rgb(241, 242, 243) 100%);;
                border: 1px solid rgb(235, 235, 235);
                border-radius: 10px;
                margin: 16px;
                padding: 16px;
                box-shadow: 0px 19px 30px -10px rgba(21, 21, 25, 0.21);;
              }
            
              .footer-links  p{
                margin: 0;
                padding: 0;
              }
              .footer-links  p a{
                text-decoration: transparent;
                color: #212529;
              }
              .footer-links  p a:hover{
                text-decoration:underline;
              }
            
            
              .scrolling-text {
                white-space: nowrap;
                
                animation: scroll-left 60s linear infinite;
             }
            
             @keyframes scroll-left {
                0% { transform: translateX(100%); }
                100% { transform: translateX(-100%); }
             }.container {
              width: 80%; 
              margin: 0 auto; 
              overflow: hidden; 
            
              padding: 1px; 
             }
             <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
             </style>            
            </head>
            <body>
                 ${headerdiv == true ? `
                <div style=' width: 100%; height: 20px; background-color: ${headerdivbgcolor}; color: ${headerdivtextcolor}; display:flex;justify-content:center;align-items:center; color: ${navbartextcolor}; font-family: ${headertextfont};'>${headerdivtext}</div>` :
                ``}
            <nav class="navbar navbar-expand-lg " style='min-width: 100%; background-color: ${titlecolor}; border-bottom: 1px solid ${conatcmenuborder},padding:50px;padding-top:0px'>
             <div class="container" style='background-color:${titlecolor},min-width: 100%;' >
                 <a class="navbar-brand" href="#">
                 <img src="${newlogo.length > 0 ? newlogo : Newlogo}" alt="logoimage" height="24px" width="24px"  class="me-1" color='black'>
                     <h3 class="d-inline "  style="margin-left: 10px; font-size: 20px; background-color: ${titlecolor}; color: ${navbartextcolor}; font-family: ${headertextfont};">${navbartitle}</h3>
                 </a>
                 <button class="navbar-toggler bg-dark" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                     <span class="navbar-toggler-icon"></span>
                 </button>
                 <div class="collapse navbar-collapse" id="navbarNav">
                     <ul class="navbar-nav ms-auto" style='background-color:${titlecolor};font-size:14px;justify-content: center; justify-items: center;cursor: pointer;' >
                     ${navbarmenu.map((item, index) => `
                     <li class="nav-item" key=${index}>
                             <a class="nav-link  " href="#" style="color: ${navbartextcolor}; font-family: ${headertextfont}; margin: 5px;">${item}</a>
                         </li>
               `).join('')}
                         
                         <li class="nav-item" style=' display: ${navremovebtn1 ? 'none' : ''}; '>
                                                            <a class="nav-link  " href="#" >
                                                            <button class="buttoncolor" style=' font-family: ${button1font};background-color: ${buttoncolor};border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px ' >${navbtn1}</button></a>
                                    </li>
                                  <li class="nav-item" style=' display: ${navremovebtn2 ? 'none' : ''}; '>
                                                            <a class="nav-link  " href="#" >
                                                            <button class="buttoncolor" style=' font-family: ${button1font};background-color: ${buttoncolor};border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px ' >${navbtn2}</button></a>
                                    </li>
                            
                                 ${navAddbutton.map((button, index) => `
                                          <li class="nav-item" style=' display: ${addbuttonapprovel == false ? 'none' : ''}; '>
                                                            <a class="nav-link  " href="#" >
                                                            <button class="buttoncolor" style=' font-family: ${button1font};background-color: ${buttoncolor};border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px ' >${button}</button></a>
                                    </li>
                                   `).join('')}
                     </ul>
                 </div>
             </div>
         </nav>

         <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
        <script src="https:cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
        <script>
            function scrollText(element, speed) {
                let scrollAmount = 0;
                const scrollDelay = speed;
                const textWidth = element.offsetWidth;
                const containerWidth = element.parentElement.offsetWidth;
   
                function scroll() {
                    scrollAmount++;
                   
   
                    if (scrollAmount >= textWidth + containerWidth) {
                        scrollAmount = 0;
                    }
                }
   
                setInterval(scroll, scrollDelay);
            }
   
            const scrollingText = document.getElementById('scrollingText');
            scrollText(scrollingText, 0);  Adjust speed as needed
        </script>

            </body>
            </html>
            `
        const NavTemplate2 = `
               <!DOCTYPE html>
               <html lang="en">
               <head>
                   <meta charset="UTF-8">
                   <meta http-equiv="X-UA-Compatible" content="IE=edge">
                   <meta name="viewport" content="width=device-width, initial-scale=1.0">
                   <title>${navbartitle}</title>
               <link rel="icon" type="image/x-icon" href="${logo}">
                   <style>
                   /* Navbar no 5 */
                   ._58ce83d8 {
                     top: 0;
                     left: 0;
                     right: 0;
                     display: flex;
                     z-index: 99;
                     
                     min-height: 70px;
                     align-items: center;
                    
                   }
                   ._5859e61e {
                     width: 100%;
                     display: flex;
                     flex-direction: column;
                     justify-content: space-between;
                   }
                   ._157dc3d3 {
                     height: 70px;
                     display: flex;
                     padding: 0 30px;
                     position: relative;
                     flex-grow: 1;
                     align-items: center;
                     border-radius: 5px;
                     flex-direction: row;
                     --nav-flyout-bg: #fff;
                   }
                   
                   .um-nav-wrapper {
                     color: #212529;
                     display: flex;
                     flex-grow: 1;
                     align-items: center;
                   }
                   .um-nav-wrapper.um-classic .um-nav-children {
                     margin-right: auto;
                   }
                   ._32caed59 {
                     margin: 0;
                     margin-right: auto;
                   }
                   ._32caed59 > a,
                   ._32caed59 > span {
                     color: inherit;
                     display: flex;
                     align-items: center;
                     line-height: 0;
                   }
                   ._32caed59 > a > div + span,
                   ._32caed59 > span > div + span {
                     margin-left: calc(15px * 1);
                   }
                   .um-nav-links {
                     margin: 0;
                     display: flex;
                     padding: 0;
                     font-size: 15px;
                     list-style: none;
                     align-items: center;
                   }
                   .um-nav-links > li {
                     position: relative;
                   }
                   ._b9923f60 {
                     display: block;
                     height: 24px;
                     width: 24px;
                   }
                   .um-nav-buttons {
                     display: flex;
                     align-items: center;
                     margin-left: 20;
                   }
                   
                   .btn {
                     color: #212529;
                     border: none;
                     cursor: pointer;
                     margin: 0 5px;
                     display: inline-flex;
                     outline: none;
                     padding: 4px 10px;
                   
                     position: relative;
                     background: #eee;
                     box-sizing: border-box;
                     transition: all 0.2s ease-in-out;
                     align-items: center;
                     border-radius: 3px;
                     justify-content: center;
                     text-decoration: none;
                   }
                   .um-nav-drawerTrigger {
                     width: 3em;
                     border: none;
                     height: 3em;
                     margin: 0;
                     padding: 0;
                     position: relative;
                     background: transparent;
                     border-radius: 4px;
                   }
                   .um-nav-drawerTrigger::after {
                     top: 9px;
                     left: 7px;
                     width: 26px;
                     height: 4px;
                     content: "";
                     opacity: 0.8;
                     position: absolute;
                     background: #343a40;
                     box-shadow: 0 8px 0 #343a40, 0 16px 0 #343a40;
                     border-radius: 2px;
                   }
                   
                   @keyframes keyframes-navbar {
                     0% {
                       opacity: 0;
                       transform: scale(0.9) translate(0, -10px);
                     }
                     100% {
                       opacity: 1;
                       transform: scale(1) translate(0, 0);
                     }
                   }
                   .um-nav-drawer {
                     position: absolute;
                     top: 20%;
                     right: 20px;
                     background-color: #fff;
                     border: 1px solid red;
                   }
                   
                   .um-nav-drawer header {
                     display: flex;
                     padding: 5px;
                     border-bottom: 1px solid #f1f3f5;
                     justify-content: flex-end;
                   }
                   .um-nav-drawer header .um-nav-drawerClose {
                     all: unset;
                     width: 40px;
                     border: none;
                     cursor: pointer;
                     height: 40px;
                     margin: 0;
                     padding: 0;
                     font-size: 30px;
                     background: transparent;
                     text-align: center;
                   }
                   .um-nav-drawer .um-drawer-links {
                     display: flex;
                     list-style: none;
                     flex-direction: column;
                   }
                   .um-nav-drawer .um-drawer-links > li {
                     border-bottom: 1px solid #f1f3f5;
                   }
                   .um-nav-drawer .um-drawer-links > li > a {
                     font: inherit;
                     display: block;
                     padding: 12px 20px;
                     font-size: 1em;
                   }
                   .um-nav-drawer .um-drawer-buttons {
                     margin: 25px;
                     display: flex;
                     flex-wrap: wrap;
                     justify-content: flex-end;
                   }
                   .um-nav-drawer .um-drawer-buttons button {
                     width: 100%;
                     flex-grow: 1;
                     font-size: 15px !important;
                     margin-bottom: 10px;
                   }
                   .btn span {
                     text-decoration: none;
                   }
                   @media (max-width: 992px) {
                     .um-nav-links,
                     .um-nav-buttons {
                       display: none !important;
                     }
                   }
                   @media (min-width: 992px) {
                     .drawerTrigger  {
                       display: none !important;
                     }
                   }
                   
                   .wr {
                     width: 100%;
                     margin: 0 auto;
                     padding: 0 30px;
                     position: relative;
                     max-width: 550px;
                     box-sizing: border-box;
                   }
                   ._18a9f441 {
                     display: flex;
                     justify-content: center;
                   }
                   ._f6f5267b {
                     border: 1px solid #f1f3f5;
                     display: flex;
                     padding:6px 20px;
                     z-index: 10;
                     position: relative;
                     flex-grow: 1;
                     margin-top: 20px;
                     max-width: 100%;
                     background: #fff;
                     box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.2);
                     align-items: center;
                     border-radius: 6px;
                     flex-direction: row;
                     --nav-flyout-bg: #fff;
                   }
                   
                   ._f4c879d8 {
                       display: flex;
                       z-index: 10;
                       position: relative;
                       flex-grow: 1;
                       box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 40px -10px;
                       align-items: center;
                       flex-direction: row;
                       --nav-flyout-bg: #fff;
                       border: 1px solid #f1f3f5;
                       padding: 15px 20px;
                       background: #fff;
                       border-radius: 5px;
                       margin-top: 15px;
                   }._9698a7cf {
                       margin: 0px auto 0px 0px;
                   }
                   ._9698a7cf > a, ._9698a7cf > span {
                      
                       color: inherit;
                       display: flex;
                       align-items: center;
                       line-height: 0;
                   }
                   </style>
                   <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
               </head>
               <body>
              
               <div class="container navbar-expand-lg" style=" background-color:${introcolor};border-bottom: 1px solid ${conatcmenuborder};min-width: 100%;">
               <section class="_58ce83d8">
                   <div class="wr">
                       <div class="_18a9f441">
                           <div class="_f6f5267b" style="background-color: ${titlecolor}; border: 1px solid ${conatcmenuborder};min-width:33rem;">
                               <div class="um-nav-wrapper">
                                   <nav class="um-nav-children">
                                       <div class="_32caed59" style="gap: 1px; font-size: 14px;">
                                           <a>
                                               <div class="_8c82ce03" style="scale: 1;">
                                                   <img src="${newlogo.length > 0 ? newlogo : Newlogo}" class="_b9923f60" alt="11.svg" />
                                               </div>
                                               <span class="_11650ac9" style="margin-left: 10px; color: black; font-size: 20px; background-color: ${titlecolor}; color: ${navbartextcolor}; font-family: ${headertextfont};">${navbartitle}</span>
                                           </a>
                                       </div>
                                <button onClick={handleToggle} class="um-nav-drawerTrigger" title="Open Mobile Menu"></button>
                                        
                                   </nav>
                               </div>
                              
                           </div>
                       </div>
                   </div>
               </section>
           </div>
           

  
       
           <script>
               let isOpen = false;
       
               function toggleDrawer() {
                const drawer = document.getElementById('navDrawer');
                if (drawer.style.display === 'none' || drawer.style.display === '') {
                    drawer.style.display = 'block';
                } else {
                    drawer.style.display = 'none';
                }
            }
                 
       
            function handleClose() {
                document.getElementById('navDrawer').style.display = 'none';
            }
           </script>
           <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
               </body>
               </html>
               `
        const NavTemplate3 = `
               <!DOCTYPE html>
               <html lang="en">
               <head>
                   <meta charset="UTF-8" />
                   <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                   <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                   <title>${navbartitle}</title>
               <link rel="icon" type="image/x-icon" href="${logo}">
                   <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
                     <style>
                     ._58ce83d8 {
                        top: 0;
                        left: 0;
                        right: 0;
                        display: flex;
                        z-index: 99;
                        
                        min-height: 70px;
                        align-items: center;
                       
                      }
                      ._5859e61e {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                      }
                      ._157dc3d3 {
                        height: 70px;
                        display: flex;
                        padding: 0 30px;
                        position: relative;
                        flex-grow: 1;
                        align-items: center;
                        border-radius: 5px;
                        flex-direction: row;
                        --nav-flyout-bg: #fff;
                      }
                      .nav-item  {
                        display: inline-block;
                        list-style: none;
                        margin: 5px;
                      }
                      
                      .um-nav-wrapper {
                        color: #212529;
                        display: flex;
                        flex-grow: 1;
                        align-items: center;
                      }
                      .um-nav-wrapper.um-classic .um-nav-children {
                        margin-right: auto;
                      }
                      ._32caed59 {
                        margin: 0;
                        margin-right: auto;
                      }
                      ._32caed59 > a,
                      ._32caed59 > span {
                        color: inherit;
                        display: flex;
                        align-items: center;
                        line-height: 0;
                      }
                      ._32caed59 > a > div + span,
                      ._32caed59 > span > div + span {
                        margin-left: calc(15px * 1);
                      }
                      .um-nav-links {
                        margin: 0;
                        display: flex;
                        padding: 0;
                        font-size: 15px;
                        list-style: none;
                        align-items: center;
                      }
                      .um-nav-links > li {
                        position: relative;
                      }
                      ._b9923f60 {
                        display: block;
                        height: 24px;
                        width: 24px;
                      }
                      .um-nav-buttons {
                        display: flex;
                        align-items: center;
                        margin-left: 20;
                      }
                      
                      .btn {
                        color: #212529;
                        border: none;
                        cursor: pointer;
                        margin: 0 5px;
                        display: inline-flex;
                        outline: none;
                        padding: 4px 10px;
                      
                        position: relative;
                        background: #eee;
                        box-sizing: border-box;
                        transition: all 0.2s ease-in-out;
                        align-items: center;
                        border-radius: 3px;
                        justify-content: center;
                        text-decoration: none;
                      }
                      .um-nav-drawerTrigger {
                        width: 3em;
                        border: none;
                        height: 3em;
                        margin: 0;
                        padding: 0;
                        position: relative;
                        background: transparent;
                        border-radius: 4px;
                      }
                      .um-nav-drawerTrigger::after {
                        top: 9px;
                        left: 7px;
                        width: 26px;
                        height: 4px;
                        content: "";
                        opacity: 0.8;
                        position: absolute;
                        background: #343a40;
                        box-shadow: 0 8px 0 #343a40, 0 16px 0 #343a40;
                        border-radius: 2px;
                      }
                      
                      @keyframes keyframes-navbar {
                        0% {
                          opacity: 0;
                          transform: scale(0.9) translate(0, -10px);
                        }
                        100% {
                          opacity: 1;
                          transform: scale(1) translate(0, 0);
                        }
                      }
                      .um-nav-drawer {
                        position: absolute;
                        top: 20%;
                        right: 20px;
                        background-color: #fff;
                        border: 1px solid red;
                      }
                      
                      .um-nav-drawer header {
                        display: flex;
                        padding: 5px;
                        border-bottom: 1px solid #f1f3f5;
                        justify-content: flex-end;
                      }
                      .um-nav-drawer header .um-nav-drawerClose {
                        all: unset;
                        width: 40px;
                        border: none;
                        cursor: pointer;
                        height: 40px;
                        margin: 0;
                        padding: 0;
                        font-size: 30px;
                        background: transparent;
                        text-align: center;
                      }
                      .um-nav-drawer .um-drawer-links {
                        display: flex;
                        list-style: none;
                        flex-direction: column;
                      }
                      .um-nav-drawer .um-drawer-links > li {
                        border-bottom: 1px solid #f1f3f5;
                      }
                      .um-nav-drawer .um-drawer-links > li > a {
                        font: inherit;
                        display: block;
                        padding: 12px 20px;
                        font-size: 1em;
                      }
                      .um-nav-drawer .um-drawer-buttons {
                        margin: 25px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-end;
                      }
                      .um-nav-drawer .um-drawer-buttons button {
                        width: 100%;
                        flex-grow: 1;
                        font-size: 15px !important;
                        margin-bottom: 10px;
                      }
                      .btn span {
                        text-decoration: none;
                      }
                      @media (max-width: 992px) {
                        .um-nav-links,
                        .um-nav-buttons {
                          display: none !important;
                        }
                      }
                      @media (min-width: 992px) {
                        .drawerTrigger  {
                          display: none !important;
                        }
                      }
                      
                      .wr {
                        width: 100%;
                        margin: 0 auto;
                        padding: 0 30px;
                        position: relative;
                    
                        box-sizing: border-box;
                      }
                      ._18a9f441 {
                        display: flex;
                        justify-content: center;
                      }
                      ._f6f5267b {
                        border: 1px solid #f1f3f5;
                        display: flex;
                        padding:6px 20px;
                        z-index: 10;
                        position: relative;
                        flex-grow: 1;
                        margin-top: 20px;
                        max-width: 700px;
                        background: #fff;
                        box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.2);
                        align-items: center;
                        border-radius: 6px;
                        flex-direction: row;
                        --nav-flyout-bg: #fff;
                      }
                      
                      ._f4c879d8 {
                          display: flex;
                          z-index: 10;
                          position: relative;
                          flex-grow: 1;
                          box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 40px -10px;
                          align-items: center;
                          flex-direction: row;
                          --nav-flyout-bg: #fff;
                          border: 1px solid #f1f3f5;
                          padding: 15px 20px;
                          background: #fff;
                          border-radius: 5px;
                          margin-top: 15px;
                      }._9698a7cf {
                          margin: 0px auto 0px 0px;
                      }
                      ._9698a7cf > a, ._9698a7cf > span {
                         
                          color: inherit;
                          display: flex;
                          align-items: center;
                          line-height: 0;
                      }
                      </style>
               </head>
               <body>
               <div class="container navbar-expand-lg" style="background-color:${introcolor}; borderBottom: 1px solid ${conatcmenuborder};min-width: 100%;">
    <div class="_58ce83d8">
        <div class="wr" style="maxWidth: 100%;">
            <div class="_f4c879d8" style="background-color: ${titlecolor}; border: 1px solid ${conatcmenuborder}; height:4rem;">
                <div class="_9698a7cf" style="gap: 1px; font-size: 16px;">
                    <a class="d-flex align-items-center">
                        <div class="_f7a17dd3" style="scale: 1;">
                            <img src='${newlogo.length > 0 ? newlogo : Newlogo}' class="_246d637b" style="width: 24px; height: 24px;" alt="11.svg" />
                        </div>
                        <span class="_3c86000f" style="marginLeft: 10px; color: black; font-size: 20px; backgroundColor: ${titlecolor}; color: ${navbartextcolor}; font-family: ${headertextfont}; text-decoration:none;">${navbartitle}</span>
                    </a>
                </div>
                <ul class="um-nav-links">
                ${navbarmenu.map((item, index) => `
                  <li>
                  <a href="#" style="color: ${navbartextcolor}; font-family: ${headertextfont}; margin: 5px; text-decoration:none;">${item}</a></li>

            `).join('')}
                    
                
                <div class="um-nav-buttons">
                <li class="nav-item" style=' display: ${navremovebtn1 ? 'none' : ''};list-style:none '>
                <a class="nav-link  m-0 p-0" href="#" ><button class="buttoncolor" 
                 style='font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px ;' >${navbtn1}</button></a>
            </li>
            <li class="nav-item" style=' display: ${navremovebtn2 ? 'none' : ''};  list-style:none'>
            <a class="nav-link  m-0 p-0" href="#"><button class="buttoncolor"
              style='font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px ;'>${navbtn2}</button></a>
        </li>
        ${navAddbutton.map((button, index) => `
          <li class="nav-item" style=' display: ${addbuttonapprovel == false ? 'none' : ''};  list-style:none'>
            <a class="nav-link  m-0 p-0" href="#"><button class="buttoncolor"  style='font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px ;'>${button}</button></a>
        </li>
   `).join('')}
        </ul>
                </div>
                
                </div>
            </div>
        </div>
    </div>
</div>

<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script> 
               </body>
               </html>
               `

        const NavTemplate4 = `
               <!DOCTYPE html>
               <html lang="en">
               <head>
                   <meta charset="UTF-8">
                   <meta http-equiv="X-UA-Compatible" content="IE=edge">
                   <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  <title>${navbartitle}</title>
               <link rel="icon" type="image/x-icon" href="${logo}">
                   <link rel="stylesheet" href="Navbar.css">
                   <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
                   <style>
                   /* Navbar no 5 */
                   ._58ce83d8 {
                     top: 0;
                     left: 0;
                     right: 0;
                     display: flex;
                     z-index: 99;
                     
                     min-height: 70px;
                     align-items: center;
                    
                   }
                   ._5859e61e {
                     width: 100%;
                     display: flex;
                     position: relative;
                     flex-direction: column;
                     justify-content: space-between;
                   }
                   .nav-item  {
                    display: inline-block;
                    list-style: none;
                    margin: 5px;
                  }
                   ._157dc3d3 {
                     height: 70px;
                     display: flex;
                     padding: 0 30px;
                     position: relative;
                     flex-grow: 1;
                     align-items: center;
                     border-radius: 5px;
                     flex-direction: row;
                     --nav-flyout-bg: #fff;
                   }
                   
                   .um-nav-wrapper {
                     color: #212529;
                     display: flex;
                     flex-grow: 1;
                     align-items: center;
                   }
                   .um-nav-wrapper.um-classic .um-nav-children {
                     margin-right: auto;
                   }
                   ._32caed59 {
                     margin: 0;
                     margin-right: auto;
                   }
                   ._32caed59 > a,
                   ._32caed59 > span {
                     color: inherit;
                     display: flex;
                     align-items: center;
                     line-height: 0;
                   }
                   ._32caed59 > a > div + span,
                   ._32caed59 > span > div + span {
                     margin-left: calc(15px * 1);
                   }
                   .um-nav-links {
                     margin: 3px 0px 0px;
                     display: flex;
                     padding: 0;
                     font-size: 15px;
                     list-style-type: none;
                     align-items: center;
                   }
                   .um-nav-links > li {
                     position: relative;
                     list-style-type: none;
                     margin: 3px 0px 0px;

                   }
                   ._b9923f60 {
                     display: block;
                     height: 24px;
                     width: 24px;
                   }
                   .um-nav-buttons {
                     display: flex;
                     align-items: center;
                     margin-left: 20;
                   }
                   
                   .btn {
                     color: #212529;
                     border: none;
                     cursor: pointer;
                     margin: 0 5px;
                     display: inline-flex;
                     outline: none;
                     padding: 4px 10px;
                   
                     position: relative;
                     background: #eee;
                     box-sizing: border-box;
                     transition: all 0.2s ease-in-out;
                     align-items: center;
                     border-radius: 3px;
                     justify-content: center;
                     text-decoration: none;
                   }
                   .um-nav-drawerTrigger {
                     width: 3em;
                     border: none;
                     height: 3em;
                     margin: 0;
                     padding: 0;
                     position: relative;
                     background: transparent;
                     border-radius: 4px;
                   }
                   .um-nav-drawerTrigger::after {
                     top: 9px;
                     left: 7px;
                     width: 26px;
                     height: 4px;
                     content: "";
                     opacity: 0.8;
                     position: absolute;
                     background: #343a40;
                     box-shadow: 0 8px 0 #343a40, 0 16px 0 #343a40;
                     border-radius: 2px;
                   }
                   
                   @keyframes keyframes-navbar {
                     0% {
                       opacity: 0;
                       transform: scale(0.9) translate(0, -10px);
                     }
                     100% {
                       opacity: 1;
                       transform: scale(1) translate(0, 0);
                     }
                   }
                   .um-nav-drawer {
                     position: absolute;
                     top: 20%;
                     right: 20px;
                     background-color: #fff;
                     border: 1px solid red;
                   }
                   
                   .um-nav-drawer header {
                     display: flex;
                     padding: 5px;
                     border-bottom: 1px solid #f1f3f5;
                     justify-content: flex-end;
                   }
                   .um-nav-drawer header .um-nav-drawerClose {
                     all: unset;
                     width: 40px;
                     border: none;
                     cursor: pointer;
                     height: 40px;
                     margin: 0;
                     padding: 0;
                     font-size: 30px;
                     background: transparent;
                     text-align: center;
                   }
                   .um-nav-drawer .um-drawer-links {
                     display: flex;
                     list-style: none;
                     flex-direction: column;
                   }
                   .um-nav-drawer .um-drawer-links > li {
                     border-bottom: 1px solid #f1f3f5;
                   }
                   .um-nav-drawer .um-drawer-links > li > a {
                     font: inherit;
                     display: block;
                     padding: 12px 20px;
                     font-size: 1em;
                   }
                   .um-nav-drawer .um-drawer-buttons {
                     margin: 25px;
                     display: flex;
                     flex-wrap: wrap;
                     justify-content: flex-end;
                   }
                   .um-nav-drawer .um-drawer-buttons button {
                     width: 100%;
                     flex-grow: 1;
                     font-size: 15px !important;
                     margin-bottom: 10px;
                   }
                   .btn span {
                     text-decoration: none;
                   }
                   @media (max-width: 992px) {
                     .um-nav-links,
                     .um-nav-buttons {
                       display: none !important;
                     }
                   }
                   @media (min-width: 992px) {
                     .drawerTrigger  {
                       display: none !important;
                     }
                   }
                   
                   .wr {
                     width: 100%;
                     margin: 0 auto;
                     padding: 0 30px;
                     position: relative;
                     max-width: 550px;
                     box-sizing: border-box;
                   }
                   ._18a9f441 {
                     display: flex;
                     justify-content: center;
                   }
                   ._f6f5267b {
                     border: 1px solid #f1f3f5;
                     display: flex;
                     padding:6px 20px;
                     z-index: 10;
                     position: relative;
                     flex-grow: 1;
                     margin-top: 20px;
                     max-width: 700px;
                     background: #fff;
                     box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.2);
                     align-items: center;
                     border-radius: 6px;
                     flex-direction: row;
                     --nav-flyout-bg: #fff;
                   }
                   
                   ._f4c879d8 {
                       display: flex;
                       z-index: 10;
                       position: relative;
                       flex-grow: 1;
                       box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 40px -10px;
                       align-items: center;
                       flex-direction: row;
                       --nav-flyout-bg: #fff;
                       border: 1px solid #f1f3f5;
                       padding: 15px 20px;
                       background: #fff;
                       border-radius: 5px;
                       margin-top: 15px;
                       margin:5px;
                       widht:100%;
                   }
                   ._9698a7cf {
                       margin: 0px auto 0px 0px;
                   }
                   ._9698a7cf > a, ._9698a7cf > span {
                      
                       color: inherit;
                       display: flex;
                       align-items: center;
                       line-height: 0;
                   }
                   </style>
                   </head>
                   <body>
                   <div class='container'>
                   <div class="_58ce83d8" style="background-color: ${titlecolor}; border-bottom: 1px solid ${conatcmenuborder},min-width: 100%;">
            <div class="_5859e61e">
            <div class="_157dc3d3">
                <nav class="_e45b5504 um-nav-wrapper um-classic">
                <div class="um-nav-children">
                    <div class="_32caed59" style="gap: 1px; font-size: 14px">
                        <a>
                            <div class="_8c82ce03" style="scale: 1">
                                <img src="${newlogo.length > 0 ? newlogo : Newlogo}" class="_b9923f60" alt="11.svg">
                            </div>
                            <span class="_11650ac9" style="font-family: ${headertextfont};color: ${navbartextcolor};">${navbartitle}</span>
                        </a>
                    </div>
                </div>
                <ul class="um-nav-links">
                ${navbarmenu.map((item, index) => `
                <li>
                <a href="#" style="color: ${navbartextcolor}; font-family: ${headertextfont}; margin: 5px; text-decoration:none">${item}</a></li>

            `).join('')}
            
            <div class="um-nav-buttons">
            <li class="nav-item" style=' display: ${navremovebtn1 ? 'none' : ''};list-style:none '>
            <a class="nav-link  m-0 p-0" href="#" ><button class="buttoncolor" 
             style='font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px ;'>${navbtn1}</button></a>
        </li>
        <li class="nav-item" style=' display: ${navremovebtn2 ? 'none' : ''};  list-style:none'>
        <a class="nav-link  m-0 p-0" href="#"><button class="buttoncolor" 
         style='font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px ;'>${navbtn2}</button></a>
    </li>
        ${navAddbutton.map((button, index) => `
          <li class="nav-item" style=' display: ${addbuttonapprovel == false ? 'none' : ''};  list-style:none'>
            <a class="nav-link  m-0 p-0" href="#"><button class="buttoncolor" 
             style='font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px ;'>${button}</button></a>
        </li>
   `).join('')}
    </ul>
            </div>
            </nav>
        </div>
    </div>
</div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
                   </body>
                   </html>
                                 `
        const NavTemplate5 = `
               <!DOCTYPE html>
               <html lang="en">
               <head>
                   <meta charset="UTF-8">
                   <meta http-equiv="X-UA-Compatible" content="IE=edge">
                   <meta name="viewport" content="width=device-width, initial-scale=1.0">
                   <title>${navbartitle}</title>
               <link rel="icon" type="image/x-icon" href="${logo}">
                   <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
                   <style>
                   .um-nav-drawer {
                    background-color: #f0f0f0;
                    padding: 20px;
                    border: 1px solid #ccc;
                    display: none; /* Initially hidden */
                }
                .um-nav-drawerClose {
                    background: none;
                    border: none;
                    cursor: pointer;
                }
                .um-drawer-links {
                    list-style-type: none;
                    padding: 0;
                }
                .um-drawer-links li {
                    margin: 10px 0;
                }
                .um-drawer-links a {
                    text-decoration: none;
                    color: #333;
                }
                .um-drawer-buttons .btn {
                    background-color: #007bff;
                    border: none;
                    color: white;
                    padding: 10px 20px;
                    margin: 5px 0;
                    cursor: pointer;
                    border-radius: 5px;
                }
                .um-drawer-buttons .btn:hover {
                    background-color: #0056b3;
                }
                /* Navbar no 5 */
                ._58ce83d8 {
                  top: 0;
                  left: 0;
                  right: 0;
                  display: flex;
                  z-index: 99;
                  
                  min-height: 70px;
                  align-items: center;
                 
                }
                ._5859e61e {
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                }
                ._157dc3d3 {
                  height: 70px;
                  display: flex;
                  padding: 0 30px;
                  position: relative;
                  flex-grow: 1;
                  align-items: center;
                  border-radius: 5px;
                  flex-direction: row;
                  --nav-flyout-bg: #fff;
                }
                
                .um-nav-wrapper {
                  color: #212529;
                  display: flex;
                  flex-grow: 1;
                  align-items: center;
                }
                .um-nav-wrapper.um-classic .um-nav-children {
                  margin-right: auto;
                }
                ._32caed59 {
                  margin: 0;
                  margin-right: auto;
                }
                ._32caed59 > a,
                ._32caed59 > span {
                  color: inherit;
                  display: flex;
                  align-items: center;
                  line-height: 0;
                }
                ._32caed59 > a > div + span,
                ._32caed59 > span > div + span {
                  margin-left: calc(15px * 1);
                }
                .um-nav-links {
                  margin: 0;
                  display: flex;
                  padding: 0;
                  font-size: 15px;
                  list-style: none;
                  align-items: center;
                }
                .um-nav-links > li {
                  position: relative;
                }
                ._b9923f60 {
                  display: block;
                  height: 24px;
                  width: 24px;
                }
                .um-nav-buttons {
                  display: flex;
                  align-items: center;
                  margin-left: 20;
                }
                
                .btn {
                  color: #212529;
                  border: none;
                  cursor: pointer;
                  margin: 0 5px;
                  display: inline-flex;
                  outline: none;
                  padding: 4px 10px;
                
                  position: relative;
                  background: #eee;
                  box-sizing: border-box;
                  transition: all 0.2s ease-in-out;
                  align-items: center;
                  border-radius: 3px;
                  justify-content: center;
                  text-decoration: none;
                }
                .um-nav-drawerTrigger {
                  width: 3em;
                  border: none;
                  height: 3em;
                  margin: 0;
                  padding: 0;
                  position: relative;
                  background: transparent;
                  border-radius: 4px;
                }
                .um-nav-drawerTrigger::after {
                  top: 9px;
                  left: 7px;
                  width: 26px;
                  height: 4px;
                  content: "";
                  opacity: 0.8;
                  position: absolute;
                  background: #343a40;
                  box-shadow: 0 8px 0 #343a40, 0 16px 0 #343a40;
                  border-radius: 2px;
                }
                
                @keyframes keyframes-navbar {
                  0% {
                    opacity: 0;
                    transform: scale(0.9) translate(0, -10px);
                  }
                  100% {
                    opacity: 1;
                    transform: scale(1) translate(0, 0);
                  }
                }
                .um-nav-drawer {
                  position: absolute;
                  top: 20%;
                  right: 20px;
                  background-color: #fff;
                  border: 1px solid red;
                }
                
                .um-nav-drawer header {
                  display: flex;
                  padding: 5px;
                  border-bottom: 1px solid #f1f3f5;
                  justify-content: flex-end;
                }
                .um-nav-drawer header .um-nav-drawerClose {
                  all: unset;
                  width: 40px;
                  border: none;
                  cursor: pointer;
                  height: 40px;
                  margin: 0;
                  padding: 0;
                  font-size: 30px;
                  background: transparent;
                  text-align: center;
                }
                .um-nav-drawer .um-drawer-links {
                  display: flex;
                  list-style: none;
                  flex-direction: column;
                }
                .um-nav-drawer .um-drawer-links > li {
                  border-bottom: 1px solid #f1f3f5;
                }
                .um-nav-drawer .um-drawer-links > li > a {
                  font: inherit;
                  display: block;
                  padding: 12px 20px;
                  font-size: 1em;
                }
                .um-nav-drawer .um-drawer-buttons {
                  margin: 25px;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: flex-end;
                }
                .um-nav-drawer .um-drawer-buttons button {
                  width: 100%;
                  flex-grow: 1;
                  font-size: 15px !important;
                  margin-bottom: 10px;
                }
                .btn span {
                  text-decoration: none;
                }
                @media (max-width: 992px) {
                  .um-nav-links,
                  .um-nav-buttons {
                    display: none !important;
                  }
                }
                @media (min-width: 992px) {
                  .drawerTrigger  {
                    display: none !important;
                  }
                }
                
              
                ._18a9f441 {
                  display: flex;
                  justify-content: center;
                }
                ._f6f5267b {
                    border: 1px solid #f1f3f5;
                    display: flex;
                    padding:6px 20px;
                    z-index: 10;
                    position: absolute;
                    flex-grow: 1;
                    margin-top: 10px;
                    margin-left:250px;
                    width: 600px;
                    margin-bottom: 15px;
                    background: #fff;
                    box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.2);
                    align-items: center;
                    border-radius: 6px;
                    flex-direction: row;
                    --nav-flyout-bg: #fff;
                  }
                
                ._f4c879d8 {
                    display: flex;
                    z-index: 10;
                    position: relative;
                    flex-grow: 1;
                    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 40px -10px;
                    align-items: center;
                    flex-direction: row;
                    --nav-flyout-bg: #fff;
                    border: 1px solid #f1f3f5;
                    padding: 15px 20px;
                    background: #fff;
                    border-radius: 5px;
                    margin-top: 15px;
                }._9698a7cf {
                    margin: 0px auto 0px 0px;
                }
                ._9698a7cf > a, ._9698a7cf > span {
                   
                    color: inherit;
                    display: flex;
                    align-items: center;
                    line-height: 0;
                }
                   </style>
               </head>
               <body>
                
               <div  class='container' style=" border-bottom: 1px solid ${conatcmenuborder};min-width: 100%;">
               <section class="_58ce83d8" style="position: absolute;">
                   <div class="wr" style="margin: 0px auto 0px 0px;">
                       <div class="_18a9f441" style="justify-content: start;">
                           <div class="_f6f5267b" style="background-color: ${titlecolor}; border: 1px solid ${conatcmenuborder};min-wdth:33rem">
                               <div class="um-nav-wrapper">
                                   <nav class="um-nav-children">
                                       <div class="_32caed59" style="gap: 1px; font-size: 14px;">
                                           <a>
                                               <div class="_8c82ce03" style="scale: 1;">
                                                   <img src="${newlogo.length > 0 ? newlogo : Newlogo}" class="_b9923f60" alt="11.svg" />
                                               </div>
                                               <span class="_11650ac9" style="font-family: ${headertextfont};">${navbartitle}</span>
                                           </a>
                                       </div>
                                   </nav>
                               </div>
                               <button onclick="handleToggle()" class="um-nav-drawerTrigger" title="Open Mobile Menu"></button>
                               ${isOpen ? `
                               <div class="_e45b5504 um-nav-drawer" style='background-color: ${titlecolor};'>
                                   <header>
                                       <button class="um-nav-drawerClose" onclick="handleClose()">
                                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                               <line x1="18" y1="6" x2="6" y2="18"></line>
                                               <line x1="6" y1="6" x2="18" y2="18"></line>
                                           </svg>
                                       </button>
                                   </header>
                                   <div class="um-drawer-links" style='background-color: ${titlecolor};'>
                                   ${navbarmenu.map((item, index) => `
                                                       <li>
                                                       <a href="#" style="color: ${navbartextcolor}; font-family: ${headertextfont}; margin: 5px;text0decoration:none;">${item}</a></li>

                                  `).join('')}
            
                                   </div>
                                   <div class="um-drawer-buttons" >
                                       <button id="d_btn_x2cz0o6ryp" class="_e72e75b2 btn"
                                            style="display:${navremovebtn1 ? 'none' : ''}   ;font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">${navbtn1}
                                       </button>
                                       <button id="d_btn_gni5jwa3za" class="_89517563 btn"
                                            style="display:${navremovebtn1 ? 'none' : ''}   ;font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">${navbtn2}
                                       </button>
                                               ${navAddbutton.map((button, index) => `
                                                      <button id="d_btn_x2cz0o6ryp" class="_e72e75b2 btn"
                                            style="display: ${addbuttonapprovel == false ? 'none' : ''};font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">${button}
                                       </button>
                                               `).join('')}
                                   </div>
                               </div>` : ''}
                           </div>
                       </div>
                   
               </section>
           </div>
         </div>  
    <script>
        function toggleDrawer() {
            const drawer = document.getElementById('navDrawer');
            if (drawer.style.display === 'none' || drawer.style.display === '') {
                drawer.style.display = 'block';
            } else {
                drawer.style.display = 'none';
            }
        }

        function handleClose() {
            document.getElementById('navDrawer').style.display = 'none';
        }
    </script>

                            </div>
                        </div>
                    </div>
                </section>
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
              </body>
               </html>
                   `
        const NavTemplate6 = `

    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>${navbartitle}</title>
               <link rel="icon" type="image/x-icon" href="${logo}">
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
        <style>
        /* Navbar no 5 */
        ._58ce83d8 {
            top: 0;
            left: 0;
            right: 0;
            display: flex;
            z-index: 99;
            
            min-height: 70px;
            align-items: center;
           
          }
          ._5859e61e {
            width: 100%;
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: space-between;
          }
          .nav-item  {
           display: inline-block;
           list-style: none;
           margin: 5px;
         }
          ._157dc3d3 {
            height: 70px;
            display: flex;
            padding: 0 30px;
            position: relative;
            flex-grow: 1;
            align-items: center;
            border-radius: 5px;
            flex-direction: row;
            --nav-flyout-bg: #fff;
          }
          
          .um-nav-wrapper {
            color: #212529;
            display: flex;
            flex-grow: 1;
            align-items: center;
          }
          .um-nav-wrapper.um-classic .um-nav-children {
            margin-right: auto;
          }
          ._32caed59 {
            margin: 0;
            margin-right: auto;
          }
          ._32caed59 > a,
          ._32caed59 > span {
            color: inherit;
            display: flex;
            align-items: center;
            line-height: 0;
          }
          ._32caed59 > a > div + span,
          ._32caed59 > span > div + span {
            margin-left: calc(15px * 1);
          }
          .um-nav-links {
            margin: 3px 0px 0px;
            display: flex;
            padding: 0;
            font-size: 15px;
            list-style-type: none;
            align-items: center;
          }
          .um-nav-links > li {
            position: relative;
            list-style-type: none;
            margin: 3px 0px 0px;

          }
          ._b9923f60 {
            display: block;
            height: 24px;
            width: 24px;
          }
          .um-nav-buttons {
            display: flex;
            align-items: center;
            margin-left: 20;
          }
          
          .btn {
            color: #212529;
            border: none;
            cursor: pointer;
            margin: 0 5px;
            display: inline-flex;
            outline: none;
            padding: 4px 10px;
          
            position: relative;
            background: #eee;
            box-sizing: border-box;
            transition: all 0.2s ease-in-out;
            align-items: center;
            border-radius: 3px;
            justify-content: center;
            text-decoration: none;
          }
          .um-nav-drawerTrigger {
            width: 3em;
            border: none;
            height: 3em;
            margin: 0;
            padding: 0;
            position: relative;
            background: transparent;
            border-radius: 4px;
          }
          .um-nav-drawerTrigger::after {
            top: 9px;
            left: 7px;
            width: 26px;
            height: 4px;
            content: "";
            opacity: 0.8;
            position: absolute;
            background: #343a40;
            box-shadow: 0 8px 0 #343a40, 0 16px 0 #343a40;
            border-radius: 2px;
          }
          
          @keyframes keyframes-navbar {
            0% {
              opacity: 0;
              transform: scale(0.9) translate(0, -10px);
            }
            100% {
              opacity: 1;
              transform: scale(1) translate(0, 0);
            }
          }
          .um-nav-drawer {
            position: absolute;
            top: 20%;
            right: 20px;
            background-color: #fff;
            border: 1px solid red;
          }
          
          .um-nav-drawer header {
            display: flex;
            padding: 5px;
            border-bottom: 1px solid #f1f3f5;
            justify-content: flex-end;
          }
          .um-nav-drawer header .um-nav-drawerClose {
            all: unset;
            width: 40px;
            border: none;
            cursor: pointer;
            height: 40px;
            margin: 0;
            padding: 0;
            font-size: 30px;
            background: transparent;
            text-align: center;
          }
          .um-nav-drawer .um-drawer-links {
            display: flex;
            list-style: none;
            flex-direction: column;
          }
          .um-nav-drawer .um-drawer-links > li {
            border-bottom: 1px solid #f1f3f5;
          }
          .um-nav-drawer .um-drawer-links > li > a {
            font: inherit;
            display: block;
            padding: 12px 20px;
            font-size: 1em;
          }
          .um-nav-drawer .um-drawer-buttons {
            margin: 25px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
          }
          .um-nav-drawer .um-drawer-buttons button {
            width: 100%;
            flex-grow: 1;
            font-size: 15px !important;
            margin-bottom: 10px;
          }
          .btn span {
            text-decoration: none;
          }
          @media (max-width: 992px) {
            .um-nav-links,
            .um-nav-buttons {
              display: none !important;
            }
          }
          @media (min-width: 992px) {
            .drawerTrigger  {
              display: none !important;
            }
          }
          
          .wr {
            width: 100%;
            margin: 0 auto;
            padding: 0 30px;
            position: relative;
            max-width: 550px;
            box-sizing: border-box;
          }
          ._18a9f441 {
            display: flex;
            justify-content: center;
          }
          ._f6f5267b {
            border: 1px solid #f1f3f5;
            display: flex;
            padding:6px 20px;
            z-index: 10;
            position: relative;
            flex-grow: 1;
            margin-top: 20px;
            max-width: 700px;
            background: #fff;
            box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.2);
            align-items: center;
            border-radius: 6px;
            flex-direction: row;
            --nav-flyout-bg: #fff;
          }
          
          ._f4c879d8 {
              display: flex;
              z-index: 10;
              position: relative;
              flex-grow: 1;
              box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 40px -10px;
              align-items: center;
              flex-direction: row;
              --nav-flyout-bg: #fff;
              border: 1px solid #f1f3f5;
              padding: 15px 20px;
              background: #fff;
              border-radius: 5px;
              margin-top: 15px;
              margin:5px;
              widht:100%;
          }
          ._9698a7cf {
              margin: 0px auto 0px 0px;
          }
          ._9698a7cf > a, ._9698a7cf > span {
             
              color: inherit;
              display: flex;
              align-items: center;
              line-height: 0;
          }
        </style>
    </head>
    <body>
    <div class='container'>  
    <div class="_58ce83d8 " style=' background-color: ${titlecolor}, border-bottom: 1px solid ${conatcmenuborder} ' >
    <div class="_5859e61e ">
        <div class="_157dc3d3 px-3">
            <nav class="_e45b5504 um-nav-wrapper um-classic">
                <div class="um-nav-children">
                    <div class="_32caed59" style=' gap: 1px, fontSize: 14px ' >
                        <a>
                            <div class="_8c82ce03" style=' scale: '1' '>
                                <img src="${newlogo.length > 0 ? newlogo : Newlogo}" class="_b9923f60" alt="11.svg" />
                            </div>
                            <span class="_11650ac9" style=' font-family: ${headertextfont} '>AutoPro Hub</span>
                        </a>
                    </div>
                </div>
                <ul class="um-nav-links">
                ${navbarmenu.map((item, index) => `
                                  <li key=${index}>
                                  <a href="#" style="color: ${navbartextcolor}; font-family: ${headertextfont}; margin: 5px; text-decoration:none;">${item}</a></li>

                   `).join('')}

            
            <div class="um-nav-buttons">
            <li class="nav-item" style=' display: ${navremovebtn1 ? 'none' : ''};list-style:none ;'>
            <a class="nav-link  m-0 p-0" href="#" ><button class="buttoncolor" 
             style='font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px ;' >${navbtn1}</button></a>
        </li>
        <li class="nav-item" style=' display: ${navremovebtn2 ? 'none' : ''};  list-style:none;'>
        <a class="nav-link  m-0 p-0" href="#"><button class="buttoncolor"
          style='font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px ;'>${navbtn2}</button></a>
    </li> 
    ${navAddbutton.map((button, index) => `
      <li class="nav-item" style=' display: ${addbuttonapprovel == false ? 'none' : ''};  list-style:none' >
        <a class="nav-link  m-0 p-0" href="#"><button class="buttoncolor" 
         style='font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px ;'>${navbtn2}</button></a>
    </li> 
`).join('')}
    </ul>
            </div>
            </nav>
            
        </div>
    </div>

</div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
    </body>
    </html>
        `

        if (selectedLi === null) {
            var NavbarTemplate = NavTemplate1
        };
        if (selectedLi === 0) {
            var NavbarTemplate = NavTemplate2
        };
        if (selectedLi === 1) {
            var NavbarTemplate = NavTemplate3
        };
        if (selectedLi === 2) {
            var NavbarTemplate = NavTemplate4
        };
        if (selectedLi === 3) {
            var NavbarTemplate = NavTemplate5

        };
        if (selectedLi === 4) {
            var NavbarTemplate = NavTemplate6
        };
        // -----------------------------------Hero--------------------------------------/

        var HeroTemplate1 = `
    <!DOCTYPE html>
    <html lang="en"><head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>${title}</title>
<link rel="stylesheet" href="https:cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css" integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA==" crossorigin="anonymous" referrerpolicy="no-referrer">
<link rel="stylesheet" href="https:cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
<style> 


/* ========= Navigation =========== */
nav {
display: flex;
align-items: center;
justify-content: space-between;
background-color: transparent;
position: relative;
padding: 0 !important;
width:100%
}
.logo{
display: flex;
align-items: center;
justify-content: start;
}
.logo a img{
height: 40px;
width: 40px;
}

nav .nav-item li {
display: inline-block;
list-style: none;
margin: 5px;
}

nav .nav-item li a {
display: inline-block;
color: #000;
font-size: 20px;
text-decoration: none;
}
.navbar-nav .nav-item a:hover{
text-decoration: underline;
}
.buttoncolor{
margin: 2px;
color: rgb(0, 0, 0);
border: none;
cursor: pointer;
display: inline-flex;
outline: none;
padding: 0.7em 0.8em;
position: relative;
font-size: 14px;
background:#f0f0f0;
box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0);
box-sizing: border-box;
transition: all 0.2s ease-in-out;
align-items: center;
border-color: rgb(227, 227, 227);
border-style: solid;
border-width: 0;
border-radius: 8px;
justify-content: center;
text-decoration: none;
}
nav .nav-item .nav-link button:hover{
background-color: lightslategray;
}

/* =====================Subheader============== */
.Subheader{
background-color: #fafafa;
height: 7px;
background: var(--bg-header);
box-shadow: 0 1px #eaeaea;
left: 0;
position: -webkit-sticky;
position: sticky;
right: 0;
top: 0;
z-index: 100;
}

/* =========== Welcome to cofee hub=========== */
/* .buttoncolor{
background-color:#f8f9fa;
} */
.menu{
border-radius: 20px;
border: 1px solid #f1f3f5;
}
.heading{
font-size: 56px;
font-weight: 600;
color: #212529;
}
.heading2{
font-size: 29px;
font-weight: 600;
color: #212529;
}
.card{
background-color: #f8f9fa;
background-image: linear-gradient(45deg, rgb(255, 255, 255) 0%, rgb(241, 242, 243) 100%);;
border: 1px solid rgb(235, 235, 235);
border-radius: 10px;
margin: 16px;
padding: 16px;
box-shadow: 0px 19px 30px -10px rgba(21, 21, 25, 0.21);;
}

.footer-links  p{
margin: 0;
padding: 0;
}
.footer-links  p a{
text-decoration: transparent;
color: #212529;
}
.footer-links  p a:hover{
text-decoration:underline;
}


.scrolling-text {
white-space: nowrap;
animation: scroll-left 60s linear infinite;
}

@keyframes scroll-left {
0% { transform: translateX(100%); }
100% { transform: translateX(-100%); }
}.container {
width: 80%; 
margin: 0 auto; 
overflow: hidden; 

padding: 10px; 
}

</style>
</head>

<body class="bg-white">

<!-- =========== Navigation ========== -->


<!-- ====================== Welcome to Cofee ============================== -->
<div class="container"   style=' background-color: ${introcolor};min-width: 100%; padding: 50px;padding-top:0px'>
    <div class="row" style="margin-top: ${intromargintop}px; margin-bottom: ${intromarginbottom}px;">
        <div class="col-md-6 d-flex align-items-center flex-column justify-content-center">
            <div style="width: 100%;">
                ${selectedOption1 === 'A' ?
                `<button class="mt-5 btn menu" style="font-size: 12px; color: ${intromenutextcolor}; background-color: ${intromenubgcolor}; border: 2px solid ${conatcmenuborder};border-radius:30px">${menu}--<span style="color: ${contactheadingcolor};">${menu1}</span></button>`
                :
                `<div>
                        <button class="mt-5 btn menu" style="font-size: 12px; border-style: none; color: ${intromenutextcolor}; background-color: ${intromenubgcolor};">${menu}</button>
                        <button class="mt-5 btn menu" style="font-size: 12px; border-style: none;">${menu1}</button>
                    </div>`
            }
            </div>
            <p style="color: #9c36b5; position: relative;">${introcaption}</p>
            <h1 style="font-size: 48px; font-weight: 780; color: ${contenttextheadingcolor}; font-family: ${introtextfont};">${title}</h1>
            <p class="fs-0" style="color: ${contenttextinfocolor}; font-family: ${introinfotextfont};">${intro}</p>
        </div>
        <div class="col-md-6 p-4">
            ${newIntroImage.length > 0 ?
                `<div>
                    ${newIntroImage.map((image, index) => `
                        <img
                            style="height: 390px; width: 100%; border-radius: 10px;"
                            key=${index}
                            src=${image}
                            alt="Image ${index}"
                            class="media_image__kN9DM"
                        />
                    `).join('')}
                </div>`
                :
                `<img style="height: 390px; width: 100%; border-radius: 10px;" src="${introImages}" class="img-fluid shadow" alt="" />`
            }
        </div>
        <div class="twobutton" style="width: 100%; color: {introtext}; display: flex; gap: 0px 10px;">
            <a href="#">
                <button class="buttoncolor" style="display: ${introbtn1remove ? 'none' : ''}; font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">
                    ${inttrobtn1}
                </button>
            </a>
            <a href="#">
                <button class="buttoncolor" style="display: ${introbtn2remove ? 'none' : ''}; font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">
                    ${introbtn2}
                </button>
            </a>
            ${addintrobutton.map((button, index) => `
                <a href="#" key=${index} style="display: ${introbuttonapprovel == false ? 'none' : ''};">
                    <button class="buttoncolor" style="font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">
                        ${button}
                    </button>
                </a>
            `).join('')}
        </div>
    </div>
</div>



<script src="https:cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
<script>
    function scrollText(element, speed) {
        let scrollAmount = 0;
        const scrollDelay = speed;
        const textWidth = element.offsetWidth;
        const containerWidth = element.parentElement.offsetWidth;

        function scroll() {
            scrollAmount++;
           

            if (scrollAmount >= textWidth + containerWidth) {
                scrollAmount = 0;
            }
        }

        setInterval(scroll, scrollDelay);
    }

    const scrollingText = document.getElementById('scrollingText');
    scrollText(scrollingText, 0);  Adjust speed as needed
</script>


</body></html>
`;
        var HeroTemplate2 = `
     <!DOCTYPE html>
     <html lang="en">
     <head>
     <title>${title}</title>
         <meta charset="UTF-8">
         <meta http-equiv="X-UA-Compatible" content="IE=edge">
         <meta name="viewport" content="width=device-width, initial-scale=1.0">
         <title>Document</title>
         <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
         <style>
         ._f497df412 {
            display: flex;
            padding: 8px;
            background: rgb(255, 255, 255);
            box-shadow: 0px 19px 30px -10px rgba(21, 21, 25, 0.21);
            box-sizing: border-box;
            border-color: rgb(255, 255, 255);
            border-style: solid;
            border-width: 0px;
            border-radius: 10px;
        }
        .buttoncolor:hover {
            background-color: rgb(219, 214, 214);
          }
          .buttoncolor {
            margin: 5px;
            color: rgb(0, 0, 0);
            border: none;
            cursor: pointer;
            display: inline-flex;
            outline: none;
            /* padding: 0.7em 0.8em; */
            padding: 4px;
            position: relative;
            /* font-size: 13px; */
            background: rgb(236, 235, 235);
            box-shadow: 0px 2px 5px 0px rgba(221, 219, 219, 0.959);
            box-sizing: border-box;
            transition: all 0.2s ease-in-out;
            align-items: center;
            border-color: rgb(220, 213, 213);
            border-style: solid;
            border-width: 1px;
            border-radius: 8px;
            justify-content: center;
            text-decoration: none;
            width: 90px;
            height: 35px;
            overflow: hidden;
          }
          .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          ._e51b713c {
            width: 100%;
            height: 100%;
            object-fit: cover;
            box-sizing: border-box;
          }
          
         </style>
     </head>
      <body>
     <div id="app">
     <div class="container" style=' backgroundColor: ${introcolor};min-width: 100%;padding: 50px;padding-top:0px; '>
     <div class="row" style="margin-top: ${intromargintop}px; margin-bottom: ${intromarginbottom}px;">
         <div class="col-md-6 d-flex align-items-center flex-column justify-content-center">
             <div style="width: 100%;">
                 ${selectedOption1 == 'A' ?
                `<button class="mt-5 btn menu" style="font-size: 12px; color: ${intromenutextcolor}; background-color: ${intromenubgcolor}; border: 2px solid ${conatcmenuborder};">${menu}--<span style="color: ${contactheadingcolor};">${menu1}</span></button>`
                :
                `<div>
                         <button class="mt-5 btn menu" style="font-size: 12px; border-style: none; color: ${intromenutextcolor}; background-color: ${intromenubgcolor};">${menu}</button>
                         <button class="mt-5 btn menu" style="font-size: 12px; border-style: none;">${menu1}</button>
                     </div>`
            }
             </div>
             <p style="color: ${captioncolor ? captioncolor : '#9c36b5'}; position: relative;">${introcaption}</p>
             <h1 style="font-size: 48px; font-weight: 780; color: ${contenttextheadingcolor}; font-family: ${introtextfont};">${title}</h1>
             <p class="fs-0" style="color: ${contenttextinfocolor}; font-family: ${introinfotextfont};">${intro}</p>
             <div class="twobutton" style="width: 100%; color: ${introtext}; display: flex; gap: 0px 10px;">
                 <a href="#"><button class="buttoncolor" style="display: ${introbtn1remove ? 'none' : ''}; font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">${inttrobtn1}</button></a>
                 <a href="#"><button class="buttoncolor" style="display: ${introbtn2remove ? 'none' : ''}; font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">${introbtn2}</button></a>
                 ${addintrobutton.map((button, index) => `
                     <a href="#" key=${index} style="display: ${introbuttonapprovel == false ? 'none' : ''};">
                         <button class="buttoncolor" style="font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">${button}</button>
                     </a>
                 `).join('')}
             </div>
         </div>
         <div class="col-md-6 p-4">
             <div class="_f497df412">
                  ${newIntroImage.length > 0 ? `
              <div>
                  ${newIntroImage.map((image, index) => `
                      <img  src="${image}" alt="Image ${index}" class="_e51b713c">
                  `).join('')}
              </div>
          ` : `
              <img style="height: 500px;" src="${introImages}" class="img-fluid" alt="">
          `}
             </div>
         </div>
     </div>
 </div>
 
 
 <script>
     document.addEventListener("DOMContentLoaded", function() {
         // Add event listeners and functionality here
         const menuBtn = document.getElementById("menuBtn");
         const menu1Btn = document.getElementById("menu1Btn");
         const introCaption = document.getElementById("introCaption");
         const title = document.getElementById("title");
         const introText = document.getElementById("introText");
 
         menuBtn.addEventListener("click", function() {
             introCaption.textContent = "Updated Intro Caption";
             title.textContent = "Updated Title";
             introText.textContent = "Updated Intro Text";
         });
 
         menu1Btn.addEventListener("click", function() {
             introCaption.textContent = "Another Updated Caption";
             title.textContent = "Another Updated Title";
             introText.textContent = "Another Updated Intro Text";
         });
 
         const btn1 = document.getElementById("btn1");
         const btn2 = document.getElementById("btn2");
 
         btn1.addEventListener("click", function() {
             alert("Button 1 clicked");
         });
 
         btn2.addEventListener("click", function() {
             alert("Button 2 clicked");
         });
     });
 </script>
 <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
 </body>
     </html>
     `
        var HeroTemplate3 = `
     <!DOCTYPE html>
     <html lang="en">
     <head>
     
         <meta charset="UTF-8">
         <meta http-equiv="X-UA-Compatible" content="IE=edge">
         <meta name="viewport" content="width=device-width, initial-scale=1.0">
         <title>Document</title>
         <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
          <style>
          .media_image__kN9DM {
            background: #e6e6e6;
            border-radius: 3px;
            box-sizing: border-box;
            height: 28px;
            max-height: 28px;
            max-width: 38px;
            object-fit: contain;
            padding: 0px 3px;
          }
          .buttoncolor:hover {
            background-color: rgb(219, 214, 214);
          }
          .buttoncolor {
            margin: 5px;
            color: rgb(0, 0, 0);
            border: none;
            cursor: pointer;
            display: inline-flex;
            outline: none;
            /* padding: 0.7em 0.8em; */
            padding: 4px;
            position: relative;
            /* font-size: 13px; */
            background: rgb(236, 235, 235);
            box-shadow: 0px 2px 5px 0px rgba(221, 219, 219, 0.959);
            box-sizing: border-box;
            transition: all 0.2s ease-in-out;
            align-items: center;
            border-color: rgb(220, 213, 213);
            border-style: solid;
            border-width: 1px;
            border-radius: 8px;
            justify-content: center;
            text-decoration: none;
            width: 90px;
            height: 35px;
            overflow: hidden;
          }
          .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          </style>
     </head>
     <body>
     <div class="container" style='background-color:${introcolor};min-width: 100%; padding: 50px;padding-top:0px'>
     <div class="row" style="margin-top: 10px; margin-bottom: 20px;">
       <div class="col-md-12 d-flex align-items-center flex-column justify-content-center">
         <div class="text-center" style="width: 100%;">
           ${selectedOption1 === 'A' ? `
             <button class="mt-5 btn menu" style="font-size: 12px; color: ${intromenutextcolor}; background-color: ${intromenubgcolor}; border: 2px solid ${conatcmenuborder};">
               ${menu}--<span style="color: ${contactheadingcolor};">${menu1}</span>
             </button>` : `
             <div>
               <button class="mt-5 btn menu" style="font-size: 12px; border-style: none; color: ${intromenutextcolor}; background-color: ${intromenubgcolor};">${menu}</button>
               <button class="mt-5 btn menu" style="font-size: 12px; border-style: none;">${menu1}</button>
             </div>
           `}
         </div>
         <p style="color: ${captioncolor ? captioncolor : '#9c36b5'}; position: relative;">${introcaption}</p>
         <h1 style="font-size: 48px; font-weight: 780; color: ${contenttextheadingcolor}; font-family: ${introtextfont};">${title}</h1>
         <p class="fs-0" style="color: ${contenttextinfocolor}; font-family: ${introinfotextfont};">${intro}</p>
         <div class="twobutton d-flex align-items-center justify-content-center" style="width: 100%; color: ${introtext}; display: flex; gap: 0px 10px;">
           <a href="#" style='link-style:none'>
             <button class="buttoncolor" style="display: ${introbtn1remove ? 'none' : 'block'}; font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">${inttrobtn1}</button>
           </a>
           <a href="#" style='link-style:none'>
             <button class="buttoncolor" style="display: ${introbtn2remove ? 'none' : 'block'}; font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">${introbtn2}</button>
           </a>
           ${introbuttonapprovel ? addintrobutton.map((button, index) => `
             <a href="#"  style="display: block;">
               <button class="buttoncolor" style="font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">${button}</button>
             </a>`).join('') : ''}
         </div>
       </div>
       <div class="col-md-12 p-4">
          ${newIntroImage.length > 0 ? `
              <div>
                  ${newIntroImage.map((image, index) => `
                      <img  src="${image}" alt="Image ${index}" style="height: 390px; width:100%;border-radius: 10px" class="_e51b713c">
                  `).join('')}
              </div>
          ` : `
              <img style="height: 390px; width:100%;border-radius: 10px" src="${introImages}" class="img-fluid" alt="">
          `}
       </div>
     </div>
   </div>
   <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
     </body>
     </html>
     `
        var HeroTemplate4 = `
     <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Styled Section</title>
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
     <style>
     .container {
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
      
        padding: 10px;
      }
      .buttoncolor {
        margin: 5px;
        color: rgb(0, 0, 0);
        border: none;
        cursor: pointer;
        display: inline-flex;
        outline: none;
        /* padding: 0.7em 0.8em; */
        padding: 4px;
        position: relative;
        /* font-size: 13px; */
        background: rgb(236, 235, 235);
        box-shadow: 0px 2px 5px 0px rgba(221, 219, 219, 0.959);
        box-sizing: border-box;
        transition: all 0.2s ease-in-out;
        align-items: center;
        border-color: rgb(220, 213, 213);
        border-style: solid;
        border-width: 1px;
        border-radius: 8px;
        justify-content: center;
        text-decoration: none;
        width: 90px;
        height: 35px;
        overflow: hidden;
      }
      .buttoncolor:hover {
        background-color: rgb(219, 214, 214);
      }
      ._f497df413 {
        max-width: 50vw;
        box-sizing: border-box;
        flex-shrink: 0;
        padding-right: 50px;
        padding-top: 50px;
      }
      ._e51b713c {
        width: 100%;
        height: 100%;
        object-fit: cover;
        box-sizing: border-box;
      }
     </style>
</head>
<body>
    <div class="container" style='background-color:${introcolor};min-width: 100%;padding: 50px;padding-top:0px'>
        <div class="row" style="margin-top: 10px; margin-bottom: 20px;">
            <div class="col-md-6 d-flex align-items-center flex-column justify-content-center">
                <div style="width: 100%;">
                    ${selectedOption1 === 'A' ? `
                        <button class="mt-5 btn menu" style="font-size: 12px; color: ${intromenutextcolor}; background-color: ${intromenubgcolor}; border: 2px solid ${conatcmenuborder};">
                            ${menu}--<span style="color: ${contactheadingcolor};">${menu1}</span>
                        </button>
                    ` : `
                        <div>
                            <button class="mt-5 btn menu" style="font-size: 12px; border-style: none; color: ${intromenutextcolor}; background-color: ${intromenubgcolor};">${menu}</button>
                            <button class="mt-5 btn menu" style="font-size: 12px; border-style: none;">${menu1}</button>
                        </div>
                    `}
                </div>
                <p style="color: ${captioncolor ? captioncolor : '#9c36b5'}; position: relative;">${introcaption}</p>
                <h1 style="font-size: 48px; font-weight: 780; color: ${contenttextheadingcolor}; font-family: ${introtextfont};">${title}</h1>
                <p class="fs-0" style="color: ${contenttextinfocolor}; font-family: ${introinfotextfont};">${intro}</p>
                <div class="twobutton" style="width: 100%; color: ${introtext}; display: flex; gap: 0px 10px;">
                    <a href="#">
                        <button class="buttoncolor" style="display: ${introbtn1remove ? 'none' : 'block'}; font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">
                            ${inttrobtn1}
                        </button>
                    </a>
                    <a href="#">
                        <button class="buttoncolor" style="display: ${introbtn2remove ? 'none' : 'block'}; font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">
                            ${introbtn2}
                        </button>
                    </a>
                    ${introbuttonapprovel ? addintrobutton.map((button, index) => `
                        <a href="#"  style="display: block;">
                            <button class="buttoncolor" style="font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">
                                ${button}
                            </button>
                        </a>
                    `).join('') : ''}
                </div>
            </div>
            <div class="col-md-6 p-4">
                <div class="_f497df413">
                    ${newIntroImage.length > 0 ? `
              <div>
                  ${newIntroImage.map((image, index) => `
                      <img  src="${image}" alt="Image ${index}" class="_e51b713c">
                  `).join('')}
              </div>
          ` : `
              <img style="height: 500px;" src="${introImages}" class="img-fluid" alt="">
          `}
                </div>
            </div>
        </div>
    </div>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
</body>
</html>
`
        var HeroTemplate5 = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Styled Section</title>
        <style>
            .twobutton {
                display: flex;
                gap: 0px 10px;
            }
            .container {
                width: 100%;
                margin: 0 auto;
                overflow: hidden;
              
                padding: 10px;
              }
              .buttoncolor {
                margin: 5px;
                color: rgb(0, 0, 0);
                border: none;
                cursor: pointer;
                display: inline-flex;
                outline: none;
                /* padding: 0.7em 0.8em; */
                padding: 4px;
                position: relative;
                /* font-size: 13px; */
                background: rgb(236, 235, 235);
                box-shadow: 0px 2px 5px 0px rgba(221, 219, 219, 0.959);
                box-sizing: border-box;
                transition: all 0.2s ease-in-out;
                align-items: center;
                border-color: rgb(220, 213, 213);
                border-style: solid;
                border-width: 1px;
                border-radius: 8px;
                justify-content: center;
                text-decoration: none;
                width: 90px;
                height: 35px;
                overflow: hidden;
              }
              .buttoncolor:hover {
                background-color: rgb(219, 214, 214);
              }
              ._f497df41 {
                right: 0;
                bottom: 0;
                position: relative;
                clip-path: polygon(10% 0, 0 100%, 100% 100%, 100% 0);
                object-fit: cover;
              }
              ._e51b713c {
                width: 100%;
                height: 100%;
                object-fit: cover;
                box-sizing: border-box;
              }
              
        </style>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
    </head>
    <body>
        <div class="container py-0" style="background-color:${introcolor};min-width: 100%; padding: 50px;padding-top:0px;">
            <div class="row" style="margin-top: 20px; margin-bottom: 20px;">
                <div class="col-md-6 d-flex align-items-center flex-column justify-content-center">
                    <div style="width: 100%;">
                        ${selectedOption1 === 'A' ? `
                            <button class="mt-5 btn menu" style="font-size: 12px; color: ${intromenutextcolor}; background-color: ${intromenubgcolor}; border: 2px solid ${conatcmenuborder};">
                                ${menu}--<span style="color: ${contactheadingcolor};">${menu1}</span>
                            </button>
                        ` : `
                            <div>
                                <button class="mt-5 btn menu" style="font-size: 12px; border-style: none; color: ${intromenutextcolor}; background-color: ${intromenubgcolor};">${menu}</button>
                                <button class="mt-5 btn menu" style="font-size: 12px; border-style: none;">${menu1}</button>
                            </div>
                        `}
                    </div>
                    <p style="color: ${captioncolor ? captioncolor : '#9c36b5'}; position: relative;">${introcaption}</p>
                    <h1 style="font-size: 48px; font-weight: 780; color: ${contenttextheadingcolor}; font-family: ${introtextfont};">${title}</h1>
                    <p class="fs-0" style="color: ${contenttextinfocolor}; font-family: ${introinfotextfont};">${intro}</p>
                    <div class="twobutton" style="width: 100%; color: ${introtext};">
                        <a href="#">
                            <button class="buttoncolor" style="display: ${introbtn1remove ? 'none' : 'block'}; font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">
                                ${inttrobtn1}
                            </button>
                        </a>
                        <a href="#">
                            <button class="buttoncolor" style="display: ${introbtn2remove ? 'none' : 'block'}; font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">
                                ${introbtn2}
                            </button>
                        </a>
                        ${introbuttonapprovel ? addintrobutton.map((button, index) => `
                            <a href="#"  style="display: block;">
                                <button class="buttoncolor" style="font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">
                                    ${button}
                                </button>
                            </a>
                        `).join('') : ''}
                    </div>
                </div>
                <div class="col-md-6 p-4">
                    <div class="_f497df41">
                         ${newIntroImage.length > 0 ? `
              <div>
                  ${newIntroImage.map((image, index) => `
                      <img  src="${image}" alt="Image ${index}" class="_e51b713c">
                  `).join('')}
              </div>
          ` : `
              <img style="height: 500px;" src="${introImages}" class="img-fluid" alt="">
          `}
                    </div>
                </div>
            </div>
        </div>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
    </body>
    </html>
    `

        var HeroTemplate6 = `
    <!DOCTYPE html>
    <html>
    <head>
     <style>
     .container {
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
      
        padding: 10px;
      }
      .buttoncolor {
        margin: 5px;
        color: rgb(0, 0, 0);
        border: none;
        cursor: pointer;
        display: inline-flex;
        outline: none;
        /* padding: 0.7em 0.8em; */
        padding: 4px;
        position: relative;
        /* font-size: 13px; */
        background: rgb(236, 235, 235);
        box-shadow: 0px 2px 5px 0px rgba(221, 219, 219, 0.959);
        box-sizing: border-box;
        transition: all 0.2s ease-in-out;
        align-items: center;
        border-color: rgb(220, 213, 213);
        border-style: solid;
        border-width: 1px;
        border-radius: 8px;
        justify-content: center;
        text-decoration: none;
        width: 90px;
        height: 35px;
        overflow: hidden;
      }
      .buttoncolor:hover {
        background-color: rgb(219, 214, 214);
      }
      ._e51b713c {
        width: 100%;
        height: 100%;
        object-fit: cover;
        box-sizing: border-box;
      }
      ._f497df414 {
        max-width: 50vw;
        box-sizing: border-box;
        flex-shrink: 0;
      }
     </style>
     <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
    </head>
    <body>
    <div class="container"  style='background-color:${introcolor};min-width: 100%;padding: 50px;padding-top:0px;'>
    <div class="row" style="margin-top: ${intromargintop}px; margin-bottom: ${intromarginbottom}px;">
        <div class="col-md-6 d-flex align-items-center flex-column justify-content-center">
            <div style="width: 100%;">
                <button class="mt-5 btn menu" style="font-size: 12px; color: ${intromenutextcolor}; background-color: ${intromenubgcolor}; border: 2px solid ${conatcmenuborder};">${menu}--<span style="color: ${contactheadingcolor};">${menu1}</span></button>
            </div>
            <p style="color: ${captioncolor ? captioncolor : '#9c36b5'}; position: relative;">${introcaption}</p>
            <h1 style="font-size: 48px; font-weight: 780; color: ${contenttextheadingcolor}; font-family: ${introtextfont};">${title}</h1>
            <p class="fs-0" style="color: ${contenttextinfocolor}; font-family: ${introinfotextfont};">${intro}</p>
            <div class="twobutton" style="width: 100%; color: ${introtext}; display: flex; gap: 0px 10px;">
                <a href="#"><button class="buttoncolor" style="font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">${inttrobtn1}</button></a>
                <a href="#"><button class="buttoncolor" style="font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">${introbtn2}</button></a>
                ${addintrobutton.map((button, index) => (
            `<a href="#" key=${index}><button class="buttoncolor" style="font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">${button}</button></a>`
        ))}
            </div>
        </div>
        <div class="col-md-6">
            <div class="_f497df414">
                 ${newIntroImage.length > 0 ? `
              <div>
                  ${newIntroImage.map((image, index) => `
                      <img  src="${image}" alt="Image ${index}" class="_e51b713c">
                  `).join('')}
              </div>
          ` : `
              <img style="height: 500px;" src="${introImages}" class="img-fluid" alt="">
          `}
            </div>
        </div>
    </div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
    </body>
    </html>
    `

        if (selectedHero === null) {
            var HeroTemplate = HeroTemplate1
        }
        if (selectedHero === 0) {
            var HeroTemplate = HeroTemplate2
        }
        if (selectedHero === 1) {
            var HeroTemplate = HeroTemplate3
        }
        if (selectedHero === 2) {
            var HeroTemplate = HeroTemplate4
        }
        if (selectedHero === 3) {
            var HeroTemplate = HeroTemplate5
        };
        if (selectedHero === 4) {
            var HeroTemplate = HeroTemplate6
        }
        //  -----------------------------------------------------trusttedby---------------------------------------//
        const Trustlogo = trustLogo.map((logo) => {
            return logo
        });
        const Newtrustlogo = newtrustlogo.map((logo) => {
            return logo
        })
        const Trustedtemplate1 = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Document</title>
         <style>
         nav {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: transparent;
          position: relative;
          padding: 0 !important;
          width:100%
          }
          .logo{
          display: flex;
          align-items: center;
          justify-content: start;
          }
          .logo a img{
          height: 40px;
          width: 40px;
          }
          
          nav .nav-item li {
          display: inline-block;
          list-style: none;
          margin: 5px;
          }
          
          nav .nav-item li a {
          display: inline-block;
          color: #000;
          font-size: 20px;
          text-decoration: none;
          }
          .navbar-nav .nav-item a:hover{
          text-decoration: underline;
          }
          .buttoncolor{
          margin: 2px;
          color: rgb(0, 0, 0);
          border: none;
          cursor: pointer;
          display: inline-flex;
          outline: none;
          padding: 0.7em 0.8em;
          position: relative;
          font-size: 14px;
          background:#f0f0f0;
          box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0);
          box-sizing: border-box;
          transition: all 0.2s ease-in-out;
          align-items: center;
          border-color: rgb(227, 227, 227);
          border-style: solid;
          border-width: 0;
          border-radius: 8px;
          justify-content: center;
          text-decoration: none;
          }
          nav .nav-item .nav-link button:hover{
          background-color: lightslategray;
          }
          
          /* =====================Subheader============== */
          .Subheader{
          background-color: #fafafa;
          height: 7px;
          background: var(--bg-header);
          box-shadow: 0 1px #eaeaea;
          left: 0;
          position: -webkit-sticky;
          position: sticky;
          right: 0;
          top: 0;
          z-index: 100;
          }
          
          /* =========== Welcome to cofee hub=========== */
          /* .buttoncolor{
          background-color:#f8f9fa;
          } */
          .menu{
          border-radius: 20px;
          border: 1px solid #f1f3f5;
          }
          .heading{
          font-size: 56px;
          font-weight: 600;
          color: #212529;
          }
          .heading2{
          font-size: 29px;
          font-weight: 600;
          color: #212529;
          }
          .card{
          background-color: #f8f9fa;
          background-image: linear-gradient(45deg, rgb(255, 255, 255) 0%, rgb(241, 242, 243) 100%);;
          border: 1px solid rgb(235, 235, 235);
          border-radius: 10px;
          margin: 16px;
          padding: 16px;
          box-shadow: 0px 19px 30px -10px rgba(21, 21, 25, 0.21);;
          }
          
          .footer-links  p{
          margin: 0;
          padding: 0;
          }
          .footer-links  p a{
          text-decoration: transparent;
          color: #212529;
          }
          .footer-links  p a:hover{
          text-decoration:underline;
          }
          
          
          .scrolling-text {
          white-space: nowrap;
          
          animation: scroll-left 60s linear infinite;
          }
          
          @keyframes scroll-left {
          0% { transform: translateX(100%); }
          100% { transform: translateX(-100%); }
          }.container {
          width: 80%; 
          margin: 0 auto; 
          overflow: hidden; 
          
          padding: 10px; 
          }
          .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          </style>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
    </head>
    <body>
    <div class="container" style="background-color: ${trustcolor};min-width: 100%;padding: 50px,padding-top:0px;position: relative,">
    <div style="margin-top: ${trustmargintop}px; margin-bottom: ${trustmarginbottom}px;">
        <p class="text-center" style="color: #9c36b5;">${trustCaption}</p>
        <h2 class="text-center heading" style="color: ${contenttextheadingcolor}; font-family: ${trusttextfont};">${trust}</h2>
        <p class="text-center" style="color: ${contenttextinfocolor}; font-family: ${trusttitletextfont};">
            ${trsuttitle}
        </p>
        <div class="scrolling-text" id="scrollingText">
            ${trustLogo.map((Logos, index) => `
                <img src="${Logos}" alt="logos">
            `).join('')}
            ${newtrustlogo.map((Logos, index) => `
                <img src="${Logos}" alt="logos" style="width: 100px; height: 40px;">
            `).join('')}
        </div>
    </div>
</div>

 
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
    </body>
     </html>`

        const Trustedtemplate2 = `
       <!DOCTYPE html>
       <html>
       <head>
        <style>
        .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          .scrolling-text {
            white-space: nowrap;
            animation: scroll-left 20s linear infinite;
          }
          const scrollingText = document.getElementById('scrollingText');
          scrollText(scrollingText, 0); 
          
          </style>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
       </head>
       <body>
       <div class="container" style="background-color: ${trustcolor};min-width: 100%; padding: 50px,padding-top:0px">
    <div style="margin-top: ${trustmargintop}px; margin-bottom: ${trustmarginbottom}px;">
        <p class="text-center" style="color: #9c36b5;">${trustCaption}</p>
        <h2 class="text-center heading" style="color: ${contenttextheadingcolor}; font-family: ${trusttextfont};">${trust}</h2>
        <p class="text-center" style="color: ${contenttextinfocolor}; font-family: ${trusttitletextfont};">
            ${trsuttitle}
        </p>
        <div class="scrolling-text" id="scrollingText">
            ${trustLogo.map((Logos, index) => `
                <img src="${Logos}" style="height: 24px; width: 150px;" alt="logos">
            `).join('')}
            ${newtrustlogo.map((Logos, index) => `
                <img src="${Logos}" alt="logos" style="height: 24px; width: 150px;">
            `).join('')}
        </div>
    </div>
</div>

   
       </body>
       <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
       </html>`

        const Trustedtemplate3 = `
        <!DOCTYPE html>
        <html>
        <head>
         <style>
         .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
         </style>
         <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
        </head>
        <body>
        <div class="container" style="background-color: ${trustcolor}; min-width: 100%; padding: 50px;padding-top:0px;">
        <div style="margin-top: ${trustmargintop}px; margin-bottom: ${trustmarginbottom}px;">
            <p class="text-center" style="color: #9c36b5;">${trustCaption}</p>
            <h2 class="text-center heading" style="color: ${contenttextheadingcolor}; font-family: ${trusttextfont};">${trust}</h2>
            <p class="text-center" style="color: ${contenttextinfocolor}; font-family: ${trusttitletextfont};">
                ${trsuttitle}
            </p>
            <div class="text-center" id="scrollingText">
                ${trustLogo.map((Logos, index) => `
                    <img src="${Logos}" style="height: 24px; width: 150px;" alt="logos">
                `).join('')}
                ${newtrustlogo.map((Logos, index) => `
                    <img src="${Logos}" alt="logos" style="height: 24px; width: 150px;">
                `).join('')}
            </div>
        </div>
    </div>
    
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
        </body>
        </html>`

        const Trustedtemplate4 = `
        <!DOCTYPE htm>
        <html>
        <head>
          <style>
          .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          .scrolling-text {
            white-space: nowrap;
            animation: scroll-left 20s linear infinite;
          }
          @keyframes scroll-left {
            0% {
              transform: translateX(100%);
            }
          
            100% {
              transform: translateX(-100%);
            }
          }
          </style>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
        </head>
        <body>
        <div class="container" style="background-color: ${trustcolor}; min-width: 100%;padding: 50px;padding-top:0px;">
    <div style="margin-top: ${trustmargintop}px; margin-bottom: ${trustmarginbottom}px;">
        <p class="text-center" style="color: #9c36b5;">${trustCaption}</p>
        <h2 class="text-center heading" style="color: ${contenttextheadingcolor}; font-family: ${trusttextfont};">${trust}</h2>
        <p class="text-center" style="color: ${contenttextinfocolor}; font-family: ${trusttitletextfont};">
            ${trsuttitle}
        </p>
        <div class="scrolling-text" id="scrollingText">
            ${trustLogo.map((Logos, index) => `
                <img src="${Logos}" style="height: 24px; width: 150px;" alt="logos">
            `).join('')}
            ${newtrustlogo.map((Logos, index) => `
                <img src="${Logos}" alt="logos" style="height: 24px; width: 150px;">
            `).join('')}
        </div>
    </div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
        </body>
        </html>`

        if (selectedLogo === null) {
            var TrustTemplate = Trustedtemplate1
        };
        if (selectedLogo === 0) {
            var TrustTemplate = Trustedtemplate2
        };

        if (selectedLogo === 1) {
            var TrustTemplate = Trustedtemplate3
        };

        if (selectedLogo === 2) {
            var TrustTemplate = Trustedtemplate4
        }

        // -------------------------------service----------------------------------------------//

        const ServiceTemplate1 = `
    <!DOCTYPE html>
    <html>
    <head>
    <style>
    nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: transparent;
      position: relative;
      padding: 0 !important;
      width:100%
      }
      .logo{
      display: flex;
      align-items: center;
      justify-content: start;
      }
      .logo a img{
      height: 40px;
      width: 40px;
      }
      
      nav .nav-item li {
      display: inline-block;
      list-style: none;
      margin: 5px;
      }
      
      nav .nav-item li a {
      display: inline-block;
      color: #000;
      font-size: 20px;
      text-decoration: none;
      }
      .navbar-nav .nav-item a:hover{
      text-decoration: underline;
      }
      .buttoncolor{
      margin: 2px;
      color: rgb(0, 0, 0);
      border: none;
      cursor: pointer;
      display: inline-flex;
      outline: none;
      padding: 0.7em 0.8em;
      position: relative;
      font-size: 14px;
      background:#f0f0f0;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0);
      box-sizing: border-box;
      transition: all 0.2s ease-in-out;
      align-items: center;
      border-color: rgb(227, 227, 227);
      border-style: solid;
      border-width: 0;
      border-radius: 8px;
      justify-content: center;
      text-decoration: none;
      }
      nav .nav-item .nav-link button:hover{
      background-color: lightslategray;
      }
      
      /* =====================Subheader============== */
      .Subheader{
      background-color: #fafafa;
      height: 7px;
      background: var(--bg-header);
      box-shadow: 0 1px #eaeaea;
      left: 0;
      position: -webkit-sticky;
      position: sticky;
      right: 0;
      top: 0;
      z-index: 100;
      }
      
      /* =========== Welcome to cofee hub=========== */
      /* .buttoncolor{
      background-color:#f8f9fa;
      } */
      .menu{
      border-radius: 20px;
      border: 1px solid #f1f3f5;
      }
      .heading{
      font-size: 56px;
      font-weight: 600;
      color: #212529;
      }
      .heading2{
      font-size: 29px;
      font-weight: 600;
      color: #212529;
      }
      .card{
      background-color: #f8f9fa;
      background-image: linear-gradient(45deg, rgb(255, 255, 255) 0%, rgb(241, 242, 243) 100%);;
      border: 1px solid rgb(235, 235, 235);
      border-radius: 10px;
      margin: 16px;
      padding: 16px;
      box-shadow: 0px 19px 30px -10px rgba(21, 21, 25, 0.21);;
      }
      
      .footer-links  p{
      margin: 0;
      padding: 0;
      }
      .footer-links  p a{
      text-decoration: transparent;
      color: #212529;
      }
      .footer-links  p a:hover{
      text-decoration:underline;
      }
      
      
      .scrolling-text {
      white-space: nowrap;
      
      animation: scroll-left 60s linear infinite;
      }
      
      @keyframes scroll-left {
      0% { transform: translateX(100%); }
      100% { transform: translateX(-100%); }
      }
      .container {
      width: 80%; 
      margin: 0 auto; 
      overflow: hidden; 
      
      padding: 10px; 
      }
      </style>
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
    </head>
    <body>
     <div class="container shadow" style='background-color:${featurecolor};min-width: 100%;position:relative;margin:-2px;padding: 50px;padding-top:0px;'>
    <div class="row" style="margin-bottom: ${serviceMarginBottom}px; margin-top: ${serviceMarginTop}px;">
    <div class="col-md-6 d-flex flex-column justify-content-start">
        <p class='mt-5' style="color: ${captioncolor};">${servicecaption}</p>
        <h1 class="heading" style="font-size: 33px; color: ${contenttextheadingcolor}; font-family: ${servicetitletextfont};">${serviceheader}</h1>
        <p class="fs-6 lh-sm" style="color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${servicesIntro1}</p>

        <div class="d-flex">
            <div style="display: ${serviceremove1 ? 'none' : 'flex'};">
                <div class="flex-shrink-0">
                    
                   <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.86 121.64"  width="15px"><title>direction-right</title><path d="M121.62,59,2.78.2A1.92,1.92,0,0,0,.2,1.08a1.89,1.89,0,0,0,0,1.76h0l30.87,58L.23,118.8h0a1.89,1.89,0,0,0,0,1.76,1.92,1.92,0,0,0,2.58.88l118.84-58.8a2,2,0,0,0,0-3.64Z"/></svg>
                </div>
                <div class="flex-grow-1 ms-3">
                    <h6 class="m-0 p-0 fs-6" style="color: ${contenttextheadingcolor}; font-family: ${serviceBodytextfont};">${serviceHeading1}</h6>
                    <span style="margin-top: 2px; font-size: 13px; color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${serviceinfo1}</span>
                </div>
            </div>
        </div>
        <div class="d-flex">
            <div style="display: ${serviceremove2 ? 'none' : 'flex'};">
                <div class="flex-shrink-0">
                    
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.86 121.64"  width="15px"><title>direction-right</title><path d="M121.62,59,2.78.2A1.92,1.92,0,0,0,.2,1.08a1.89,1.89,0,0,0,0,1.76h0l30.87,58L.23,118.8h0a1.89,1.89,0,0,0,0,1.76,1.92,1.92,0,0,0,2.58.88l118.84-58.8a2,2,0,0,0,0-3.64Z"/></svg>
                </div>
                <div class="flex-grow-1 ms-3">
                    <h6 class="m-0 p-0 fs-6" style="color: ${contenttextheadingcolor}; font-family: ${serviceBodytextfont};">${serviceHeading2}</h6>
                    <span style="margin-top: 2px; font-size: 13px; color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${serviceinfo2}</span>
                </div>
            </div>
        </div>
        <div class="d-flex">

            <div style="display: ${serviceremove3 ? 'none' : 'flex'};">
                <div class="flex-shrink-0">
                    
                    <IconPickerItem value=${featureIcon1}></IconPickerItem>
                    
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.86 121.64"  width="15px"><title>direction-right</title><path d="M121.62,59,2.78.2A1.92,1.92,0,0,0,.2,1.08a1.89,1.89,0,0,0,0,1.76h0l30.87,58L.23,118.8h0a1.89,1.89,0,0,0,0,1.76,1.92,1.92,0,0,0,2.58.88l118.84-58.8a2,2,0,0,0,0-3.64Z"/></svg>
                </div>
                <div class="flex-grow-1 ms-3">
                    <h6 class="m-0 p-0 fs-6" style="color: ${contenttextheadingcolor}; font-family: ${serviceBodytextfont};">${serviceHeading3}</h6>
                    <span style="margin-top: 2px; font-size: 13px; color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${serviceinfo3}</span>
                </div>
            </div>
        </div>
        <div class="d-flex">
            <div style="display: ${serviceremove4 ? 'none' : 'flex'};">
                <div class="flex-shrink-0">
                    
                    <IconPickerItem value=${featureIcon1}></IconPickerItem>
                    
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.86 121.64" width="15px"><title>direction-right</title><path d="M121.62,59,2.78.2A1.92,1.92,0,0,0,.2,1.08a1.89,1.89,0,0,0,0,1.76h0l30.87,58L.23,118.8h0a1.89,1.89,0,0,0,0,1.76,1.92,1.92,0,0,0,2.58.88l118.84-58.8a2,2,0,0,0,0-3.64Z"/></svg>
                </div>
                <div class="flex-grow-1 ms-3">
                    <h6 class="m-0 p-0 fs-6" style="color: ${contenttextheadingcolor}; font-family: ${serviceBodytextfont};">${serviceHeading4}</h6>
                    <span style="margin-top: 2px; font-size: 13px; color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${serviceinfo4}</span>
                </div>
            </div>
        </div>
        
        ${newservicesheading.map((heading, index) => `
            <div class="d-flex" key="${index}">
                <div>
                    <div class="flex-shrink-0">
                        <img src="pointer" alt="...">
                         <img src=${ServiceIcon} alt='Icon' height:'20px'; width:'20px'></img>
                    </div>
                    <div class="flex-grow-1 ms-3">
                        <h6 class="m-0 p-0 fs-6" style="color: ${contenttextheadingcolor}; font-family: ${serviceBodytextfont};">${heading.heading}</h6>
                        <span style="margin-top: 2px; font-size: 13px; color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${heading.info}</span>
                    </div>
                </div>
            </div>
        `).join('')}
        
        <div style="width: 100%;">
            <a href="#"><button class="mt-5 buttoncolor">More info</button></a>
        </div>
    </div>
    <div class="col-md-6 p-6">
        <!-- Conditional rendering for the image -->
        ${newservicesImage.length > 0 ?
                `<img src="${newservicesImage}" class="img-fluid shadow rounded p-6" alt="" style="margin-top: 100px; height: 350px;" />` :
                `<img src="${featureimages}" class="img-fluid shadow rounded p-6" alt="" style="margin-top: 100px; height: 350px;" />`}
    </div>
</div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
    </body>
    </html>`

        const ServiceTemplate2 = `
      <!DOCTYPE html>
      <html>
      <head>
       <style>
       .container {
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
      
        padding: 10px;
      }
      ._c33e3ab2 {
        display: flex;
        flex-wrap: wrap;
      }
      ._5a4edc19 {
        display: flex;
        flex-grow: 1;
        flex-basis: 0;
        align-items: flex-start;
        flex-direction: column;
       
      }
      ._e519f50a {
        font-size: 0.9em;
      }
      .heading {
        font-size: 56px;
        font-weight: 600;
        color: #212529;
      }
      ._c8e71cbb {
        width: calc(50% - 4em);
        margin-left: 3em;
      }
      </style>
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
      </head>
      <body>
      <div class="container" style="background-color: ${featurecolor}; min-width: 100%; padding: 50px;position:relative;margin:-2px;padding-top:0px;">
      <div class="row" style="margin-bottom: ${serviceMarginBottom}px; margin-top: ${serviceMarginTop}px;">
          <div class="_c33e3ab2" style="flex-direction: ${imagePosition ? 'row-reverse' : 'row'};">
              <div class="_5a4edc19" style="justify-content: ${alignMedia === 'start' ? 'flex-start' : alignMedia === 'end' ? 'flex-end' : alignMedia === 'center' ? 'center' : 'flex-start'};">
                  <div class="_c6ab3272">
                      <header class="_e519f50a">
                          <p class="" style="color: ${captioncolor};">${servicecaption}</p>
                          <h1 class="heading" style="font-size: 33px; color: ${contenttextheadingcolor}; font-family: ${servicetitletextfont};">${serviceheader}</h1>
                          <p class="fs-6 lh-sm" style="color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${servicesIntro1}</p>
                      </header>
                      <button id="btn_gzt74ocmkq" class="_974be402 btn">
                          <span>Read More</span>
                      </button>
                  </div>
              </div>
              <div class="_c8e71cbb">
                  <!-- Conditional rendering for the image -->
                  ${newservicesImage.length > 0 ?
                `<img src="${newservicesImage}" class="img-fluid shadow rounded p-6" alt="" style="margin-top: 100px; height: 350px;" />` :
                `<img src="${featureimages}" class="img-fluid shadow rounded p-6" alt="" style="margin-top: 100px; height: 350px;" />`}
              </div>
          </div>
      </div>
  </div>
  <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
      </body>
      </html>`

        const ServiceTemplate3 = `
       <!DOCTYPE html>
       <html>
       <head>
         <style>
         .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          ._c33e3ab2 {
            display: flex;
            flex-wrap: wrap;
          }
          ._5a4edc19 {
            display: flex;
            flex-grow: 1;
            flex-basis: 0;
            align-items: flex-start;
            flex-direction: column;
           
          }
          ._e519f50a {
            font-size: 0.9em;
          }
          .heading {
            font-size: 56px;
            font-weight: 600;
            color: #212529;
          }
          ._c8e71cbb {
            width: calc(50% - 4em);
            margin-left: 3em;
          }
          </style>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
       </head>
       <body>
       <div class="container" style="background-color: ${featurecolor};min-width: 100%; padding: 50px;position:relative;margin:-2px;padding-top:0px;">
    <div class="row" style="margin-bottom: ${serviceMarginBottom}px; margin-top: ${serviceMarginTop}px; margin: 20px;">
        <div class="_c33e3ab2 " style="flex-direction: ${imagePosition ? 'row-reverse' : 'row'};">
            <div class="_5a4edc19" style="justify-content: ${alignMedia === 'start' ? 'flex-start' : alignMedia === 'end' ? 'flex-end' : alignMedia === 'center' ? 'center' : 'flex-start'};">
                <div class="_c6ab3272">
                    <header class="_e519f50a">
                        <p class="" style="color: ${captioncolor};">${servicecaption}</p>
                        <h1 class="heading" style="font-size: 33px; color: ${contenttextheadingcolor}; font-family: ${servicetitletextfont};">${serviceheader}</h1>
                        <p class="fs-6 lh-sm" style="color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${servicesIntro1}</p>
                    </header>
                    <button id="btn_gzt74ocmkq" class="_974be402 btn">
                        <span>Read More</span>
                    </button>
                </div>
            </div>
            <div class="_c8e71cbb">
                <!-- Conditional rendering for the image -->
                ${newservicesImage.length > 0 ?
                `<img src="${newservicesImage}" class="img-fluid shadow rounded p-6" alt="" style="margin-top: 100px; height: 350px;" />` :
                `<img src="${featureimages}" class="img-fluid shadow rounded p-6" alt="" style="margin-top: 100px; height: 350px;" />`}
            </div>
        </div>
    </div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
       </body>
       </html>`

        const ServiceTemplate4 = `
       <!DOCTYPE html>
       <html>
       <head>
        <style>
        .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          ._c33e3ab2 {
            display: flex;
            flex-wrap: wrap;
          }
          ._5a4edc19 {
            display: flex;
            flex-grow: 1;
            flex-basis: 0;
            align-items: flex-start;
            flex-direction: column;
           
          }
          ._e519f50a {
            font-size: 0.9em;
          }
          ._c8e71cbb {
            width: calc(50% - 4em);
            margin-left: 3em;
          }
        </style>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
       </head>
       <body>
       <div class="container" style="background-color: ${featurecolor};min-width: 100%; padding: 50px;position:relative;margin:-2px;padding-top:0px;">
    <div class="row" style="margin-bottom: ${serviceMarginBottom}px; margin-top: ${serviceMarginTop}px;">
        <div class="_c33e3ab2" style="flex-direction: ${imagePosition ? 'row-reverse' : 'row'};">
            <div class="_5a4edc19" style="justify-content: ${alignMedia === 'start' ? 'flex-start' : alignMedia === 'end' ? 'flex-end' : alignMedia === 'center' ? 'center' : 'flex-start'};">
                <div class="_c6ab3272">
                    <header class="_e519f50a">
                        <p class="" style="color: ${captioncolor};">${servicecaption}</p>
                        <h1 class="heading" style="font-size: 33px; color: ${contenttextheadingcolor}; font-family: ${servicetitletextfont};">${serviceheader}</h1>
                        <p class="fs-6 lh-sm" style="color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${servicesIntro1}</p>
                    </header>
                    <button id="btn_gzt74ocmkq" class="_974be402 btn">
                        <span>Read More</span>
                    </button>
                   
                    <div class="flex-grow-1 ms-3">
                        <h6 class="m-0 p-0 fs-6" style="color: ${contenttextheadingcolor}; font-family: ${serviceBodytextfont};">${serviceHeading1}</h6>
                        <span style="margin-top: 2px; font-size: 13px; color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${serviceinfo1}</span>
                    </div>
                    <div class="flex-grow-1 ms-3">
                        <h6 class="m-0 p-0 fs-6" style="color: ${contenttextheadingcolor}; font-family: ${serviceBodytextfont};">${serviceHeading2}</h6>
                        <span style="margin-top: 2px; font-size: 13px; color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${serviceinfo2}</span>
                    </div>
                    <div class="flex-grow-1 ms-3">
                        <h6 class="m-0 p-0 fs-6" style="color: ${contenttextheadingcolor}; font-family: ${serviceBodytextfont};">${serviceHeading3}</h6>
                        <span style="margin-top: 2px; font-size: 13px; color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${serviceinfo3}</span>
                    </div>
                    <div class="flex-grow-1 ms-3">
                        <h6 class="m-0 p-0 fs-6" style="color: ${contenttextheadingcolor}; font-family: ${serviceBodytextfont};">${serviceHeading4}</h6>
                        <span style="margin-top: 2px; font-size: 13px; color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${serviceinfo4}</span>
                    </div>
                </div>
            </div>
            <div class="_c8e71cbb">
               
                ${newservicesImage.length > 0 ?
                `<img src="${newservicesImage}" class="img-fluid shadow rounded p-6" alt="" style="margin-top: 100px; height: 350px;" />` :
                `<img src="${featureimages}" class="img-fluid shadow rounded p-6" alt="" style="margin-top: 100px; height: 350px;" />`}
            </div>
        </div>
    </div>
</div>

<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>  
       </body>
       </html>`
        const ServiceTemplate5 = `
       <!DOCTYPE html>
       <html>
       <head>
        <style>
        .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          ._c33e3ab2 {
            display: flex;
            flex-wrap: wrap;
          }
          ._5a4edc19 {
            display: flex;
            flex-grow: 1;
            flex-basis: 0;
            align-items: flex-start;
            flex-direction: column;
           
          }
          ._e519f50a {
            font-size: 0.9em;
          }
          ._c8e71cbb {
            width: calc(50% - 4em);
            margin-left: 3em;
          }
          </style>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
       </head>
       <body>
       <div class="container" style="background-color: ${featurecolor};min-width: 100%; padding: 50px;position:relative;margin:-2px;padding-top:0px;">
    <div class="row" style="margin-bottom: ${serviceMarginBottom}px; margin-top: ${serviceMarginTop}px;">
        <div class="_c33e3ab2" style="flex-direction: ${imagePosition ? 'row-reverse' : 'row'};">
            <div class="_5a4edc19" style="justify-content: ${alignMedia === 'start' ? 'flex-start' : alignMedia === 'end' ? 'flex-end' : alignMedia === 'center' ? 'center' : 'flex-start'};">
                <div class="_c6ab3272">
                    <header class="_e519f50a">
                        <p class="" style="color: ${captioncolor};">${servicecaption}</p>
                        <h1 class="heading" style="font-size: 33px; color: ${contenttextheadingcolor}; font-family: ${servicetitletextfont};">${serviceheader}</h1>
                        <p class="fs-6 lh-sm" style="color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${servicesIntro1}</p>
                    </header>
                    <button id="btn_gzt74ocmkq" class="_974be402 btn">
                        <span>Read More</span>
                    </button>
                   
                    <div class="flex-grow-1 ms-3">
                        <h6 class="m-0 p-0 fs-6" style="color: ${contenttextheadingcolor}; font-family: ${serviceBodytextfont};">${serviceHeading1}</h6>
                        <span style="margin-top: 2px; font-size: 13px; color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${serviceinfo1}</span>
                    </div>
                    <div class="flex-grow-1 ms-3">
                        <h6 class="m-0 p-0 fs-6" style="color: ${contenttextheadingcolor}; font-family: ${serviceBodytextfont};">${serviceHeading2}</h6>
                        <span style="margin-top: 2px; font-size: 13px; color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${serviceinfo2}</span>
                    </div>
                    <div class="flex-grow-1 ms-3">
                        <h6 class="m-0 p-0 fs-6" style="color: ${contenttextheadingcolor}; font-family: ${serviceBodytextfont};">${serviceHeading3}</h6>
                        <span style="margin-top: 2px; font-size: 13px; color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${serviceinfo3}</span>
                    </div>
                    <div class="flex-grow-1 ms-3">
                        <h6 class="m-0 p-0 fs-6" style="color: ${contenttextheadingcolor}; font-family: ${serviceBodytextfont};">${serviceHeading4}</h6>
                        <span style="margin-top: 2px; font-size: 13px; color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${serviceinfo4}</span>
                    </div>
                </div>
            </div>
            <div class="_c8e71cbb">
                ${newservicesImage.length > 0 ?
                `<img src="${newservicesImage}" class="img-fluid shadow rounded p-6" alt="" style="margin-top: 100px; height: 350px;" />` :
                `<img src="${featureimages}" class="img-fluid shadow rounded p-6" alt="" style="margin-top: 100px; height: 350px;" />`}
            </div>
        </div>
    </div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
   
       </body>
       </html>`

        if (selectedFeature === null) {
            var ServiceTemplate = ServiceTemplate1
        };
        if (selectedFeature === 0) {
            var ServiceTemplate = ServiceTemplate2
        };
        if (selectedFeature === 1) {
            var ServiceTemplate = ServiceTemplate3
        };
        if (selectedFeature === 2) {
            var ServiceTemplate = ServiceTemplate4
        };
        if (selectedFeature === 3) {
            var ServiceTemplate = ServiceTemplate5
        };

        //  ---------------------------------------Stats-------------------------------//
        var StatsTemplate1 = `
        <!DOCTYPE html>
        <html>
        <head>
        <style>
        nav {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: transparent;
          position: relative;
          padding: 0 !important;
          width:100%
          }
          .logo{
          display: flex;
          align-items: center;
          justify-content: start;
          }
          .logo a img{
          height: 40px;
          width: 40px;
          }
          
          nav .nav-item li {
          display: inline-block;
          list-style: none;
          margin: 5px;
          }
          
          nav .nav-item li a {
          display: inline-block;
          color: #000;
          font-size: 20px;
          text-decoration: none;
          }
          .navbar-nav .nav-item a:hover{
          text-decoration: underline;
          }
          .buttoncolor{
          margin: 2px;
          color: rgb(0, 0, 0);
          border: none;
          cursor: pointer;
          display: inline-flex;
          outline: none;
          padding: 0.7em 0.8em;
          position: relative;
          font-size: 14px;
          background:#f0f0f0;
          box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0);
          box-sizing: border-box;
          transition: all 0.2s ease-in-out;
          align-items: center;
          border-color: rgb(227, 227, 227);
          border-style: solid;
          border-width: 0;
          border-radius: 8px;
          justify-content: center;
          text-decoration: none;
          }
          nav .nav-item .nav-link button:hover{
          background-color: lightslategray;
          }
          
          /* =====================Subheader============== */
          .Subheader{
          background-color: #fafafa;
          height: 7px;
          background: var(--bg-header);
          box-shadow: 0 1px #eaeaea;
          left: 0;
          position: -webkit-sticky;
          position: sticky;
          right: 0;
          top: 0;
          z-index: 100;
          }
          
          /* =========== Welcome to cofee hub=========== */
          /* .buttoncolor{
          background-color:#f8f9fa;
          } */
          .menu{
          border-radius: 20px;
          border: 1px solid #f1f3f5;
          }
          .heading{
          font-size: 56px;
          font-weight: 600;
          color: #212529;
          }
          .heading2{
          font-size: 29px;
          font-weight: 600;
          color: #212529;
          }
          .card{
          
          border-radius: 10px;
          margin: 16px;
          padding: 16px;
          box-shadow: 0px 19px 30px -10px rgba(21, 21, 25, 0.21);;
          }
          
          .footer-links  p{
          margin: 0;
          padding: 0;
          }
          .footer-links  p a{
          text-decoration: transparent;
          color: #212529;
          }
          .footer-links  p a:hover{
          text-decoration:underline;
          }
          
          
          .scrolling-text {
          white-space: nowrap;
          
          animation: scroll-left 60s linear infinite;
          }
          
          @keyframes scroll-left {
          0% { transform: translateX(100%); }
          100% { transform: translateX(-100%); }
          }.container {
          width: 80%; 
          margin: 0 auto; 
          overflow: hidden; 
          
          padding: 10px; 
          }
           .Statscard {
  background-color: #f8f9fa;
  /* background-image: linear-gradient(
    45deg,
    rgb(255, 255, 255) 0%,
    rgb(241, 242, 243) 100%
  ); */
  border: 1px solid rgb(235, 235, 235);
  border-radius: 10px;
  margin: 16px;
  padding: 16px;
  box-shadow: 0px 19px 30px -10px rgba(21, 21, 25, 0.21);
}
        </style>
        </head>
        <body>
        <div class="container" style="background-color: ${legacycolor}; color: ${legacytext};min-width:100%;padding: 50px;padding-top:0px;">
        <div style="margin-top: ${statsMarginTop}px; margin-bottom: ${statsMarginBottom}px;">
            <p class="text-center" style="color: ${captioncolor};">${statscaption}</p>
            <h2 class="text-center heading" style="color: ${contenttextheadingcolor}; font-family: ${statstitletextfont};">${legacy}</h2>
            <p class="text-center" style="color: ${contenttextinfocolor}; font-family: ${statsbodytextfont};">
                ${statsintro}
            </p>
            <div class="cards row d-flex align-items-center justify-content-evenly" >
                <div class="Statscard col-md-4" style="width: 18rem; display: ${statsnumremove1 ? 'none' : ''}; background-color: ${statsbackgroundcolor}; border: ${statsborder}px solid ${statsbordercolor}; border-radius: ${statsborderradius}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21)` : '0px 19px 30px 10px rgba(21, 21, 25, 0.21)'};">
                    <div class="card-body d-flex align-items-center justify-content-center flex-column">
                        <h5 class="card-title" style="color: ${statevaluecolor ? statevaluecolor : contenttextheadingcolor}; font-family: ${statsbodytextfont};">${stateOne.prefix}${statsnumber2}${stateOne.suffix}</h5>
                        <p class="card-text" style="color: ${statslabelcolor ? statslabelcolor : contenttextinfocolor}; font-family: ${statsbodytextfont};">${statsnumber1}</p>
                    </div>
                </div>
            
                <div class="Statscard col-md-4" style="width: 18rem; display: ${statsnumremove2 ? 'none' : ''}; background-color: ${statsbackgroundcolor}; border: ${statsborder}px solid ${statsbordercolor}; border-radius: ${statsborderradius}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21)` : '0px 19px 30px 10px rgba(21, 21, 25, 0.21)'};">
                    <div class="card-body d-flex align-items-center justify-content-center flex-column">
                        <h5 class="card-title" style="color: ${statevaluecolor ? statevaluecolor : contenttextheadingcolor}; font-family: ${statsbodytextfont};">${stateOne.prefix}${statsnumber4}${stateOne.suffix}</h5>
                        <p class="card-text" style="color: ${statslabelcolor ? statslabelcolor : contenttextinfocolor}; font-family: ${statsbodytextfont};">${statsnumber3}</p>
                    </div>
                </div>
    
                <div class="Statscard col-md-4" style="width: 18rem; display: ${statsnumremove3 ? 'none' : ''}; background-color: ${statsbackgroundcolor}; border: ${statsborder}px solid ${statsbordercolor}; border-radius: ${statsborderradius}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21)` : '0px 19px 30px 10px rgba(21, 21, 25, 0.21)'};">
                    <div class="card-body d-flex align-items-center justify-content-center flex-column">
                        <h5 class="card-title" style="color: ${statevaluecolor ? statevaluecolor : contenttextheadingcolor}; font-family: ${statsbodytextfont};">${stateOne.prefix}${statsnumber6}${stateOne.suffix}</h5>
                        <p class="card-text" style="color: ${statslabelcolor ? statslabelcolor : contenttextinfocolor}; font-family: ${statsbodytextfont};">${statsnumber5}</p>
                    </div>
                </div>
                <!-- Repeat the same structure for the other cards -->
                ${newlegacy.map((item, index) => `
                <div class="Statscard col-md-4" style="width: 18rem; ; background-color: ${statsbackgroundcolor}; border: ${statsborder}px solid ${statsbordercolor}; border-radius: ${statsborderradius}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21)` : '0px 19px 30px 10px rgba(21, 21, 25, 0.21)'};">
                    <div class="card-body d-flex align-items-center justify-content-center flex-column">
                        <h5 class="card-title" style="color: ${statevaluecolor ? statevaluecolor : contenttextheadingcolor}; font-family: ${statsbodytextfont};">${item.prefix}${item.value}${item.suffix}</h5>
                        <p class="card-text" style="color: ${statslabelcolor ? statslabelcolor : contenttextinfocolor}; font-family: ${statsbodytextfont};">${item.label}</p>
                    </div>
                </div>
                `).join('')}
            </div>
        </div>
    </div>
    
        </body>
        </html>`

        var StatsTemplate2 = `
         <!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Legacy Stats</title>
<style>
.container {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  
    padding: 10px;
  } 
  .sidecard {
    color: #343a40;
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0px 19px 30px -10px rgba(21, 21, 25, 0.21);
    box-sizing: border-box;
    background: linear-gradient(45deg, rgb(255, 255, 255) 0%, rgb(241, 242, 243) 100%);
    border: 1px solid rgb(235, 235, 235) ;
    border-radius: 10px;
  }
  .sidecard-child {
    display: flex;
    flex-grow: 1;
    flex-basis: 0px;
    text-align: center;
    align-items: center;
    flex-direction: column;
    padding: 1em;
    border-right: 1px solid rgb(235, 235, 235);
  }
  .countparent{
    color:  #212529;
    font-size: 1.8em;
    font-weight: 600;
  }
  .countspantext {
    font-size: 16px;
  }
</style>
<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
</head>
<body>
<div class="container"   style=' backgroundColor: ${legacycolor}, color: ${legacytext},min-width: 100%; padding: 50px;padding-top:0px;'>
    <div style="margin-top: ${statsMarginTop}px; margin-bottom: ${statsMarginBottom}px;">
        <p class="text-center" style="color: #9c36b5;">${statscaption}</p>
        <h2 class="text-center heading">${legacy}</h2>
        <p class="text-center">${statsintro}</p>
        <div class="sidecard">
            <div class="sidecard-child" style="display: ${statsnumremove1 ? 'none' : ''};  background-color: ${statsbackgroundcolor}, border: ${statsborder}px solid ${statsbordercolor}, border-radius: ${statsborderradius}px, box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px -10px rgba(21, 21, 25, 0.21)'};">
                <div class="countparent">
                    <span data-value="10000" class="countup">${stateOne.prefix}${statsnumber2}${stateOne.suffix}</span>
                </div>
                <span class="countspantext">${statsnumber1}</span>
            </div>
            <div class="sidecard-child" style="display: ${statsnumremove2 ? 'none' : ''};">
                <div class="countparent">
                    <span data-value="50" class="countup">${statetwo.prefix}${statsnumber4}${statetwo.suffix}</span>
                </div>
                <span class="countspantext">${statsnumber3}</span>
            </div>
            <div class="sidecard-child" style="display: ${statsnumremove3 ? 'none' : ''};">
                <div class="countparent">
                    <span data-value="15" class="countup">${statethree.prefix}${statsnumber6}${statethree.suffix}</span>
                </div>
                <span class="countspantext">${statsnumber5}</span>
            </div>

             ${newlegacy.map((item, index) => `
                <div class="sidecard-child" >
                <div class="countparent">
                    <span data-value="15" class="countup">${item.prefix}${item.value}${item.suffix}</span>
                </div>
                <span class="countspantext">${item.label}</span>
            </div>

                `).join('')}
        </div>
    </div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
</body>
</html>
`
        var StatsTemplate3 = `
        <!DOCTYPE html>
        <html>
        <head>
         <style>
         .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          .sidecard-child {
            display: flex;
            flex-grow: 1;
            flex-basis: 0px;
            text-align: center;
            align-items: center;
            flex-direction: column;
            padding: 1em;
            border-right: 1px solid rgb(235, 235, 235);
          }
          .countparent{
            color:  #212529;
            font-size: 1.8em;
            font-weight: 600;
          }
          .countspantext {
            font-size: 16px;
          }
          
         </style>
        </head>
        <body>
        <div class="container" style="background-color: ${legacycolor}; color: ${legacytext}; min-width: 100%; padding: 50px;padding-top:0px;">
    <div style="margin-top: ${statsMarginTop}px; margin-bottom: ${statsMarginBottom}px;">
        <p class="text-center" style="color: ${captioncolor};">${statscaption}</p>
        <h2 class="text-center heading" style="color: ${legacytext}; font-family: ${statstitletextfont};">${legacy}</h2>
        <p class="text-center" style="color: ${legacytext}; font-family: ${statsbodytextfont};">${statsintro}</p>
        <div class="d-flex flex-wrap">
            <div class="sidecard-child" style="width: 18rem; display: ${statsnumremove1 ? 'none' : ''}; background-color: ${statsbackgroundcolor}; border: ${statsborder}px solid ${statsbordercolor}; border-radius: ${statsborderradius}px; box-shadow: ${statsshowdowrange ? '0px 0px' : '0px 19px 30px -10px'} rgba(21, 21, 25, 0.21); border-right: none;">
                <div class="countparent">
                    <span data-value="10000" class="countup" style="color: ${statevaluecolor}; font-family: ${statsbodytextfont};">${stateOne.prefix}${statsnumber2}${stateOne.suffix}</span>
                </div>
                <span class="countspantext" style="color: ${statslabelcolor}; font-family: ${statsbodytextfont};">${statsnumber1}</span>
            </div>
            <div class="sidecard-child" style="width: 18rem; display: ${statsnumremove2 ? 'none' : ''}; background-color: ${statsbackgroundcolor}; border: ${statsborder}px solid ${statsbordercolor}; border-radius: ${statsborderradius}px; box-shadow: ${statsshowdowrange ? '0px 0px' : '0px 19px 30px -10px'} rgba(21, 21, 25, 0.21); border-right: none;">
                <div class="countparent">
                    <span data-value="50" class="countup" style="color: ${statevaluecolor}; font-family: ${statsbodytextfont};">${statetwo.prefix}${statsnumber4}${statetwo.suffix}</span>
                </div>
                <span class="countspantext" style="color: ${statslabelcolor}; font-family: ${statsbodytextfont};">${statsnumber3}</span>
            </div>
            <div class="sidecard-child" style="width: 18rem; display: ${statsnumremove3 ? 'none' : ''}; background-color: ${statsbackgroundcolor}; border: ${statsborder}px solid ${statsbordercolor}; border-radius: ${statsborderradius}px; box-shadow: ${statsshowdowrange ? '0px 0px' : '0px 19px 30px -10px'} rgba(21, 21, 25, 0.21); border-right: none;">
                <div class="countparent">
                    <span data-value="15" class="countup" style="color: ${statevaluecolor}; font-family: ${statsbodytextfont};">${statethree.prefix}${statsnumber6}${statethree.suffix}</span>
                </div>
                <span class="countspantext" style="color: ${statslabelcolor}; font-family: ${statsbodytextfont};">${statsnumber5}</span>
            </div>
            ${newlegacy.map((item, index) => `
              <div class="sidecard-child" style="width: 18rem;  background-color: ${statsbackgroundcolor}; border: ${statsborder}px solid ${statsbordercolor}; border-radius: ${statsborderradius}px; box-shadow: ${statsshowdowrange ? '0px 0px' : '0px 19px 30px -10px'} rgba(21, 21, 25, 0.21); border-right: none;">
                <div class="countparent">
                    <span data-value="15" class="countup" style="color: ${statevaluecolor}; font-family: ${statsbodytextfont};">${item.prefix}${item.value}${item.suffix}</span>
                </div>
                <span class="countspantext" style="color: ${statslabelcolor}; font-family: ${statsbodytextfont};">${item.label}</span>
            </div>

              `).join('')}
        </div>
    </div>
</div>
    
        </body>
        </html>`
        var StatsTemplate4 = `
        <!DOCTYPE html>
        <html>
        <head>
         <style>
         .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          .separatedcards {
            color: #343a40;
            margin: 1em;
            display: flex;
            padding: 1em;
            flex-grow: 1;
            box-shadow: 0px 19px 30px -10px rgba(21, 21, 25, 0.21);
            box-sizing: border-box;
            flex-basis: 0;
            text-align: center;
            align-items: center;
            border-color: rgb(235, 235, 235);
            border-style: solid;
            border-width: 1px;
            border-radius: 10px;
            flex-direction: column;
          }
          .countspantext {
            font-size: 16px;
          }
          .countparent{
            color:  #212529;
            font-size: 1.8em;
            font-weight: 600;
          }
          </style>
        </head>
        <body>
        <div class="container" style="background-color: ${legacycolor}; color: ${legacytext}; min-width: 100%;padding: 50px;padding-top:0px;">
    <div style="margin-top: ${statsMarginTop}; margin-bottom: ${statsMarginBottom};">
        <p class="text-center" style="color: ${captioncolor};">${statscaption}</p>
        <h2 class="text-center heading" style="color: ${legacytext}; font-family: ${statstitletextfont};">${legacy}</h2>
        <p class="text-center" style="color: ${legacytext}; font-family: ${statsbodytextfont};">${statsintro}</p>
        <div class="d-flex flex-wrap" style="margin: 0 -16px;">
            <div class="separatedcards" style="width: 18rem; display: ${statsnumremove1 ? 'none' : ''}; background-color: ${statsbackgroundcolor}; border: ${statsborder} solid ${statsbordercolor}; border-radius: ${statsborderradius}; box-shadow: ${statsshowdowrange};">
                <div class="countparent">
                    <span data-value="10000" class="countup" style="color: ${statevaluecolor}; font-family: ${statsbodytextfont};">${stateOne.prefix}${statsnumber2}${stateOne.suffix}</span>
                </div>
                <span class="countspantext" style="color: ${statslabelcolor}; font-family: ${statsbodytextfont};">${statsnumber1}</span>
            </div>
            <div class="separatedcards" style="width: 18rem; display: ${statsnumremove2 ? 'none' : ''}; background-color: ${statsbackgroundcolor}; border: ${statsborder} solid ${statsbordercolor}; border-radius: ${statsborderradius}; box-shadow: ${statsshowdowrange};">
                <div class="countparent">
                    <span data-value="50" class="countup" style="color: ${statevaluecolor}; font-family: ${statsbodytextfont};">${statetwo.prefix}${statsnumber4}${statetwo.suffix}</span>
                </div>
                <span class="countspantext" style="color: ${statslabelcolor}; font-family: ${statsbodytextfont};">${statsnumber3}</span>
            </div>
            <div class="separatedcards" style="width: 18rem; display: ${statsnumremove3 ? 'none' : ''}; background-color: ${statsbackgroundcolor}; border: ${statsborder} solid ${statsbordercolor}; border-radius: ${statsborderradius}; box-shadow: ${statsshowdowrange};">
                <div class="countparent">
                    <span data-value="15" class="countup" style="color: ${statevaluecolor}; font-family: ${statsbodytextfont};">${statethree.prefix}${statsnumber6}${statethree.suffix}</span>
                </div>
                <span class="countspantext" style="color: ${statslabelcolor}; font-family: ${statsbodytextfont};">${statsnumber5}</span>
            </div>
            ${newlegacy.map((item, index) => `
               <div class="separatedcards" style="width: 18rem; display: ${statsnumremove3 ? 'none' : ''}; background-color: ${statsbackgroundcolor}; border: ${statsborder} solid ${statsbordercolor}; border-radius: ${statsborderradius}; box-shadow: ${statsshowdowrange};">
                <div class="countparent">
                    <span data-value="15" class="countup" style="color: ${statevaluecolor}; font-family: ${statsbodytextfont};">${item.prefix}${item.value}${item.suffix}</span>
                </div>
                <span class="countspantext" style="color: ${statslabelcolor}; font-family: ${statsbodytextfont};">${item.label}</span>
            </div>

              `).join('')}
            
        </div>
    </div>
</div>
    
        </body>
        </html>`


        if (selectedStats === null) {
            var StatsTemplate = StatsTemplate1
        };
        if (selectedStats === 0) {
            var StatsTemplate = StatsTemplate2
        };
        if (selectedStats === 1) {
            var StatsTemplate = StatsTemplate3
        };
        if (selectedStats === 2) {
            var StatsTemplate = StatsTemplate4
        };

        //  --------------------------------------------Faq----------------------------------------------------------------------//

        const toggleAccordion4 = () => {
            setIsOpen1(false);
            setIsOpen2(false);
            setIsOpen3(false);
            setIsopen(false);
            setIsopen4(!isOpen4);
        }
        const toggleAccordionNew = () => {
            setIsopen(!isOpen);
            setIsOpen1(false);
            setIsOpen2(false);
            setIsOpen3(false);
            setIsopen4(false);
        }
        const toggleAccordion1 = () => {
            setIsOpen1(!isOpen1);
            setIsOpen2(false);
            setIsOpen3(false);
            setIsopen(false);
            setIsopen4(false)
        };

        const toggleAccordion2 = () => {
            setIsOpen1(false);
            setIsOpen2(!isOpen2);
            setIsOpen3(false);
            setIsopen(false);
            setIsopen4(false)
        };

        const toggleAccordion3 = () => {
            setIsOpen1(false);
            setIsOpen2(false);
            setIsOpen3(!isOpen3);
            setIsopen(false);
            setIsopen4(false)
        };

        const FaqTemplate1 = `
         <!DOCTYPE html>
         <html>
         <head>
           <style>
           .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
               .accordion-summary {
            padding: 1rem 0;
            font-size: 16px;
            display:flex;
            justify-content: space-between;
            align-items: center;
          }

          </style>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
           <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/material-design-icons/3.0.1/iconfont/material-icons.min.css" />
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/mui/0.9.43/css/mui.min.css" />
         </head>
         <body>
         <div class="container" style="background-color: ${faqcolor}; min-width: 100%; padding: 50px;padding-top:0px;" ref={Section6}>
    <div style="margin-top: ${faqMargintop}px; margin-bottom: ${faqMarginBottom}px;">
        <p style="color: ${captioncolor};">${faqCaption}</p>
        <h1 class="heading fs-4" style="font-family: ${faqtitletextfont}; color: ${contenttextheadingcolor};">${faqtitle}</h1>
        <p style="font-size: 17px; font-family: ${faqbodytextfont}; color: ${contenttextinfocolor};">
            ${faqIntro}
        </p>
             <div id="accordion11" style="display:block;">
                           <div class="mui-panel">
                                 <div class="accordion-summary" style="background-color: #yourFaqColor; font-family: yourFaqBodyTextFont;">
                
                                    <span id="faqQ11" style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer'  onclick="toggleAccordion('accordion11-details')">${faqQ1}</span>
                                        <i class="material-icons" onclick="toggleAccordion('accordion11-details')" style='cursor:pointer'>expand_more</i>
                          </div>
                                 <div id="accordion11-details" class="mui-panel" style="display: none; background-color: #yourFaqColor; font-family: yourFaqBodyTextFont; color: #yourContentTextHeadingColor;">
                                     <span id="faqA11"  style=' background-color: ${faqcolor}, font-fmily: ${faqbodytextfont}, color:${contenttextheadingcolor}'>${faqA1}</span>
                                  </div>
                                </div>
                     </div>

                       <div id="accordion22" style="display:block;">
                             <div class="mui-panel">
                              <div class="accordion-summary" style="background-color: #yourFaqColor; font-family: yourFaqBodyTextFont;">
                                       <span id="faqQ22"  style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer' onclick="toggleAccordion('accordion22-details')">${faqQ2}</span>
                                     <i class="material-icons" onclick="toggleAccordion('accordion22-details')"  style='cursor:pointer'>expand_more</i>

                                         </div>
                              <div id="accordion22-details" class="mui-panel" style="display: none; background-color: #yourFaqColor; font-family: yourFaqBodyTextFont; color: #yourContentTextHeadingColor;">
                             <span id="faqA22" style=' background-color: ${faqcolor}, font-fmily: ${faqbodytextfont}, color:${contenttextheadingcolor}'>${faqA2}</span>
                            </div>
                              </div>

                               <div id="accordion33" style="display:block;">
                             <div class="mui-panel">
                              <div class="accordion-summary" style="background-color: #yourFaqColor; font-family: yourFaqBodyTextFont;">
                                       <span id="faqQ33"  style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer' onclick="toggleAccordion('accordion33-details')">${faqQ3}</span>
                                     <i class="material-icons" onclick="toggleAccordion('accordion33-details')" style='cursor:pointer'>expand_more</i>

                                         </div>
                              <div id="accordion33-details" class="mui-panel" style="display: none; background-color: #yourFaqColor; font-family: yourFaqBodyTextFont; color: #yourContentTextHeadingColor;">
                             <span id="faqA33" style=' background-color: ${faqcolor}, font-fmily: ${faqbodytextfont}, color:${contenttextheadingcolor}'>${faqA3}</span>
                            </div>
                              </div>

                              <div id="accordion44" style="display:block;">
                             <div class="mui-panel">
                              <div class="accordion-summary" style="background-color: #yourFaqColor; font-family: yourFaqBodyTextFont;">
                                       <span id="faqQ44"  style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer' onclick="toggleAccordion('accordion44-details')">${faqQ4}</span>
                                     <i class="material-icons" onclick="toggleAccordion('accordion44-details')" style='cursor:pointer' >expand_more</i>

                                         </div>
                              <div id="accordion44-details" class="mui-panel" style="display: none; background-color: #yourFaqColor; font-family: yourFaqBodyTextFont; color: #yourContentTextHeadingColor;">
                             <span id="faqA44" style=' background-color: ${faqcolor}, font-fmily: ${faqbodytextfont}, color:${contenttextheadingcolor}'>${faqA4}</span>
                            </div>
                              </div>
     </div>
        </div>
    </div>
                      

                     
                      

    <script>
        function toggleAccordion(detailsId) {
            const details = document.getElementById(detailsId);
            details.style.display = details.style.display === 'none' ? 'block' : 'none';
        }
    </script>
            <!-- Other accordion items omitted for brevity -->
            ${newFaq.map((item, index) => (`
              <div id="accordion${index}" style="display:block;">
                           <div class="mui-panel">
                            <div class="accordion-summary" style="background-color: #yourFaqColor; font-family: yourFaqBodyTextFont;">
                                     <span id="faqQ${index}"  style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer' onclick="toggleAccordion('accordion${index}-details')">${item.Question}</span>
                                   <i class="material-icons" onclick="toggleAccordion('accordion${index}-details')" style='cursor:pointer' >expand_more</i>

                                       </div>
                            <div id="accordion${index}-details" class="mui-panel" style="display: none; background-color: #yourFaqColor; font-family: yourFaqBodyTextFont; color: #yourContentTextHeadingColor;">
                           <span id="faqA${index}" style=' background-color: ${faqcolor}, font-fmily: ${faqbodytextfont}, color:${contenttextheadingcolor}'>${item.Answer}</span>
                          </div>
                            </div>
          `)).join('')}
        </div>
    </div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
         </body>
         </html>`
        const FaqTemplate2 = `
          <!DOCTYPE html>
         <html>
         <head>
           <style>
           .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
               .accordion-summary {
            padding: 1rem 0;
            font-size: 16px;
            display:flex;
            justify-content: space-between;
            align-items: center;
          }

          </style>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
           <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/material-design-icons/3.0.1/iconfont/material-icons.min.css" />
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/mui/0.9.43/css/mui.min.css" />
         </head>
         <body>
         <div class="container" style="background-color: ${faqcolor}; min-width: 100%; padding: 50px;padding-top:0px;" ref={Section6}>
    <div style="margin-top: ${faqMargintop}px; margin-bottom: ${faqMarginBottom}px;">
        <p style="color: ${captioncolor};">${faqCaption}</p>
        <h1 class="heading fs-4" style="font-family: ${faqtitletextfont}; color: ${contenttextheadingcolor};">${faqtitle}</h1>
        <p style="font-size: 17px; font-family: ${faqbodytextfont}; color: ${contenttextinfocolor};">
            ${faqIntro}
        </p>
             <div id="accordion11" style="display:block;">
                           <div class="mui-panel">
                                 <div class="accordion-summary" style="background: linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%); font-family: yourFaqBodyTextFont;">
                
                                    <span id="faqQ11" style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer;color:  ${contenttextheadingcolor ? `white` : `${contenttextheadingcolor}`}'  onclick="toggleAccordion('accordion11-details')">${faqQ1}</span>
                                        <i class="material-icons" onclick="toggleAccordion('accordion11-details')" style='cursor:pointer'>expand_more</i>
                          </div>
                                 <div id="accordion11-details" class="mui-panel" style="display: none;  font-family: yourFaqBodyTextFont; background: linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%); font-family: yourFaqBodyTextFont;">
                                     <span id="faqA11"  style='  font-fmily: ${faqbodytextfont};  color: ${contenttextinfocolor ? `${contenttextinfocolor}` : 'white'}'>${faqA1}</span>
                                  </div>
                                </div>
                     </div>

                       <div id="accordion22" style="display:block;">
                             <div class="mui-panel">
                              <div class="accordion-summary" style="background: linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%); font-family: yourFaqBodyTextFont;">
                                       <span id="faqQ22"  style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer;color:  ${contenttextheadingcolor ? `white` : `${contenttextheadingcolor}`}' onclick="toggleAccordion('accordion22-details')">${faqQ2}</span>
                                     <i class="material-icons" onclick="toggleAccordion('accordion22-details')"  style='cursor:pointer'>expand_more</i>

                                         </div>
                              <div id="accordion22-details" class="mui-panel" style="display: none; background: linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%); font-family: yourFaqBodyTextFont; color: #yourContentTextHeadingColor;">
                             <span id="faqA22" style='  font-fmily: ${faqbodytextfont}; color: ${contenttextinfocolor ? `${contenttextinfocolor}` : 'white'}''>${faqA2}</span>
                            </div>
                              </div>

                               <div id="accordion33" style="display:block;">
                             <div class="mui-panel">
                              <div class="accordion-summary" style="background: linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%); font-family: yourFaqBodyTextFont;">
                                       <span id="faqQ33"  style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer;color:  ${contenttextheadingcolor ? `white` : `${contenttextheadingcolor}`}' onclick="toggleAccordion('accordion33-details')">${faqQ3}</span>
                                     <i class="material-icons" onclick="toggleAccordion('accordion33-details')" style='cursor:pointer'>expand_more</i>

                                         </div>
                              <div id="accordion33-details" class="mui-panel" style="display: none; background: linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%); font-family: yourFaqBodyTextFont; color: #yourContentTextHeadingColor;">
                             <span id="faqA33" style='font-fmily: ${faqbodytextfont}; color: ${contenttextinfocolor ? `${contenttextinfocolor}` : 'white'}''>${faqA3}</span>
                            </div>
                              </div>

                              <div id="accordion44" style="display:block;">
                             <div class="mui-panel">
                              <div class="accordion-summary" style="background: linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%); font-family: yourFaqBodyTextFont;">
                                       <span id="faqQ44"  style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer;color:  ${contenttextheadingcolor ? `white` : `${contenttextheadingcolor}`}' onclick="toggleAccordion('accordion44-details')">${faqQ4}</span>
                                     <i class="material-icons" onclick="toggleAccordion('accordion44-details')" style='cursor:pointer' >expand_more</i>

                                         </div>
                              <div id="accordion44-details" class="mui-panel" style="display: none; background: linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%); font-family: yourFaqBodyTextFont; color: #yourContentTextHeadingColor;">
                             <span id="faqA44" style=' font-fmily: ${faqbodytextfont}; color: ${contenttextinfocolor ? `${contenttextinfocolor}` : 'white'}'>${faqA4}</span>
                            </div>
                              </div>
     </div>
        </div>
    </div>
                      

                     
                      

    <script>
        function toggleAccordion(detailsId) {
            const details = document.getElementById(detailsId);
            details.style.display = details.style.display === 'none' ? 'block' : 'none';
        }
    </script>
            <!-- Other accordion items omitted for brevity -->
            ${newFaq.map((item, index) => (`
              <div id="accordion${index}" style="display:block;">
                           <div class="mui-panel">
                            <div class="accordion-summary" style="background:  linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%); font-family: yourFaqBodyTextFont;">
                                     <span id="faqQ${index}"  style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer;color:  ${contenttextheadingcolor ? `white` : `${contenttextheadingcolor}`}' onclick="toggleAccordion('accordion${index}-details')">${item.Question}</span>
                                   <i class="material-icons" onclick="toggleAccordion('accordion${index}-details')" style='cursor:pointer' >expand_more</i>

                                       </div>
                            <div id="accordion${index}-details" class="mui-panel" style="display: none; background: linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%); font-family: yourFaqBodyTextFont; color: #yourContentTextHeadingColor;">
                           <span id="faqA${index}" style=' font-fmily: ${faqbodytextfont};color: ${contenttextinfocolor ? `${contenttextinfocolor}` : 'white'}'>${item.Answer}</span>
                          </div>
                            </div>
          `)).join('')}
        </div>
    </div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
         </body>
         </html>`

        const FaqTemplate3 = `
         <!DOCTYPE html>
         <html>
         <head>
          <style>
          .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          ._c53b3b37 {
            color: #212529;
            margin: 0 0 0 0;
            font-size: 40px;
          }
          ._9a49c734 {
            padding: 0 0 30px 0;
          }
          ._d1646f8c {
            color: #212529;
            margin: 0;
            font-size: 1.05em;
            font-weight: 500;
          }
          </style>
         </head>
         <body>
         <div class="container" style="background-color: ${faqcolor}; min-width: 100%;padding: 50px; padding-top:0px;" >
         <section  style="margin-top: ${faqMargintop}px; margin-bottom: ${faqMarginBottom}px;">
    <div class="row">
        <div class="col-md-4">
            <p style="color: ${captioncolor};">${faqCaption}</p>
            <h1 class="_c53b3b37" style="font-family: ${faqtitletextfont}; color: ${contenttextheadingcolor};">${faqtitle}</h1>
            <p style="font-size: 17px; color: ${faqtext}; font-family: ${faqbodytextfont}; color: ${contenttextinfocolor};">
                ${faqIntro}
            </p>
        </div>
        <div class="col-md-8">
            ${removeFaq1 ? '' : `
            <div class="_9a49c734" >
                <h4 class="_d1646f8c" style="color: ${contenttextheadingcolor}; font-family: ${faqbodytextfont};">${faqQ1}</h4>
                <div class="_3840d56d" style="color: ${contenttextinfocolor}; font-family: ${faqbodytextfont};">${faqA1}</div>
            </div>` }
            ${removeFaq2 ? '' : `
            <div class="_9a49c734" >
                <h4 class="_d1646f8c" style="color: ${contenttextheadingcolor}; font-family: ${faqbodytextfont};">${faqQ2}</h4>
                <div class="_3840d56d" style="color: ${contenttextinfocolor}; font-family: ${faqbodytextfont};">${faqA2}</div>
            </div>` }
            ${removeFaq3 ? '' : `
            <div class="_9a49c734" >
                <h4 class="_d1646f8c" style="color: ${contenttextheadingcolor}; font-family: ${faqbodytextfont};">${faqQ3}</h4>
                <div class="_3840d56d" style="color: ${contenttextinfocolor}; font-family: ${faqbodytextfont};">${faqA3}</div>
            </div>` }
            ${removeFaq4 ? '' : `
            <div class="_9a49c734" >
                <h4 class="_d1646f8c" style="color: ${contenttextheadingcolor}; font-family: ${faqbodytextfont};">${faqQ4}</h4>
                <div class="_3840d56d" style="color: ${contenttextinfocolor}; font-family: ${faqbodytextfont};">${faqA4}</div>
            </div>` }
            ${newFaq.map((item, index) => (`
            <div class="_9a49c734" key=${index}>
                <h4 class="_d1646f8c" style="color: ${contenttextheadingcolor}; font-family: ${faqtitletextfont};">${item.Question}</h4>
                <div class="_3840d56d">${item.Answer}</div>
            </div>
            `)).join('')}
        </div>
    </div>
</section>
       </div>
         </body>
         </html>`
        const FaqTemplate4 = `
         <!DOCTYPE html>
        <html>
        <head>
          <style>
          .container {
           width: 100%;
           margin: 0 auto;
           overflow: hidden;
         
           padding: 10px;
         }
              .accordion-summary {
           padding: 1rem 0;
           font-size: 16px;
           display:flex;
           justify-content: space-between;
           align-items: center;
         }

         </style>
         <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
   <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/material-design-icons/3.0.1/iconfont/material-icons.min.css" />
   <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/mui/0.9.43/css/mui.min.css" />
        </head>
        <body>
        <div class="container" style="background-color: ${faqcolor}; min-width: 100%; padding: 50px;padding-top:0px;" ref={Section6}>
   <div style="margin-top: ${faqMargintop}px; margin-bottom: ${faqMarginBottom}px;">
       <p style="color: ${captioncolor};">${faqCaption}</p>
       <h1 class="heading fs-4" style="font-family: ${faqtitletextfont}; color: ${contenttextheadingcolor};">${faqtitle}</h1>
       <p style="font-size: 17px; font-family: ${faqbodytextfont}; color: ${contenttextinfocolor};">
           ${faqIntro}
       </p>
            <div id="accordion11" style="display:block;">
                          <div class="mui-panel">
                                <div class="accordion-summary" style=" font-family: yourFaqBodyTextFont;">
               
                                   <span id="faqQ11" style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer;color:  ${contenttextheadingcolor ? `${contenttextheadingcolor}` : `black`};font-family: ${faqbodytextfont}'  onclick="toggleAccordion('accordion11-details')">${faqQ1}</span>
                                       <i class="material-icons" onclick="toggleAccordion('accordion11-details')" style='cursor:pointer'>expand_more</i>
                         </div>
                                <div id="accordion11-details" class="mui-panel" style="display: none;   font-family: yourFaqBodyTextFont;">
                                    <span id="faqA11"  style='  font-fmily: ${faqbodytextfont};  color: ${contenttextinfocolor ? `${contenttextinfocolor}` : 'black'} font-family: ${faqbodytextfont}'>${faqA1}</span>
                                 </div>
                               </div>
                    </div>

                      <div id="accordion22" style="display:block;">
                            <div class="mui-panel">
                             <div class="accordion-summary" style=" font-family: yourFaqBodyTextFont;">
                                      <span id="faqQ22"  style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer;color:  ${contenttextheadingcolor ? `${contenttextheadingcolor}` : `black`};font-family: ${faqbodytextfont};' onclick="toggleAccordion('accordion22-details')">${faqQ2}</span>
                                    <i class="material-icons" onclick="toggleAccordion('accordion22-details')"  style='cursor:pointer'>expand_more</i>

                                        </div>
                             <div id="accordion22-details" class="mui-panel" style="display: none; font-family: yourFaqBodyTextFont; color: #yourContentTextHeadingColor;">
                            <span id="faqA22" style='  font-fmily: ${faqbodytextfont}; color: ${contenttextinfocolor ? `${contenttextinfocolor}` : 'black'};font-family: ${faqbodytextfont};'>${faqA2}</span>
                           </div>
                             </div>

                              <div id="accordion33" style="display:block;">
                            <div class="mui-panel">
                             <div class="accordion-summary" style="font-family: yourFaqBodyTextFont;">
                                      <span id="faqQ33"  style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer;color:  ${contenttextheadingcolor ? `${contenttextheadingcolor}` : `black`};font-family: ${faqbodytextfont};' onclick="toggleAccordion('accordion33-details')">${faqQ3}</span>
                                    <i class="material-icons" onclick="toggleAccordion('accordion33-details')" style='cursor:pointer'>expand_more</i>

                                        </div>
                             <div id="accordion33-details" class="mui-panel" style="display: none; font-family: yourFaqBodyTextFont; color: #yourContentTextHeadingColor;">
                            <span id="faqA33" style='font-fmily: ${faqbodytextfont}; color: ${contenttextinfocolor ? `${contenttextinfocolor}` : 'black'}; font-family: ${faqbodytextfont}'>${faqA3}</span>
                           </div>
                             </div>

                             <div id="accordion44" style="display:block;">
                            <div class="mui-panel">
                             <div class="accordion-summary" style=" font-family: yourFaqBodyTextFont;">
                                      <span id="faqQ44"  style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer;color:  ${contenttextheadingcolor ? `${contenttextheadingcolor}` : `black`}; font-family: ${faqbodytextfont};' onclick="toggleAccordion('accordion44-details')">${faqQ4}</span>
                                    <i class="material-icons" onclick="toggleAccordion('accordion44-details')" style='cursor:pointer' >expand_more</i>

                                        </div>
                             <div id="accordion44-details" class="mui-panel" style="display: none; font-family: yourFaqBodyTextFont; color: #yourContentTextHeadingColor;">
                            <span id="faqA44" style=' font-fmily: ${faqbodytextfont}; color: ${contenttextinfocolor ? `${contenttextinfocolor}` : 'black'};font-family: ${faqbodytextfont};'>${faqA4}</span>
                           </div>
                             </div>
    </div>
       </div>
   </div>
                     

                    
                     

   <script>
       function toggleAccordion(detailsId) {
           const details = document.getElementById(detailsId);
           details.style.display = details.style.display === 'none' ? 'block' : 'none';
       }
   </script>
           <!-- Other accordion items omitted for brevity -->
           ${newFaq.map((item, index) => (`
             <div id="accordion${index}" style="display:block;">
                          <div class="mui-panel">
                           <div class="accordion-summary" style="font-family: yourFaqBodyTextFont;">
                                    <span id="faqQ${index}"  style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer;color:  ${contenttextheadingcolor ? `${contenttextheadingcolor}` : `black`};font-family: ${faqbodytextfont};' onclick="toggleAccordion('accordion${index}-details')">${item.Question}</span>
                                  <i class="material-icons" onclick="toggleAccordion('accordion${index}-details')" style='cursor:pointer' >expand_more</i>

                                      </div>
                           <div id="accordion${index}-details" class="mui-panel" style="display: none;  font-family: yourFaqBodyTextFont; color: #yourContentTextHeadingColor;">
                          <span id="faqA${index}" style=' font-fmily: ${faqbodytextfont};color: ${contenttextinfocolor ? `${contenttextinfocolor}` : 'black'};font-family: ${faqbodytextfont};'>${item.Answer}</span>
                         </div>
                           </div>
         `)).join('')}
       </div>
   </div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
        </body>
        </html>`

        const FaqTemplate5 = `
         <!DOCTYPE html>
         <html>
         <head>
          <style>
          .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          ._c53b3b37 {
            color: #212529;
            margin: 0 0 0 0;
            font-size: 40px;
          }
          </style>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
         </head>
         <body>
          <div class="container" style="background-color: ${faqcolor}; padding: 50px; padding-top:0px;">
         <div  style="margin-top: ${faqMargintop}px; margin-bottom: ${faqMarginBottom}px;" ref="Section6">
    <header class="_e02e8965">
        <p style="color: ${captioncolor};">${faqCaption}</p>
        <h1 class="_c53b3b37" style="font-family: ${faqtitletextfont}; color: ${contenttextheadingcolor};">${faqtitle}</h1>
        <p style="font-size: 17px; color: ${faqtext}; font-family: ${faqbodytextfont}; color: ${contenttextinfocolor};">
            ${faqIntro}
        </p>
    </header>
    <div class="row">
        <div class="col-md-6 col-sm-12">
            <div class="_157660be">
                <h6 class="_790354ef" style="color: ${contenttextheadingcolor}; font-family: ${faqbodytextfont};">${faqQ1}</h6>
                <div class="_7d377080" style="color: ${contenttextinfocolor}; font-family: ${faqbodytextfont};">${faqA1}</div>
            </div>
            <div class="_157660be">
                <h6 class="_790354ef" style="color: ${contenttextheadingcolor}; font-family: ${faqbodytextfont};">${faqQ2}</h6>
                <div class="_7d377080" style="color: ${contenttextinfocolor}; font-family: ${faqbodytextfont};">${faqA2}</div>
            </div>
        </div>
        <div class="col-md-6 col-sm-12">
            <div class="_157660be">
                <h6 class="_790354ef" style="color: ${contenttextheadingcolor}; font-family: ${faqbodytextfont};">${faqQ3}</h6>
                <div class="_7d377080" style="color: ${contenttextheadingcolor}; font-family: ${faqbodytextfont};">${faqA3}</div>
            </div>
            <div class="_157660be">
                <h6 class="_790354ef" style="color: ${contenttextheadingcolor}; font-family: ${faqbodytextfont};">${faqQ4}</h6>
                <div class="_7d377080" style="color: ${contenttextinfocolor}; font-family: ${faqbodytextfont};">${faqA4}</div>
            </div>
        </div>
        ${newFaq.map((item) => (`
            <div className="col-md-6 col-sm-12">
            <div class="_157660be"><h6 class="_790354ef" style=' color: ${contenttextheadingcolor}, font-family: ${faqbodytextfont} '>${item.Question}</h6>
            <div class="_7d377080" style='color: ${contenttextheadingcolor}, font-family: ${faqbodytextfont}'>${item.Answer}</div></div>

            </div>
         `))}
    </div>
</div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
         </body>
         </html>`



        if (selectedFaq === null) {
            var FaqTemplate = FaqTemplate1
        };
        if (selectedFaq === 0) {
            var FaqTemplate = FaqTemplate2
        };
        if (selectedFaq === 1) {
            var FaqTemplate = FaqTemplate3
        };
        if (selectedFaq === 2) {
            var FaqTemplate = FaqTemplate4
        };
        if (selectedFaq === 3) {
            var FaqTemplate = FaqTemplate5
        };

        //  -------------------------------------------Footer-----------------------------------------------------------//
        const FooterFeature = footerfeature.map((item) => {
            return item
        });
        const FooterCompany = footercompany.map((item) => {
            return item
        });
        const Footerlegal = footerlegal.map((item) => {
            return item
        })
        const Footertemplate1 = `
         <!DOCTYPE html>
         <html>
         <head>
          <style>
          .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          </style>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
         </head>
         <body>
         <div class="container" style="background-color: ${footercolor}; min-width: 100%; padding: 50px;padding-top:0px;" >
         <div class="row" style="margin-top: ${footerMargintop}px; margin-bottom: ${footerMarginBottom}px;">
             <div class="col-sm-6 d-flex align-items-start justify-content-start flex-column">
                 <span style="color: ${footertext}; font-family: ${footerbodytextfont}; color: ${contenttextinfocolor};">${footeryear}</span>
                 <div class="media mt-2">
                     <a href="" style="display: ${removeSocialIcon1 ? 'none' : ''};"><i class="fa-brands fa-facebook" style="color: ${contenttextinfocolor};"></i></a>
                     <a href="" class="ms-2" style="display: ${removeSocialIcon2 ? 'none' : ''};"><i class="fa-brands fa-instagram" style="color: ${contenttextinfocolor};"></i></a>
                     <a href="" class="ms-2" style="display: ${removeSocialIcon3 ? 'none' : ''};"><i class="fa-brands fa-twitter" style="color: ${contenttextinfocolor};"></i></a>
                     <a href="" class="ms-2" style="display: ${removeSocialIcon4 ? 'none' : ''};"><i class="fa-brands fa-youtube" style="color: ${contenttextinfocolor};"></i></a>
                     ${newfootersocialIcon.map((item) => `
                         <a href="#" class="ms-2"><i class="fa-brands">#</i>${item}</a>
                     `).join('')}
                 </div>
             </div>
             <div class="col-sm-6">
                 <div class="row">
                     <div class="col-md-4 col-sm-5 footer-links">
                         <span style="display: ${footerfeature.length == 0 ? 'none' : ''}; font-family: ${footerbodytextfont}; color: ${contenttextinfocolor};">${footerfeaturehead}</span>
                              ${footerfeature.map((item, index) => `
                                            <li key=${index} style=" font-size: '13.5px'; color: ${contenttextinfocolor}; list-style: none; margin-top: '5px'; font-family: ${footerbodytextfont}; ">
                                                ${item}
                                            </li>

                                        `).join('')}
                    
                            
                             
                     </div>
                     <div class="col-md-4 col-sm-5 footer-links">
                         <span style="display: ${footercompany.length == 0 ? 'none' : ''}; font-family: ${footerbodytextfont}; color: ${contenttextinfocolor};">${footerCompanyhead}</span>
                         ${FooterCompany.map((item, index) => `
                            <li  key=${index} style="font-size: '13.5px'; color: ${contenttextinfocolor}; list-style: none; margin-top: '5px'; font-family: ${footerbodytextfont}; ">
                                ${item}
                            </li>

                        `).join('')}
                         
                         
                             
                              
                     </div>
                     <div class="col-md-4 col-sm-5 footer-links">
                         <span style="display: ${footerlegal.length == 0 ? 'none' : ''}; font-family: ${footerbodytextfont}; color: ${contenttextinfocolor};">${footerlegalhead}</span>
                         
                                     ${Footerlegal.map((item, index) => `
                            <li key=${index} style="font-size: '13.5px'; color: ${contenttextinfocolor}; list-style: none; margin-top: '5px'; font-family: ${footerbodytextfont};">
                                ${item}
                            </li>

                        `).join('')}
                             


                         
                     </div>
                 </div>
             </div>
         </div>
     </div>
     <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
         </body>
         </html>`
        const FooterTemplate2 = `
         <!DOCTYPE html>
         <html>
         <head>
          <style>
          .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
          </style>
         </head>
         <body>
         <div class="container" style="background-color: ${footercolor}; min-width: 100%; padding: 50px;padding-top:0px" >
         <div class="row" style="margin-top: ${footerMargintop}px; margin-bottom: ${footerMarginBottom}px;">
             <div class="col-sm-6 d-flex align-items-start justify-content-start flex-column">
                 <span style="color: ${footertext}; font-family: ${footerbodytextfont}; color: ${contenttextinfocolor};">${footeryear}</span>
                 <div class="media mt-2">
                     <a href="" style="display: ${removeSocialIcon1 ? 'none' : ''};"><i class="fa-brands fa-facebook"></i></a>
                     <a href="" class="ms-2" style="display: ${removeSocialIcon2 ? '' : 'none'};"><i class="fa-brands fa-instagram"></i></a>
                     <a href="" class="ms-2" style="display: ${removeSocialIcon3 ? '' : 'none'};"><i class="fa-brands fa-twitter"></i></a>
                     <a href="" class="ms-2" style="display: ${removeSocialIcon4 ? '' : 'none'};"><i class="fa-brands fa-youtube"></i></a>
                     ${newfootersocialIcon.map((item) => `
                         <a href="#" class="ms-2"><i class="fa-brands">#</i>${item}</a>
                     `).join('')}
                 </div>
             </div>
     
             <div class="col-sm-6">
                 <div class="row">
                     <div class="col-md-4 col-sm-5 footer-links">
                         <span style="display: ${footerfeature.length == 0 ? 'none' : ''}; font-family: ${footerbodytextfont}; color: ${contenttextinfocolor};">${footerfeaturehead}</span>
                         
                         ${footerfeature.map((item, index) => `
                            <li key=${index} style=" font-size: '13.5px'; color: ${contenttextinfocolor}; list-style: none; margin-top: '5px'; font-family: ${footerbodytextfont}; ">
                                ${item}
                            </li>

                        `).join('')}
                        
                     </div>
                     <div class="col-md-4 col-sm-5 footer-links">
                         <span style="display: ${footercompany.length == 0 ? 'none' : ''}; font-family: ${footerbodytextfont}; color: ${contenttextinfocolor};">${footerCompanyhead}</span>
                         
                         ${FooterCompany.map((item, index) => `
                            <li  key=${index} style="font-size: '13.5px'; color: ${contenttextinfocolor}; list-style: none; margin-top: '5px'; font-family: ${footerbodytextfont}; ">
                                ${item}
                            </li>

                        `).join('')}

                         
                     </div>
                     <div class="col-md-4 col-sm-5 footer-links">
                         <span style="display: ${footerlegal.length == 0 ? 'none' : ''}; font-family: ${footerbodytextfont}; color: ${contenttextinfocolor};">${footerlegalhead}</span>
                        
                               ${Footerlegal.map((item, index) => `
                            <li key=${index} style="font-size: '13.5px'; color: ${contenttextinfocolor}; list-style: none; margin-top: '5px'; font-family: ${footerbodytextfont};">
                                ${item}
                            </li>

                        `).join('')}
                             
                         
                     </div>
                 </div>
             </div>
         </div>
     </div>
     <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
         </body>
         <html>`

        if (selectedFooter === null) {
            var FooterTemplate = Footertemplate1
        };
        if (selectedFooter === 0) {
            var FooterTemplate = FooterTemplate2
        }
        //  -----------------------------------------------------Memecoin----------------------------------------------------------------------------------//
        const Navbar = memecoinNavbar.map((item) => {
            return item
        })




        console.log('Memecoin', memeCaption.slice(13, 32))
        const Caption1 = memeCaption.slice(0, 13);
        const Caption2 = memeCaption.slice(13, 32);

        const insertLineBreaks = (text, maxChars) => {
            const lines = [];
            for (let i = 0; i < text.length; i += maxChars) {
                lines.push(text.substring(i, i + maxChars));
            }
            return lines.join('\n');
        };

        const formattedText = insertLineBreaks(memetokencaption, 20);
        const Template2memecaption = insertLineBreaks(template2memecaption, 25);



        const MemeCoin = `
    <!DOCTYPE html>
    <html>
    <head>
    <title>${memecoinname}</title>
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">

     <style>
     @import url("https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@100;300;400;900&display=swap");

.body {
margin-right: 250px;
overflow-x: hidden;
min-height: 768px !important;
min-width: 100vw !important;
}
.menu-memecoin {
position: relative;
background-position: center;
text-align: center;
background-repeat: no-repeat;
background-position-y: top;
/* width: 100vw; */
height: 200px;
background-size: cover;
z-index: 9;
}
.menu-inner {
margin: auto;
overflow: visible;
position: relative;

display: flex;
align-items: center;
justify-content: center;
}
.menu-item {
display: inline-block;
vertical-align: middle;
margin-left: 10px;
margin-right: 10px;
padding: 15px 15px;
transition: all 300ms ease-out;
}
.menu-item a {
color: white;
font-weight: 900;
text-decoration: none;

cursor: pointer;
}
.logo {
filter: drop-shadow(4px 4px 0px rgba(1, 1, 1, 0.2));
}
#hero {
margin-top: -15px;
padding-top: 300px;
color: white;
/* width: 100vw; */
background-size: cover;
height: calc(100vh - 200px);
position: relative;
overflow: hidden;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}
@media screen and (min-width: 201px) and (max-width: 1920px) {
#hero,
{
min-height: calc(1080px - 200px);

overflow: hidden;
}
}
#hero::before {
content: "";

left: 0px;
right: 0px;
width: 100vw;
background-size: 100% 100%;
background-repeat: repeat-x;
height: 100%;
top: 0;
position: absolute;
bottom: 57%;
}

#hero .section-inner {
text-align: center;
}
.hero-animation {
position: absolute;
top: -4% !important;
max-width: 250px;
animation: herofly 20s linear infinite;
}

.boomer-cartoon {
z-index: 4;
}
@media screen and (min-width: 701px) and (max-width: 1920px) {
.hero-animation {
max-width: 250px !important;
}
}
@keyframes herofly {
0% {
transform: translateX(-30vw) scaleX(1);
}

50% {
transform: translateX(130vw) scaleX(1);
}
51% {
transform: translateX(130vw) scaleX(-1);
}
100% {
transform: translateX(-30vw) scaleX(-1);
}
}

#hero .header-container {

-webkit-mask-repeat: no-repeat;
mask-repeat: no-repeat;

background-repeat: no-repeat;
background-position: center;
background-size: 100% 100%;
display: inline-block;
margin-bottom: 40px;
padding: 90px;
text-align: center;
margin-top: -18vh;

position: relative;
}
#hero h2 {
font-size: 1.5em;
margin: 0px;
}
h1 {
display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
}
.boomer-cig {
position: absolute;
max-width: 480px;
right: 8vw;
bottom: 1.2vh;
}
.boomer-chair {
position: absolute;
max-width: 450px;
left: 12vw;
bottom: 1.2vh;
}

.boomer-cartoon {
z-index: 4;
}
@media screen and (min-width: 701px) and (max-width: 1920px) {
.boomer-cig {
max-width: 400px !important;
right: 2vw;
}
}

@media screen and (min-width: 701px) and (max-width: 1920px) {
.boomer-chair {
max-width: 380px !important;
left: 2vw !important;
}
}

.arrow-memecoin {
position: absolute;
bottom: 3vh;
width: 70px;
height: 50px;
background-repeat: no-repeat;
background-size: contain;
left: 50%;
transform: translateX(-50%);
z-index: 1;
}
.arrow-memecoin img {
animation: swivel 1s ease infinite;
}
@keyframes swivel {
0% {
transform: translateY(20px);
}

50% {
transform: translateY(0px);
}
100% {
transform: translateY(20px);
}
}
.button-container {
margin: auto;
z-index: 800;
position: relative;
padding-bottom: 10%;
}
.button {
transition: all 300ms ease-out;
}
.button:hover {
transform: scale(1.05) !important;
}

.button-primary {
background: #023cba;
color: white;
}
.button {
padding: 15px 15px;
margin-left: 10px;
margin-right: 10px;

min-width: 175px;
display: inline-block;
font-weight: 800 !important;
}
a {
text-decoration: none;
}
a.button.button-secondary {
text-align: center;
}

.button-secondary {
background: white;
color: #023cba;
}



#vision {
background-size: 350px;

position: relative;
}
#vision {
/* background: #0452ff; */
}
#vision::before,
#howtobuy::before {
content: "";
display: block;
width: 100%;
top: 0px;

height: 100%;
position: absolute;
z-index: 0;
}
#vision .section-inner {
z-index: 1;

text-align: center;
}
.header-container {
text-align: center;
padding-top: 3vh;
}
.section.blue h1,
.section.blue p {
color: white ;
}
#vision p {
max-width: 550px;
margin: auto;
margin-top: 40px;
font-family: "Londrina Solid";
font-size: 24px;
}

}
@media screen and (min-width: 701px) and (max-width: 1920px) {
#vision {
padding-bottom: 250px !important;
}
}
@media screen and (min-width: 701px) and (max-width: 1920px) {
#hero,
 #vision
 {
min-height: calc(1080px - 400px);
}
}

@media screen and (min-width: 701px) and (max-width: 1200px) {
.section:not(#hero):not(#vision) {
min-height: auto !important;
}
}

.section:not(#hero) {
position: relative;
}

.section.white h1,
.section.white p {
color: #023cba ;
}
.section.white h1 {
letter-spacing: 1.1px;
font-family: "Londrina Solid";
}

.heading-3 {
font-size: 56px !important;
color: #023cba;
letter-spacing: 1.1px;
font-family: "Londrina Solid";
position: relative;
top:3rem;
}

.get-started-with {
position: relative;
font-size: 16px;
letter-spacing: 0.14px;
line-height: 26px;
font-weight: 500;
text-align: center;
}

.heading-3-legendary-parent {
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
gap: 29px;
}



.div1 {
position: relative;
letter-spacing: 0.14px;
line-height: 16px;
font-weight: 600;
color: #fff;
}

.wrapper {
position: absolute;
top: 61px;
left: 0px;
border-radius: 999px;
background-color: #000000;
width: 64px;
height: 64px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 10px;
box-sizing: border-box;
overflow: hidden;
}

.frame-item {
position: absolute;
top: 0px;
left: 32px;
max-height: 100%;
width: 100%;
}

.group-parent {
width: 64px;
position: relative;
height: 125px;
z-index: 1;
}

.install-a-chrome {
width: 206px;
position: relative;
font-size: 16px;
line-height: 26px;
display: flex;
color: #fff;
align-items: center;
justify-content: center;
text-align: center;
}

.step-1-parent-memecoin {
width: 226px;
border-radius: 16px;

height: 250px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
padding: 44px 20px;
box-sizing: border-box;
gap: 28px;
z-index: 0;
margin-top: -28px;
font-size: 20px;
overflow: hidden;
}

.div {
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
position: relative;
top:-5rem
}

.mint-mlon-eusk {
margin: 0;
}

.mint-mlon-eusk-container1 {
width: 100%;
}

.mint-mlon-eusk-container {
width: 206px;
position: relative;
font-size: 16px;
line-height: 26px;
display: flex;
color: #631f1f;
align-items: center;
text-align: center;
}

.optional {
font-size: 14px;
}

.step-5-optional-container {
position: relative;
letter-spacing: 0.14px;
line-height: 26px;
}

.step-5-optional-parent {
width: 226px;
height: 220px;
border-radius: 16px;
background: linear-gradient(180deg, #023cba, #516faf);
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
padding: 44px 20px;
box-sizing: border-box;
gap: 28px;
z-index: 0;
margin-top: -28px;
font-size: 20px;
}

.parent {
display: flex;
flex-direction: row;
justify-content: center;
gap: 56px;
text-align: center;
font-size: 36px;
flex-wrap: wrap;
}

.frame-parent {
position: relative;
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-end;
gap: 87px;
text-align: left;
font-size: 48px;
color: #fff;
font-family: Montserrat;
}

@media (max-width: 528px) {
.heading-3 {
font-size: 24px;
line-height: 32px;
}

.step-1-parent,
.step-5-optional-parent {
padding: 20px;
font-size: 16px;
}

.install-a-chrome {
width: auto;
font-size: 14px;
}

.parent {
flex-direction: column;
gap: 20px;
}

.frame-child,
.frame-item {
position: relative;
left: 0;
}

.wrapper {
top: 0;
}
}

.white-logosvg-icon {
position: absolute;
top: 45px;
left:20px;
width: 180px;
height: 40px;
overflow: hidden;
}
.meme-was-made {
position: absolute;
top: 100px;
left: 20%;
line-height: 110%;
display: inline-block;
width: 65%;
}
.link-boomer-on-base-social-m {
position: absolute;
top: 48px;
right:320px;
width: 50px;
height: 50px;
}
.link-boomer-on-base-social-m1 {
position: absolute;
top: 48px;
right:260px;
width: 50px;
height: 50px;
}
.link-boomer-on-base-social-m2 {
position: absolute;
top: 48px;
right:198px;
width: 50px;
height: 50px;
}
.link-boomer-on-base-social-m3 {
position: absolute;
top: 48px;
right:138px;
width: 50px;
height: 50px;
}
.link-boomer-on-base-social-m4 {
position: absolute;
top: 48px;
right:78px;
width: 50px;
height: 50px;
}
.link-boomer-on-base-social-m5 {
position: absolute;
top: 48px;
right:18px;
width: 50px;
height: 50px;
}
.footer {
/* width: 100%; */
width: 100vw;
position: relative;

background-color: #023cba;
height: 300px;
/* text-align: center; */
font-size: 28px;
color: #fff;
font-family: "Londrina Solid";
justify-content: center;
align-items: center;
}

.frame-container {
padding: 5vw;
/* width: 100vw; */
/* background: url(../images/tokonmicsbg.svg); */
background-repeat: no-repeat;
width: 100%;
height: 100%;

color: white !important;
display: flex;
justify-content: center;
margin: auto;
margin-top: 40px !important;
align-items: center;
gap: 80px;
}
.token-grid {
display: grid;
grid-template-columns: 1fr 1fr;
grid-gap: 20px;
text-align: center;
}
.token-grid-item{

text-align: center;
font-weight: 500;
font-size: 24px;
}
.material-symbols-outlined{
font-size: 28px;
display: block;
text-align: center;
margin: auto;
}
.token-grid span,
.token-grid .textarea {
font-weight: 300;
margin-top: 15px;
display: inline-block;
width: 246px;
}



.textarea {
resize: none;

color: white;
font-size: 22px;
border-radius: 15px;
border: none;
max-width: 250px;
word-wrap: break-word;
}
.boomer-coin-text {
Max-width: 24vw;
margin: auto;
text-align: center;
padding: 80px 80px;
padding-bottom: 30px ! Important;
color: white ;
line-height: 1.3em;
margin-left:-50px;
}
.animate-number{
font-size: 24px;
font-weight: 600 !important;
}

.container {
width: 100%;
margin: 0 auto;
overflow: hidden;
padding: 10px;
}

  #google_translate_element {
    width: 300px;
    float: right;
    text-align: right;
    display: block;
}
.goog-te-banner-frame.skiptranslate {
    display: none !important;
}
body {
    top: 0px !important;
}
#goog-gt-tt {
    display: none !important;
    top: 0px !important;
}
.goog-tooltip skiptranslate {
    display: none !important;
    top: 0px !important;
}
.activity-root {
    display: hide !important;
}
.status-message {
    display: hide !important;
}
.started-activity-container {
    display: hide !important;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
    display: none;
}

.VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q {
    display: none;
    background-color: unset !important;
    box-shadow: none !important;
}
    .cartonImage{
   
     height: 400px;
    width:400px ;
    
    background-size:cover;
    background-repeat:no-repeat;
    }
    @media (max-width: 728px) {
  .cartonImage {
    width: 250px;
    
    height: 250px;
    object-fit: cover; 
    background-size: cover;
    background-repeat: no-repeat;
  }
}
     </style>
    </head>
    <body style="overflow-x:hidden;position: relative;width:10px">
         <div style="position: relative;width:100vw;">
        
              <nav id="navbar" class="menu-memecoin"  style=' background-image:  ${memebackground == 0 ? `${memenavbgcolor.length > 0 ? `url(${memenavbgcolor})` : `url(${Images1})`}` : ''};background-color: ${memebackground == 1 ? `${memenavbarbgcolor}` : ''};height:auto;width:100vw;object-fit:cover;background-size:cover;'>
                  <div class="menu-inner">
                      <div class="menu-item" >
                          <a aria-label="boomer-link" href="#" style=' font-family: ${memecoinanvfont}; color: ${memenavtextcolor} ;font-size:${memenavfontsize}px;'> ${Navbar[0] !== undefined ? Navbar[0] : ''}</a>
                      </div>
                      <div class="menu-item">
                          <a aria-label="boomer-link" href="#" style=' font-family: ${memecoinanvfont}; color: ${memenavtextcolor}; font-size:${memenavfontsize}px;'>${Navbar[1] !== undefined ? Navbar[1] : ''}</a>
                      </div>
                      <div class="menu-item logo">
                          <picture>
                              <img src=${memecoinlogoImage.length > 0 ? memecoinlogoImage : Images2} style=' width: 360px; height: 200px ' alt="boomer"  />
                          </picture>
                      </div>
                      <div class="menu-item">
                          <a aria-label="boomer-link" href="#" style=' font-family: ${memecoinanvfont}; color: ${memenavtextcolor};font-size:${memenavfontsize}px; '>${Navbar[2] !== undefined ? Navbar[2] : ''}</a>
                      </div>
                      <div class="menu-item">
                          <a aria-label="boomer-link" href="#" style=' font-family: ${memecoinanvfont}; color: ${memenavtextcolor};font-size:${memenavfontsize}px; ' >${Navbar[3] !== undefined ? Navbar[3] : ''}</a>
                      </div>
                          <div style="position: absolute; right: 20px; top: 5px; cursor: pointer;color:white">
    <div onclick="toggleGoogleTranslate()">EN</div>

    <div
        style="background-color: transparent; 
               padding: 0px 8px; 
               margin-right: 10px; 
               border-radius: 8px; 
               display: ${googletransalte == false ? 'none' : 'block'};"
        id="google_translate_element">
    </div>
</div>

                  </div>
                     
              </nav>
          </div>

               <div class='' class="section" id="hero" style='background-image:${memeherobackground == 0 ? `${memecoinaboutbgImage.length > 0 ? `url(${memecoinaboutbgImage})` : `url(${Images3})`}` : ""};height: auto; width: 100vw; object-fit: cover; background-size: cover;background-color:${memeherobackground == 1 ? `${memeherobgcolor}` : ""} ;'>
              <div class="section-inner">
                  <div class="hero-animation">
                      <div class="animation-wrapper" style="position:relative;margin-top:70px;">
                          <img src=${memecoinAminitionImage.length > 0 ? memecoinAminitionImage : Images4} style='  height: 400px; width: 350px; margin-top: 30 ,object-fit:'cover';background-size:'cover' ' alt=''></img>
                      </div>
                  </div>
                  <div class="header-container" style='background-image:${memecloudimage.length > 0 ? `url(${memecloudimage})` : `url(${NewmaskImage})`}'>
                      <h2 style=' color: ${memecoinabouttextcolor};font-family: ${memecoinaboutfont} ;font-size:${memeMainfontsize}px;'>${Caption1}</h2>
                      <h2 style=' color: ${memecoinabouttextcolor};font-family: ${memecoinaboutfont} ;font-size:${memeMainfontsize}px;'>${Caption2}</h2>

                      <h1 style=' color: ${memecoinabouttextcolor};font-family: ${memecoinaboutfont} ;font-size:${memeMainfontsize}px;'>${memecoinValue}</h1>
                      <h2 style=' color: ${memecoinabouttextcolor};font-family: ${memecoinaboutfont} ; font-size:${memeMainfontsize}px;'>${memecoinname}</h2>
                  </div>
                  <div style='position:relative;width: 100%;display:flex;align-items:center;justify-content:space-around'>
                  <div class="boomer-cartoon">
                      <img src=${memecoinaboutleftimage.length > 0 ? memecoinaboutleftimage : Images5} style='width: 400px;height: 400px; background-size:cover' alt="" />

                  </div>
                  <div class="boomer-cartoon ">
                    <img src=${memecoinaboutrightimage.length > 0 ? memecoinaboutrightimage : Images6} style=' width: 400px;height: 400px;background-size:cover' ' alt="" />

                  </div>
                  </div>
                  <div class="button-container">
                      <a aria-label="boomer-link" href="" target="_blank" class="button button-primary" style=' color: ${memecoinabouttextcolor};font-family: ${memecoinaboutfont} ;font-size:${memeMainfontsize}px;' onclick="window.open('${template2cmc}')"><div>Buy $${memecoinsymbol}</div></a>
                      <a aria-label="boomer-link" href="" target="_blank" class="button button-secondary" style=' color: ${memecoinabouttextcolor};font-family: ${memecoinaboutfont} ;font-size:${memeMainfontsize}px;' onclick="window.open('${memetelegramlink}')"><div style='position:relative;display:flex;align-items:center;justify-content:center;gap:20px'>${memeherobutton} <img src=${Newtelegram} alt='T' width=20px; height=20px/></div></a>
                  </div>
              </div>
              <div class="arrow-memecoin">
              <a href="#vision"><img width=100% src=${arrow} alt="continue to next section for more information" /></a></div>
          </div>

           <div class="section blue " id="vision" style='margin-top: -0.5px ; background-color: ${memeaboutbackground == 0 ? `${memeaboutbgcolor.length == 0 ? '#0452ff' : `${memeaboutbgcolor}`}` : ''};background-image:${memeaboutbackground == 1 ? `${memeaboutbgimage.length !== 0 ? `url(${memeaboutbgimage})` : ''}` : ''};height:fit-content;width:100vw;object-fit:cover;background-size:cover;' tabindex="-1">
              <div class="section-inner">
                  <div class="header-container">
                      <h1 style='font-family:${memecoinvisionfont};color:${memecoinvisiontextcolor};font-size:${memeaboutfontSize}px;'>${memeabout}</h1>
                      <p style='font-family:${memecoinvisionfont};color:${memecoinvisiontextcolor};font-size:${memeaboutfontSize}px;'>${memecoinAbout}</p>

                  </div>
                 <div style='position:relative;width:100%;display:flex;align-items:center;justify-content:space-around'>
                  <div class="boomer-cartoon " style=' margin-bottom: 1rem; ' >
                     
                        
                         <img  class="cartonImage" src=${memecoinavisionleftimage.length > 0 ? memecoinavisionleftimage : Images8}  alt="" />  
                  </div>
                  <div class="boomer-cartoon" style=' margin-bottom: 1rem; '>
                            <img class="cartonImage" src=${memecoinavisionrightimage.length > 0 ? memecoinavisionrightimage : Images7} alt="" />
                          

                  </div>
                  </div>


              </div>
          </div>

              
          <div id='token' class="section white" style=' margin-bottom:4rem;top:-60px;width:100vw ' >
          <span style='position:absolute;align-items:center;left:39%;top:0.5rem ;'>
          <div class="header-container">
                      <h1 style=' font-family: ${memecointokenfont}; color: ${memecointoketextcolor};font-size:${memetokenfontSize}px ;'>${template1tokenomics}</h1>
                  </div>
          </span>

          
              <div class="section-inner">

                  
                  <div class="frame-container" style=' background-image:${memetokenomicsbackground == 0 ? `${memecointokonomicsbgimage.length > 0 ? `url(${memecointokonomicsbgimage})` : `url(${Images10})`}` : ''}; width: 100%; height: 100%;object-fit: cover;background-size: cover;background-color:${memetokenomicsbackground == 1 ? `${memetokenomicsbgcolor}` : ''};padding-top:200px'  >
                 <div class="token-grid">

                       ${newaddtokest == 1 ? '' : newaddtokest.map((item, index) => (
            `<div key=${index}>
                            <div class="flex-column-center">
                                <img src="${newiconsImage.length > 0 ? newiconsImage : Contract}" alt="" style="height: 95px; width: 80px;" />
                                <div style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};${memetokenfontSize}px">${item.title}</div>
                            </div>
                            <div class="textarea" aria-label="wallet address container" readonly style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};${memetokenfontSize}px">
                                ${item.value}
                            </div>
                        </div>`
        )).join('')}
                               
                          ${iSremove1 ? '' :
                `<div>
                            <div class="flex-column-center">
                                <img src=${memecoinContractImage.length > 0 ? memecoinContractImage : Contract} alt="" style="height: 95px; width: 80px;" />
                                <div style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};${memetokenfontSize}px;">${memecoincontractaddress.title}</div>
                            </div>
                            <div class="textarea" aria-label="wallet address container" readonly="" style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};background-color:transparent;${memetokenfontSize}px;">${memecoincontractaddress.value}</div>
                        </div>`
            }




                          ${iSremove2 ? '' :
                `<div>
                  <div class="flex-column-center">
                      <img src="${memecoinDatabaseImage.length > 0 ? memecoinDatabaseImage : Database}" alt="" style="height: 95px; width: 80px;" />
                      <div style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};${memetokenfontSize}px;">${memecoinInitialsupply.title}</div>
                  </div>
                  <span class="animate-number" style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};${memetokenfontSize}px;">${memecoinInitialsupply.value}</span>
                              </div>`
            }




                          ${iSremove3 ? '' :
                `
              <div>
                  <div class="flex-column-center">
                      <img src="${memecoinCirculatingImage.length > 0 ? memecoinCirculatingImage : Circulating}" alt="" style="height: 95px; width: 80px;" />
                      <div style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};${memetokenfontSize}px;">${memecoinCirculatingSupply.title}</div>
                  </div>
                  <span class="animate-number" style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};${memetokenfontSize}px;">${memecoinCirculatingSupply.value}</span>
              </div>
                             `
            }


                          ${iSremove4 ? '' :
                `
              <div>
                  <div class="flex-column-center">
                      <img src="${memecoinBurnedImage.length > 0 ? memecoinBurnedImage : Burned}" alt="" style="height: 95px; width: 80px;" />
                      <div style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};${memetokenfontSize}px;">${memecoinSupplyBurned.title}</div>
                  </div>
                  <span class="animate-number" style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};${memetokenfontSize}px;">${memecoinSupplyBurned.value}</span>
                  
              </div>
                              `
            }



                          ${iSremove5 ? '' :
                `
              <div>
                  <div class="flex-column-center">
                      <img src="${memecoinLiquidityImage.length > 0 ? memecoinLiquidityImage : Liquidity}" alt="" style="height: 95px; width: 80px;" />
                      <div style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};">${memecoinLiquidity.title}</div>
                  </div>
                  <span class="animate-number" style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};${memetokenfontSize}px;">${memecoinLiquidity.value}</span>
                  <span class="animate-number" style="margin: 0px; padding: 0px !important; color: ${memecointoketextcolor};${memetokenfontSize}px;"></span>
              </div>
                                `
            }



                          ${iSremove6 ? '' :
                `
              <div>
                  <div class="flex-column-center">
                      <img src="${memecoinRenouncedImage.length > 0 ? memecoinRenouncedImage : Renounced}" alt="" style="height: 95px; width: 80px;" />
                      <div style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};${memetokenfontSize}px;">${contractRenounced.title}</div>
                      <br/>
                      <div style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};${memetokenfontSize}px;">${contractRenounced.value}</div>

                  </div>
              </div>
                             `
            }
                     </div>


                      <div style=' text-aglin: center '>
                          <div class="animated-rotate" style=' margin: auto '>
                              <img width='50%'  src=${memecointokenomicsimage.length > 0 ? memecointokenomicsimage : Images9} alt="illustration of boomer coin" />
                              <p class="boomer-coin-text" style='font-family:${memecointokenfont};color:${memecointoketextcolor};${memetokenfontSize}px' >${memecoinname} ${formattedText}</p>
                              <a href="#howtobuy" class="button button-secondary" style='font-family:${memecointokenfont};color:${memecointoketextcolor == 'white' ? 'blue' : `${memecointoketextcolor}`};${memetokenfontSize}px' onclick="window.open('${template2cmc}')">${memetokenbuy} ${memecoinsymbol}</a>

                          </div>
                      </div>
                  </div>

              </div>

              <div class="divider"></div>
          </div>

          <div class="frame-parent" id="howtobuy" style='background-image: ${memestepbackground == 0 ? `url(${memestepbgimage})` : ''};height:100%;width:100vw;background-size:cover;object-fit:cover;background-color:${memestepbackground == 1 ? `${memestepbgcolor}` : ""};margin-bottom:0px;margin-top:-9.5rem;'>

              <h2 class="heading-3" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor}';font-size:${memestepfontSize}px ;' >HOW TO BUY</h2>


              <div class="parent">
                  <div class="div">
                      <div class="group-parent">
                          <div class="wrapper">
                              <div class="div1" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor};font-size:${memestepfontSize}px' ,overflow:hidden>${stepnumber1}</div>
                          </div>
                          <img class="frame-item" alt="" src="Line 2.svg" />
                      </div>
                      <div class="step-1-parent-memecoin" style='background:linear-gradient(130deg, ${statsbackgroundcolor != 'white' ? statsbackgroundcolor : '#023cba'}, #516faf); border: ${statsborder}px solid ${statsbordercolor}; border-radius: ${statsborderradius}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21 ` : ` 0px 19px 30px -10px rgba(21, 21, 25, 0.21)`}; '>
                          <b class="step-5-optional-container" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor};font-size:${memestepfontSize}px'>${stepheading1}</b>
                          <b class="install-a-chrome" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor};font-size:${memestepfontSize}px'>
                             ${step1}
                          </b>
                      </div>
                  </div>
                  <div class="div">
                      <div class="group-parent">
                          <div class="wrapper">
                              <div class="div1" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor};font-size:${memestepfontSize}px',overflow:hidden>${stepnumber2}</div>
                          </div>
                      </div>
                      <div class="step-1-parent-memecoin" style='background:linear-gradient(130deg, ${statsbackgroundcolor != 'white' ? statsbackgroundcolor : '#023cba'}, #516faf); border: ${statsborder}px solid ${statsbordercolor}; border-radius: ${statsborderradius}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21 ` : ` 0px 19px 30px -10px rgba(21, 21, 25, 0.21)`}; '>
                          <b class="step-5-optional-container" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor};font-size:${memestepfontSize}px'>${stepheading2}</b>
                          <b class="install-a-chrome" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor};font-size:${memestepfontSize}px'>${step2}</b>
                      </div>
                  </div>
                  <div class="div">
                      <div class="group-parent">
                          <div class="wrapper">
                              <div class="div1" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor};font-size:${memestepfontSize}px',overflow:hidden>${stepnumber3}</div>
                          </div>
                      </div>
                      <div class="step-1-parent-memecoin" style='background:linear-gradient(130deg, ${statsbackgroundcolor != 'white' ? statsbackgroundcolor : '#023cba'}, #516faf); border: ${statsborder}px solid ${statsbordercolor}; border-radius: ${statsborderradius}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21 ` : ` 0px 19px 30px -10px rgba(21, 21, 25, 0.21)`}; '>
                          <b class="step-5-optional-container" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor};font-size:${memestepfontSize}px'>${stepheading3}</b>
                          <b class="install-a-chrome" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor};font-size:${memestepfontSize}px'>
                              ${Step3test1} ${memecoinsymbol} ${Step3test2}
                          </b>
                      </div>
                  </div>
                  <div class="div">
                      <div class="group-parent">
                          <div class="wrapper">
                              <div class="div1" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor};font-size:${memestepfontSize}px',overflow:hidden>${stepnumber4}</div>
                          </div>
                      </div>
                      <div class="step-1-parent-memecoin" style='background:linear-gradient(130deg, ${statsbackgroundcolor != 'white' ? statsbackgroundcolor : '#023cba'}, #516faf); border: ${statsborder}px solid ${statsbordercolor}; border-radius: ${statsborderradius}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21 ` : ` 0px 19px 30px -10px rgba(21, 21, 25, 0.21)`}; '>
                          <b class="step-5-optional-container" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor};font-size:${memestepfontSize}px'>${stepheading4}</b>
                          <b class="mint-mlon-eusk-container">
                              <span class="mint-mlon-eusk-container1">
                                  <p class="install-a-chrome" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor};font-size:${memestepfontSize}px'>${step4} ${memecoinname}</p>
                                  <p class="install-a-chrome" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor};font-size:${memestepfontSize}px'>${memecoinsymbol}</p>
                              </span>
                          </b>
                      </div>
                  </div>

              </div>
          </div>

          <div class="footer"  style='background-image:${memefootrbackground == 1 ? `${memefooterbgimage.length !== 0 ? `url(${memefooterbgimage})` : ''}` : ''};object-fit:cover;background-size:cover;background-color: ${memefootrbackground == 0 ? `${memefooterbgcolor.length == 0 ? '#0452ff' : memefooterbgcolor}` : ''}; overflow: hidden;'>
              <div class="container">
                  <span class="white-logosvg-icon" style='color:${memefootertextcolor};font-size:${memefooterfontSize}px;font-family:${memefootertextfont};'>$${memecoinsymbol}</span>
                  <div class="meme-was-made"  style='color:${memefootertextcolor};font-family:${memefootertextfont};font-size:${memefooterfontSize}px'>$${memecoinsymbol}${memefooterabout}</div>
              </div>
              
              <img class="link-boomer-on-base-social-m" alt="" src=${twitterimage.length > 0 ? twitterimage : twitter} style="cursor: pointer;" onclick="window.open('${memetwitterlink}')" />
              <img class="link-boomer-on-base-social-m1" alt="" src=${telegraminage.length > 0 ? telegraminage : telegram} style="cursor: pointer;" onclick="window.open('${memetelegramlink}')"/>
              <img class="link-boomer-on-base-social-m2" alt="" src=${youtubeimage.length > 0 ? youtubeimage : youtube}   style="cursor: pointer;" onclick="window.open('${memeYoutubelink}')"/>
              <img class="link-boomer-on-base-social-m3" alt="" src=${tiktokimage.length > 0 ? tiktokimage : tiktok}   style="cursor: pointer;" onclick="window.open('${memetiktoklink}')"/>
              <img class="link-boomer-on-base-social-m4" alt="" src=${instagramimage.length > 0 ? instagramimage : instagram}   style="cursor: pointer;" onclick="window.open('${memeinstagramlink}')"/>
              <img class="link-boomer-on-base-social-m5" alt="" src=${warpcastimage.length > 0 ? warpcastimage : warpcast}   style="cursor: pointer;" onclick="window.open('${memewarpcastlink}')"/>
              
          </div>
      </div>
   
         <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
                          
        
          </body>
           <script>
    let googletransalte = ${googletransalte};

    function toggleGoogleTranslate() {
        googletransalte = !googletransalte;
        document.getElementById('google_translate_element').style.display = googletransalte ? 'block' : 'none';
    }
</script>
      <script type="text/javascript">
        function googleTranslateElementInit() {
            new google.translate.TranslateElement(
                { pageLanguage: 'en' },
                'google_translate_element'  
            );
        }
    </script>
    <script
        type="text/javascript"
        src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    ></script>


    </html>`



        const NewmemeTemplate1 = `
     <html>
     <head>
     <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
     <meta name="viewport" content="width=device-width, initial-scale=1.0">
     <meta http-equiv="X-UA-Compatible" content="ie=edge">
     <title>${memecoinname}</title>
     <style>
     
    .navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100px;

    color: white;
    width: 100%;
}
.navbar a {
    color: white;
    text-decoration: none;
    padding: 0 10px;
    font-size: 16px;
}
.navbar a:hover {
    text-decoration: underline;
}

.container {
   
    text-align: center;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.containerDiv1 {
   
    text-align: center;
   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    overflow: hidden;
}

.containerDiv2 {
   
    text-align: center;
   
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
}
.container h1 {
    font-size: 2.5rem;
}
.container p {
    font-size: 1.1rem;
    margin: 10px 0;
}

.telegrambutton {
    margin-top: 20px;
}
.telegrambutton button {
    background-color: #e4e4f1;
    border: none;
    padding: 12px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 8px;
    margin-right: 10px;
}
.telegrambutton button:hover {
    background-color: #ccc;
}

.heroimage {
    width: 100%;
    max-width: 400px;
    margin: 20px auto;
}

.sectionred {
    position: relative;
   
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
   
    flex-direction: row;
}

.sectionredDiv1 {
   
    width: 50%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    position: relative;
    padding: 20px;
    
}

.sectionredDiv2 {
    
    width: 50%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 30px;
}
.sectionred h1 {
    font-size: 2rem;
}
.sectionred p {
    font-size: 1rem;
    line-height: 1.5;
    max-width: 800px;
    margin: 0 auto;
}

.tokenomics {
    position: relative;
    width: 100%;
    height: 100%;
    /* background-color: #7b42cc; */
    text-align: center;
}
.tokenomics h2 {
    font-size: 2rem;
    margin-bottom: 10px;
}
.tokenomics p {
    font-size: 1.1rem;
}

.stats {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
    margin-top: 30px;
    width: 100%;
    align-items: center;
}
.stats div {
    width: 30%;
    text-align: center;
}
.stats img {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
}
.stats h3 {
    font-size: 1.2rem;
}
.stats p {
    font-size: 1rem;
}

.socials {

    text-align: center;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    
   
}

.socialsDiv1 {
    
    text-align: center;
    position: relative;
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 20px;
    padding: 20px;
    margin-top:30px;
}

.socialsDiv2 {
     display: flex;
     align-items: center;
     justify-content: center;
    height: 50%;
   
    position: relative;
    width: 100%;
    
   
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
   margin:35px;
}
.socials img {
    width: 60px;
    height: 60px;
    margin: 15px;
}
.contract {
    margin-top: 20px;
    background-color: #ffffff;
    color: #8e3134;
    padding: 10px;
    font-size: 1.1rem;
    border-radius: 5px;
    text-align: center;
   
   
    width: 50%;
    height: 100%;
}

.footer {
   position: relative;
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   
    text-align: center;
    font-size: 0.9rem;
    padding-top:10%;
}

.footerdiv1 {
   position: relative;
   width: 50%;
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
    text-align: center;
    font-size: 0.9rem;
    padding: 20px;
}
.footerdiv2 {
   position: relative;
   width: 100%;
   height: 100%;
   display: flex;
  
   align-items: center;
   justify-content: center;
    
    text-align: center;
    font-size: 0.9rem;
    padding: 45px;
}
.footer p {
    margin: 10px 0;
}

/* Responsive Styles */
@media only screen and (max-width: 768px) {
    .stats div {
        width: 45%;
    }
    .navbar a {
        font-size: 14px;
        padding: 0 5px;
    }
    .container h1 {
        font-size: 2rem;
    }
    .container p {
        font-size: 1rem;
    }
    .sectionred h1 {
        font-size: 1.8rem;
    }
    .sectionred p {
        font-size: 0.9rem;
    }
    .tokenomics h2 {
        font-size: 1.8rem;
    }
    .tokenomics p {
        font-size: 1rem;
    }
    .container {
   
        text-align: center;
        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding-top:15%;
        position: relative;
    }
    .containerDiv2{
        width: 100%;
    }
    .containerDiv1{
        width: 100%;
    }
    .sectionred{
        flex-direction: column;
    }
    
    
    .stats{
        flex-direction: column;
    }
    .socialsDiv1{
        flex-direction: column;
        padding-top:80px;
    }
    
    
    .footerdiv2{
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .title{
      font-size: 20px;
    }
    .stats div {
        width: 100%;
    }
    .navbar {
        display: flex;
        font-size: 5px;
        
    }
    .container h1 {
        font-size: 1.5rem;
    }
    .container p {
        font-size: 0.9rem;
    }
    .telegram-button button {
        padding: 10px 15px;
        font-size: 0.9rem;
    }
    .heroimage {
        max-width: 100%;
    }
    .sectionred h1 {
        font-size: 1.5rem;
    }
    .sectionred p {
        font-size: 0.9rem;
    }
    .socials img {
        width: 50px;
        height: 50px;
    }
        .footer {
   position: relative;
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;

    text-align: center;
    font-size: 0.9rem;
    padding-top:10%;
}
}

@media only screen and (max-width: 320px) {
   .navbar {
        display: flex;
        font-size: 5px;
        flex-direction: column;
        
    }
}
    @media only screen and (max-width:440px){
       .socialsDiv2 {
     display: flex;
     align-items: center;
     justify-content: center;
    height: 50%;
   
    position: relative;
    width: 100%;
    
   
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
   margin:35px;
   line-break:anywhere;
}
    }

    @media only screen and (max-width: 650px) {
     .navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100px;
     flex-direction: column;
    color: white;
    width: 100%;
}
    }

 #google_translate_element {
    width: 300px;
    float: right;
    text-align: right;
    display: block;
}
.goog-te-banner-frame.skiptranslate {
    display: none !important;
}
body {
    top: 0px !important;
}
#goog-gt-tt {
    display: none !important;
    top: 0px !important;
}
.goog-tooltip skiptranslate {
    display: none !important;
    top: 0px !important;
}
.activity-root {
    display: hide !important;
}
.status-message {
    display: hide !important;
}
.started-activity-container {
    display: hide !important;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
    display: none;
}

.VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q {
    display: none;
    background-color: unset !important;
    box-shadow: none !important;
}
   .socialsDiv3{
    background-color: #ffffff;
}

     </style>
     <body >
         <div style='position:relative;display:flex;flex-direction:column;overflow-x:hidden'>
         <div style="position: absolute; right: 0px; top: 5px; cursor: pointer;color:white;display:flex;flex-direction:column;align-items:center">
    <div onclick="toggleGoogleTranslate()">EN</div>

    <div
        style="background-color: transparent; 
               padding: 0px 8px; 
               margin-right: 10px; 
               border-radius: 8px; 
               display: ${googletransalte == false ? 'none' : 'block'};"
        id="google_translate_element">
    </div>
</div>

    <div id="Navbar" class="navbar" style="background-image: ${memebackground === 0 ? (memenavbgcolor.length > 0 ? `url(${memenavbgcolor})` : '') : ''}; object-fit: cover; background-size: cover; background-color: ${memebackground === 1 ? memenavbarbgcolor : (memenavbgcolor.length === 0 ? '#7b42cc' : '')};padding-top:100px;padding-bottom: 30px;">
     <a href="#" style="color: white; text-decoration: none; font-family: ${memecoinanvfont}; color: ${memenavtextcolor}; ">${Navbar[0]}</a>
    <a href="#" style="color: white; text-decoration: none; font-family: ${memecoinanvfont}; color: ${memenavtextcolor}; ">${Navbar[1]}</a>
    <h1 class="title" style="color:${memenavtextcolor}; font-family: ${memecoinanvfont}; ">${memecoinname}</h1>
    <a href="#" style="color: white; text-decoration: none; font-family: ${memecoinanvfont}; color: ${memenavtextcolor}; ">${Navbar[2]}</a>
    <a href="#" style="color: white; text-decoration: none; font-family: ${memecoinanvfont}; color: ${memenavtextcolor}; ">${Navbar[3]}</a>
  </div>

  <div id="Hero" class="container" style="background-image: ${memeherobackground == 0 ? (memecoinaboutbgImage.length > 0 ? `url(${memecoinaboutbgImage})` : '') : ''}; background-color: ${memeherobackground == 1 ? memeherobgcolor : (memecoinaboutbgImage.length > 0 ? '' : '#7b42cc')}; background-size: cover; background-repeat: no-repeat;">
    <div class="containerDiv1">
        <h1 style="color: ${memecoinabouttextcolor.length > 0 ? memecoinabouttextcolor : 'white'}; font-family: ${memecoinaboutfont}; font-size: ${memeMainfontsize}px;">${template1caption} ${memecoinsymbol}</h1>
        <p style="color: ${memecoinabouttextcolor.length > 0 ? memecoinabouttextcolor : 'white'}; font-family: ${memecoinaboutfont}; font-size: 18px;">${template1Info}</p>
        <div class="telegrambutton">
            <button style="margin-right: 10px; color: ${memecoinabouttextcolor}; font-family: ${memecoinaboutfont}; font-size: ${memeMainfontsize}px;" onclick="window.open('${memetelegramlink}')">${telegramtext}</button>
            <button style="color: ${memecoinabouttextcolor}; font-family: ${memecoinaboutfont}; font-size: ${memeMainfontsize}px;" onclick="window.open('${memetwitterlink}')">${twittertext}</button>
        </div>
    </div>
    <div class="containerDiv2">
        <img class="hero-image" src="${memecoinaboutrightimage.length > 0 ? memecoinaboutrightimage : Template1mainimage}" alt="Molly Image" style="width: 100%; max-width: 400px; margin: 20px auto;" />
    </div>
</div>
  <div id="Vision" class="sectionred" style="background-color: ${memeaboutbackground == 0 ? (memeaboutbgcolor.length == 0 ? '#8e3134' : memeaboutbgcolor) : ''}; background-image: ${memeaboutbackground == 1 ? (memeaboutbgimage.length !== 0 ? `url(${memeaboutbgimage})` : '') : ''}; width: 100%; height: 100%;padding-top:30px;background-repeat: no-repeat;background-size: cover">
    <div class="sectionredDiv1">
        <h1 style="font-family: ${memecoinvisionfont}; color: ${memecoinvisiontextcolor.length > 0 ? memecoinvisiontextcolor : 'white'}; font-size: ${memeaboutfontSize}px;">${memecoinsymbol}</h1>
        <p style="font-family: ${memecoinvisionfont}; color: ${memecoinvisiontextcolor.length > 0 ? memecoinvisiontextcolor : 'white'}; font-size: 20px;width: 100%;height: 100%;overflow:'hinden'">
            ${memecoinAbout}
        </p>
    </div>
    <div class="sectionredDiv2">
        <img class="heroimage" src="${memecoinavisionrightimage.length > 0 ? memecoinavisionrightimage : Template1Solly}" alt="Molly Image" style="width: 100%; max-width: 400px; margin: 20px auto;" />
    </div>
</div>

<div id="Token" class="tokenomics" style="background-image: ${memetokenomicsbackground == 0 ? (memecointokonomicsbgimage.length > 0 ? `url(${memecointokonomicsbgimage})` : '') : ''}; object-fit: cover; background-size: cover; background-color: ${memetokenomicsbackground == 1 ? memetokenomicsbgcolor : (memecointokonomicsbgimage.length === 0 ? '#7b42cc' : '')};width: 100%;height: fit-content;">
    <h2 style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : 'white'}; font-size: 20px;">${template1tokenomics}</h2>
    <p style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : 'white'}; font-size: ${memestepfontSize}px;">${template1tokenomicssub1}</p>
    <div class="stats">
        <div style="text-align: center;">
            <img src="${memecointokenomicsimage.length > 0 ? memecointokenomicsimage : Tokenomic1}" alt="Supply Image" style="width: 200px; height: 200px; margin-bottom: 10px;" />
            <h3 style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : 'white'}; font-size: ${memestepfontSize}px;">${template1tokenomisupply.label}</h3>
            <p style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : 'white'}; font-size: ${memestepfontSize}px;">${template1tokenomisupply.value}</p>
        </div>
        <div style="text-align: center;">
            <img src="${template1tokenimage2.length > 0 ? template1tokenimage2 : Tokenomics2}" alt="Contract Image" style="width: 200px; height: 200px; margin-bottom: 10px;" />
            <h3 style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : 'white'}; font-size: ${memestepfontSize}px;">${template1tokenomicontract.label}</h3>
            <p style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : 'white'}; font-size: ${memestepfontSize}px;">${template1tokenomicontract.value}</p>
        </div>
        <div style="text-align: center;">
            <img src="${template1tokenimage3.length > 0 ? template1tokenimage3 : Tokenomics3}" alt="Tax Image" style="width: 200px; height: 200px; margin-bottom: 10px;" />
            <h3 style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : 'white'}; font-size: ${memestepfontSize}px;">${template1tokenomiTax.label}</h3>
            <p style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : 'white'}; font-size: ${memestepfontSize}px;">${template1tokenomiTax.value}</p>
        </div>
    </div>
    
</div>
  
  <div id="HowTOBuy" class="socials" style="background-image: ${memestepbackground == 0 ? `url(${memestepbgimage})` : ''}; background-size: cover; object-fit: cover; background-color: ${memestepbackground == 1 ? memestepbgcolor : (memestepbgimage.length === 0 ? '#8e3134' : '')}; ">
  <div>
        <h1 style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : 'white'}; font-size: ${memestepfontSize}px;">${template1tokenomicssub2}</h1>
        <p style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : 'white'}; font-size: 20px;">${template1tokenomicssub3}</p>
    </div>
    <div class="socialsDiv1">
        <div class="socialsDiv3" style="padding: 20px;  border-radius: 5px;
        background-color:  ${t4statsbackgrounsd.length > 0 ? t4statsbackgrounsd : '#ffffff'}; 
       border: ${statsborder}px solid ${statsbordercolor};
       border-radius: {{ statsborderradius }}px;
         box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21)` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};
 
        ">
            <img src="${stepimage1.length > 0 ? stepimage1 : ContractImage1}" alt="Telegram" style="width: 100px; height: 100px; margin: 15px; cursor:pointer;"  onclick="window.open('${memetelegramlink}')"/>
        </div>
        <div class="socialsDiv3" style=" padding: 20px;  border-radius: 5px;
        background-color:  ${t4statsbackgrounsd.length > 0 ? t4statsbackgrounsd : '#ffffff'}; 
       border: ${statsborder}px solid ${statsbordercolor};
       border-radius: {{ statsborderradius }}px;
         box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21)` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};
 
        ">
            <img src="${stepimage2.length > 0 ? stepimage2 : ContractImage2}" alt="Telegram" style="width: 100px; height: 100px; margin: 15px; cursor:pointer;"  onclick="window.open('${template2cmc}')"/>
        </div>
        <div class="socialsDiv3" style=" padding: 20px;  border-radius: 5px;
        background-color:  ${t4statsbackgrounsd.length > 0 ? t4statsbackgrounsd : '#ffffff'}; 
       border: ${statsborder}px solid ${statsbordercolor};
       border-radius: {{ statsborderradius }}px;
         box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21)` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};
 
        ">
            <img src="${stepimage3.length > 0 ? stepimage3 : ContractImage3}" alt="Telegram" style="width: 100px; height: 100px; margin: 15px; cursor:pointer;"  onclick="window.open('${template2uniswap}')"/>
        </div>
    </div>
    <div class="socialsDiv2" >
       <div style=" color: ${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#8e3134'};  font-size: 1.1rem; border-radius: 5px; margin-top: 20px; font-family: ${memecoinstepfont}; height: 100px;width: fit-content; display: flex; align-items: center; justify-content: center;
       background:  ${statsbackgroundcolor != 'white' ? statsbackgroundcolor : '#ffffff'}; 
       border: ${statsborder}px solid ${statsbordercolor};
       border-radius: {{ statsborderradius }}px;
         box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21)` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};padding: 10px;
 
       "> ${template1stepcontract} ${template1stepAdd}</div>
    </div>
</div>

        <div id="Footer" class="footer" style=" background-image: ${memefootrbackground == 1 ? (memefooterbgimage.length !== 0 ? `url(${memefooterbgimage})` : '') : ''}; object-fit: cover; background-size: cover; background-color: ${memefootrbackground == 0 ? (memefooterbgcolor.length == 0 ? '#7b42cc' : memefooterbgcolor) : ''};">
    <div class="footerdiv1">
        <p style="color: ${memefootertextcolor.length > 0 ? memefootertextcolor : 'white'}; font-size: ${memefooterfontSize}px; font-family: ${memefootertextfont};font-size:25px;">${memecoinsymbol}</p>
        <p style="color: ${memefootertextcolor.length > 0 ? memefootertextcolor : 'white'}; font-size: ${memefooterfontSize}px; font-family: ${memefootertextfont};width:100%;height:100%;overflow:hidden;font-size:20px">${template1footerabout}</p>
        <p style="color: ${memefootertextcolor.length > 0 ? memefootertextcolor : 'white'}; font-size: ${memefooterfontSize}px; font-family: ${memefootertextfont};font-size:20px">${memecoinsymbol}${t1footer}</p>
    </div>
    <div class="footerdiv2">
        <img src="${template1footerImage.length > 0 ? template1footerImage : Template1Footer}" alt="Logo"  height="70%" width="70%" />
    </div>
</div>


         </div>
     </body>
     <script>
    let googletransalte = ${googletransalte};

    function toggleGoogleTranslate() {
        googletransalte = !googletransalte;
        document.getElementById('google_translate_element').style.display = googletransalte ? 'block' : 'none';
    }
</script>
      <script type="text/javascript">
        function googleTranslateElementInit() {
            new google.translate.TranslateElement(
                { pageLanguage: 'en' },
                'google_translate_element'  
            );
        }
    </script>
     <script
        type="text/javascript"
        src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    ></script>
     </html>`




        const NewmemeTemplate2 = `
          <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>${memecoinname}</title>
   <style>
       @import url("https://fonts.googleapis.com/css2?family=Itim:wght@400&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Itim", sans-serif;
}
section {
  background-color: #fe9c34;
}
section .first-section {
  height: auto;
  padding-top: 5em;
  background-image: url("./images/wildmainbg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
/* commnon start */

.nav-article,
.main-article,
.second-section main,
.Tokenomics,
.Tokenomics-section main,
.footer-section {
  width: 90%;
  margin: auto;
}
.main-article,
.nav,
.logo-container,
.btns,
.three-links,
.three-btn,
.second-section main,
.Tokenomics-section main,
.Tokenomics {
  display: flex;
}
.nav,
.logo-container,
.three-links,
.second-section main,
.Tokenomics {
  align-items: center;
}
.icons,
.menu {
  display: flex;
  flex-wrap: wrap;
}

/* commnon End */

/*  navbar start */
.nav-article {
  width: 90%;
  border-radius: 8px;
  background-color: #fff;
  border: 2px solid #000;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.8);
  padding: 10px;
  overflow: hidden;
}
.nav {
  justify-content: space-between;
  height: 60px;
}
.logo {
  width: 50px;
  height: 60px;
  object-fit: cover;
}
.heading {
  
  font-size: 30px;
  font-family: Itim;
  color: #fff;
  text-shadow: 2px 0 0 #000, 0 2px 0 #000, -2px 0 0 #000, 0 -2px 0 #000;
}
.menu {
  list-style: none;
  gap: 20px;
}
.menu-item a {
  text-decoration: none;
  color: #000;
  font-size: 20px;
  padding: 5px 10px;
}
.menu-item a:hover {
  color: #007bff;
}
.icons {
  gap: 15px;
}
.icon {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
/*  navbar End */

/* main coin start */
.main-article aside {
  width: 50%;
  margin-top: 4.5em;
}
.main-left .heading {
  margin-left: 1.8em;
}
.heading-1 {
  font-weight: 400;
  font-size: 72px;
  font-family: Itim;

  text-shadow: 2px 0 0 #000, 0 2px 0 #000, -2px 0 0 #000, 0 -2px 0 #000;
}
.main-left .heading-6 {
  font-size: 32px;
  font-family: Itim;

  text-shadow: 1.5px 0 0 #000, 0 1.5px 0 #000, -1.5px 0 0 #000, 0 -1.5px 0 #000;
}
.main-left img {
  width: 500px;
  height: 500px;
}

.welcome-text-txt-container {
  width: 100%;
}
.welcome-text {
  font-size: 40px;
  font-family: Itim;
  text-shadow: 1.5px 0 0 #000, 0 1.5px 0 #000, -1.5px 0 0 #000, 0 -1.5px 0 #000;
  color: #fff;
  line-height: 50px;
}
.g {
  color: #ffcd4e;
}
.meme {
  display: inline-block;
  margin-top: 0.3em;
}
.btns {
  margin-top: 2em;
  gap: 0px 20px;
}
.link {
  box-shadow: 6px 6px 0px #000;
  border-radius: 5px;
  border: 1px solid #000;
  height: 45px;
}
.link a {
  text-decoration: none;
  font-family: Itim;
  padding: 6px 12px;
  font-size: 16px;
}

.CA {
  margin-top: 1.5em;
}
.contract-label {
  font-size: 28px;
  font-family: Itim;

  word-break: break-all;
  text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
    0 -1px 0 #181818;
}
.three-btn {
  gap: 0px 20px;
  margin-top: 2em;
  
}
.three-links {
  font-family: Itim;
}
.three-links img {
  height: 32px;
  width: 32px;
}

.three-btn #link1 {
  box-shadow: 6px 6px 0px #8c3483;
}
.three-btn #link2 {
  box-shadow: 6px 6px 0px #1a48b5;
}
.three-btn #link3 {
  box-shadow: 6px 6px 0px #507125;
}
/* main coin End */

/* About start */
.second-section,
.Tokenomics-section,
.howtobuysection {
  height: auto;
  margin: auto;
  padding-top: 2em;
  background-image: url("./images/wildmainbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
}
.second-section main {
  padding: 5em 0;
}
.second-section .second-left {
  width: 60%;
}
.second-section .second-right {
  width: 40%;
}
.about-text {
  width: 80%;
  margin: auto;
}
.heading-about {
  text-align: center;
}
.second-right img {
  width: 100%;
  height: 500px;
}
.second-btn {
  margin-left: 5em;
}
/* Tokenomics start */
.Tokenomics-left,
.Tokenomics-right {
  width: 50%;
}
.Tokenomics {
  padding-top: 4em;
  justify-content: center;
  gap: 0px 10px;
}
.Tokenomics-section main {
  margin-top: 5em;
}
.heading-3 {
  width: 85%;
  font-size: 40px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px 0px;

  font-family: Itim;
}
.supply-statement {
  text-shadow: 1.5px 0 0 #000, 0 1.5px 0 #000, -1.5px 0 0 #000, 0 -1.5px 0 #000;
}
.three-links {
  gap: 0px 10px;
}
#Tokenomics-btn {
  margin-top: 4em;
}
/* How to Buy start */
.howtobuy-text {
  display: flex;
  flex-direction: column;
  gap: 1em 0;
  text-align: center;
  max-width: 840px;
  margin-top: 1em;
}
/*  Footer start  */
.footer-section,
.howtobuy-content {
  height: auto;
  padding: 5em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.footer-heading {
  font-family: Itim;
  font-size: 35px;
  text-align: center;
  text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
    0 -1px 0 #181818;
}

.text2 {
  margin-top: 1em;
}
/* Footer End */

@media (max-width: 1010px) {
  .nav {
    flex-direction: column;
    height: auto;
    gap: 1em 0;
  }
  .logo-container {
    justify-content: center;
  }
  .logo-container {
    text-align: center;
  }
}
@media (max-width: 992px) {
  .main-article,
  .second-section main,
  .Tokenomics-section main {
    flex-direction: column;
  }
  .main-article,
  .main-article aside {
    align-items: center;
    justify-content: center;
  }
  .main-article aside,
  .Tokenomics-left,
  .Tokenomics-left img,
  .second-section .second-right,
  .second-section .second-left,
  .about-text,
  .Tokenomics-right,.heading-3  {
    width: 100%;
  }
  .three-btn,
  .second-btn {
    gap: 1em 1em;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .second-btn,
  .main-left .heading,
  .heading-3 {
    margin: auto;
  }
  .heading-1,
  .main-left .heading-6,
  .about-text,
  .welcome-text-txt-container,
  .heading-3 {
    text-align: center;
  }
  .main-article aside,
  .second-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  #Tokenomics-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .main-left img,
  .contract-label,
  .heading-3 {
    width: 100%;
  }
}
@media (max-width: 450px) {
  .heading-1 {
    font-size: 7vw;
  }
  .heading-3,
  .welcome-text,
  .footer-heading {
    font-size: 5vw;
  }
  .contract-label {
    font-size: 6vw;
  }
  .howtobuy-text,
  .Tokenomics,
  .Tokenomics-section,
  .second-section main,
  .footer-section,
  .howtobuy-content,
  .second-right {
    padding: 0.5em 0.5em;
  }
  .Tokenomics-section main,
  .main-article aside {
    margin: auto;
  }
  .link a {
    font-size: 2.6vw;
  }
    .three-btn{
      flex-wrap: wrap;
    }
}




#google_translate_element {
    width: 300px;
    float: right;
    text-align: right;
    display: block;
}
.goog-te-banner-frame.skiptranslate {
    display: none !important;
}
body {
    top: 0px !important;
}
#goog-gt-tt {
    display: none !important;
    top: 0px !important;
}
.goog-tooltip skiptranslate {
    display: none !important;
    top: 0px !important;
}
.activity-root {
    display: hide !important;
}
.status-message {
    display: hide !important;
}
.started-activity-container {
    display: hide !important;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
    display: none;
}

.VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q {
    display: none;
    background-color: unset !important;
    box-shadow: none !important;
}


   </style>

  </head>
  <body>
    <!-- Navbar and Main section -->
    <section
       style="
          background-color: ${memeherobackground == 0 ? (memeherobgcolor.length == 0 ? '#fe9c34' : memeherobgcolor) : ''};
          background-image: ${memeherobackground == 1 ? (memecoinaboutbgImage.length !== 0 ? `url(${memecoinaboutbgImage})` : '') : `url(${Template2sectionbg})`};
          object-fit: cover;
          background-size: cover;
          background-repeat: no-repeat;
          background-psition: center;
      "
    >
    <div style="position: absolute; right: 20px; top: 5px; cursor: pointer;color:white">
    <div onclick="toggleGoogleTranslate()">EN</div>

    <div
        style="background-color: transparent; 
               padding: 0px 8px; 
               margin-right: 10px; 
               border-radius: 8px; 
               display: ${googletransalte == false ? 'none' : 'block'};"
        id="google_translate_element">
    </div>
    </div>
      <!-- Navbar -->
      <article class="first-section">
        <aside class="nav-article"
         style="
                  background-image: ${memebackground == 0 ? (memenavbgcolor.length > 0 ? `url(${memenavbgcolor})` : '') : ''};
                  object-fit: cover;
                  background-size: cover;
                  background-color: ${memebackground == 1 ? memenavbarbgcolor : ''};
                  background-repeat: no-repeat;
                  background-position: center;
              "
        >
          <nav class="nav">
            <aside class="logo-container">
              <img class="logo" alt="logo"src="${memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}" />
              <div class="heading" style="font-family: ${memecoinanvfont}; color: ${memenavtextcolor}; font-size: ${memenavfontsize}px;">${memecoinname}</div>
            </aside>
            <ul class="menu">
              <li class="menu-item"><a href="#" style="font-family: ${memecoinanvfont}; color: ${memenavtextcolor}; font-size: ${memenavfontsize}px;">${Navbar[0]}</a></li>
              <li class="menu-item"><a href="#" style="font-family: ${memecoinanvfont}; color: ${memenavtextcolor}; font-size: ${memenavfontsize}px;">${Navbar[1]}</a></li>
              <li class="menu-item"><a href="#" style="font-family: ${memecoinanvfont}; color: ${memenavtextcolor}; font-size: ${memenavfontsize}px;">${Navbar[2]}</a></li>
              <li class="menu-item"><a href="#" style="font-family: ${memecoinanvfont}; color: ${memenavtextcolor}; font-size: ${memenavfontsize}px;">${Navbar[3]}</a></li>
            </ul>
            <aside class="icons">
              <img class="icon" alt="Link icon" src="${telegraminage.length>0?telegraminage:Template2telegram}" onclick="window.open('${template2telegramlink}')" style="cursor:pointer;"/>
              <img class="icon" alt="Link icon" src="${twitterimage.length>0?twitterimage:Template2twitter}" onclick="window.open('${template2twitterlink}')" style="cursor:pointer;"/>
            </aside>
          </nav>
        </aside>

        <!-- Main section -->
        <aside
          
        >
          <main class="main-article"
             
          >
            <aside class="main-left">
              <div class="heading">
                <h1 class="heading-1" style=' color: ${memecoinabouttextcolor.length>0?memecoinabouttextcolor:'#fff'}; font-family: ${memecoinaboutfont};font-size:${memeMainfontsize}px' >${memecoinsymbol}</h1>
                <h6 class="heading-6" style=' color: ${memecoinabouttextcolor.length>0?memecoinabouttextcolor:'#fff'}; font-family: ${memecoinaboutfont};font-size:${memeMainfontsize}px'>${template2memecaption}</h6>
              </div>
              <img  src="${memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}" alt="" />
            </aside>
            <aside class="main-right">
              <div class="welcome-text">
                <p class="welcome-text-txt-container">
                  <span style=' color: ${memecoinabouttextcolor}; font-family: ${memecoinaboutfont};font-size:${memeMainfontsize}px'>${template2caption} </span>
                  <span class="g" style=' color: ${memecoinabouttextcolor.length>0?memecoinabouttextcolor:'#ffcd4e'}; font-family: ${memecoinaboutfont};font-size:${memeMainfontsize}px'>${memecoinsymbol} </span>
                   <span class="meme" style=' color: ${memecoinabouttextcolor}; font-family: ${memecoinaboutfont};font-size:${memeMainfontsize}px'>${memecoinsymbol} ${template2info}</span>
                  
                </p>
              </div>
              <div class="btns">
                <button class="link" onclick="window.open('${template2cmc}')" style="cursor:pointer">
                  <a href="" 
                   style=' color: ${memecoinabouttextcolor.length>0?memecoinabouttextcolor:'#000'}; font-family: ${memecoinaboutfont};font-size:${memeMainfontsize}px' >${template2mainbutton1} ${memecoinsymbol}</a
                  >
                </button>
                <button class="link" onclick="window.open('${template2telegramlink}')" style="cursor:pointer">
                  <a href=""  
                   style=' color: ${memecoinabouttextcolor.length>0?memecoinabouttextcolor:'#000'}; font-family: ${memecoinaboutfont};font-size:${memeMainfontsize}px' >${template2mainbutton2}</a
                  >
                </button>
                <br/>
              </div>
              
              <p class="contract-label"style=' color: ${memecoinabouttextcolor.length>0?memecoinabouttextcolor:'#fff'}; font-family: ${memecoinaboutfont};font-size:${memeMainfontsize}px;margin-top:20px' >
                ${template2tokenAddress}
              </p>
              <div class="three-btn">
          ${uniswapimage.length>0?`<img src=${uniswapimage} height="50px" width="100px" alt="" style='cursor:pointer' onclick="window.open('${template2uniswap}')" />`:`<button
                  class="link"
                  id="link1"
                  style="background-color: #ff94f4;;cursor:pointer"
                  onclick="window.open('${template2uniswap}')"
                >
                  <a href="" class="three-links" style="color: #fff">
                    <span>Uniswap</span>
                    <img src=${Template2unicorn} alt="" />
                  </a>
                </button>`}
               ${cmcimage.length>0?`<img src=${cmcimage} height="50px" width="100px" alt="" style='cursor:pointer'  onclick="window.open('${template2cmc}')"/> `:`<button
                  class="link"
                  id="link2"
                  style="background-color: #2b6aff ;cursor:pointer"
                  onclick="window.open('${template2cmc}')"
                >
                  <a href="" class="three-links" style="color: #fff">
                    <span>CMC</span>
                    <img src=${Template2M} alt="" />
                  </a>
                </button>`}
               ${coingeckoimage.length>0?`<img src=${coingeckoimage} height="50px" width="100px" alt="" style='cursor:pointer' onclick="window.open('${template2coingecko}')" />`:` <button
                  class="link"
                  id="link3"
                  style="background-color: #8cc63f;cursor:pointer"
                  onclick="window.open('${template2coingecko}')"
                >
                  <a href="" class="three-links" style="color: #fff">
                    <span>CoinGecko</span>
                    <img src=${Template2Forg} alt="" />
                  </a>
                </button>`}
              </div>
            </aside>
          </main>
        </aside>
      </article>
    </section>

    <!-- About Start-->
    <section  
      style="
          background-color: ${memeaboutbackground == 0 ? (memeaboutbgcolor.length == 0 ? '#fe9c34' : memeaboutbgcolor) : ''};
          background-image: ${memeaboutbackground == 1 ? (memeaboutbgimage.length !== 0 ? `url(${memeaboutbgimage})` : '') : `url(${Template2sectionbg})`};
          object-fit: cover;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
      "
    >
      <article class="second-section">
        <main>
          <aside class="second-left">
            <h1 class="heading-1 heading-about" style="font-family: ${memecoinvisionfont}; color: ${memecoinvisiontextcolor.length>0?memecoinvisiontextcolor:' #fff'}; font-size: ${memeaboutfontSize}px;">${memeabout}</h1>
            <div class="welcome-text about-text">
              <p class="welcome-text-txt-container" style="font-family: ${memecoinvisionfont}; color: ${memecoinvisiontextcolor.length>0?memecoinvisiontextcolor:' #ffcd4e'}; font-size: ${memeaboutfontSize}px;">
                  ${memecoinAbout} ${memecoinsymbol}
              </p>
            </div>
            <div class="btns second-btn">
              <button class="link" onclick="window.open('${template2cmc}')" style="cursor:pointer">
                <a href="" class="buy-link-text" style="font-family: ${memecoinvisionfont}; color: ${memecoinvisiontextcolor.length>0?memecoinvisiontextcolor:' black'}; font-size: ${memeaboutfontSize}px;"">${template2mainbutton1} ${memecoinsymbol}</a>
              </button>
              <img src="${memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}"alt=""  width="60px" height="60px"/>
            </div>
          </aside>
          <aside class="second-right">
            <img src="${memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}" alt="" />
          </aside>
        </main>
      </article>
    </section>
    <!-- About End-->

    <!-- Tokenomics Start-->
    <section 
    style="
      background-color: ${memetokenomicsbackground == 0 ? (memetokenomicsbgcolor.length == 0 ? '#fe9c34' : memetokenomicsbgcolor) : ''};
      background-image: ${memetokenomicsbackground == 1 ? (memecointokonomicsbgimage.length !== 0 ? `url(${memecointokonomicsbgimage})` : '') : `url(${Template2sectionbg})`};
      object-fit: cover;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
  " 
    >
      <article class="Tokenomics-section">
        <div class="Tokenomics">
          <h1 class="heading-1" style=' font-family: ${memecointokenfont}; color: ${memecointoketextcolor.length>0?memecointoketextcolor:'#fff'};font-size:${memetokenfontSize}px'> ${template2tokenheading}</h1>
          <img src="${memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}" height="60px" width="60px" alt="" />
          
        </div>
        <main>
          <aside class="Tokenomics-left">
            <img src="${memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}" alt="" width="500px" height="500px" />
          </aside>
          <aside class="Tokenomics-right">
            <div class="heading-3" style="color: #fff">
              <p class="supply-statement"  style=' font-family: ${memecointokenfont}; color: ${memecointoketextcolor.length>0?memecointoketextcolor:'#fff'};font-size:${memetokenfontSize}px'>
                ${template2tokensupply} <br />${template2tokentatesupply}
              </p>

              <p>
                <span class="supply-statement" 
                style=' font-family: ${memecointokenfont}; color: ${memecointoketextcolor.length>0?memecointoketextcolor:'#fff'};font-size:${memetokenfontSize}px'
                  >${template2tokentax}</span
                >
              </p>
              <p>
                <span class="supply-statement" 
                style=' font-family: ${memecointokenfont}; color: ${memecointoketextcolor.length>0?memecointoketextcolor:'#fff'};font-size:${memetokenfontSize}px'
                  >${template2tokenlp}</span
                >
              </p>
              <p>
                <span class="supply-statement" 
                style=' font-family: ${memecointokenfont}; color: ${memecointoketextcolor.length>0?memecointoketextcolor:'#fff'};font-size:${memetokenfontSize}px'
                  > ${memecoinsymbol} ${template2tokenMeme}</span
                >
              </p>
            </div>
            <div class="btns" id="Tokenomics-btn">
              <button class="link" id="link2" onclick="window.open('${template2telegramlink}')" style="cursor:pointer">
                <a href="" class="three-links" >
                  <span style=' font-family: ${memecointokenfont}; color: ${memecointoketextcolor!=='white'?memecointoketextcolor:'black'};font-size:${memetokenfontSize}px'>${template2tokenbutton} ${memecoinsymbol} </span>
                  <img  src="${telegraminage.length > 0 ? telegraminage:Template2telegram}" alt="" />
                </a>
              </button>
              <img src="${memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}" height="60px" width="60px" alt="" />
            </div>
          </aside>
        </main>
      </article>
    </section>
    <!-- Tokenomics End-->

    <!-- How to Buy Start-->
    <section 
      style="
      background-color: ${memestepbackground == 0 ? (memestepbgcolor.length == 0 ? '#fe9c34' : memestepbgcolor) : ''};
      background-image: ${memestepbackground == 1 ? (memestepbgimage.length !== 0 ? `url(${memestepbgimage})` : '') : `url(${Template2sectionbg})`};
      object-fit: cover;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
  "
    >
      <article class="howtobuysection">
        <aside class="howtobuy-content">
        <div  style='display:flex;align-items:center;justify-content:center;gap:30px;'>
          <h1 class="heading-1" 
          style="
          font-family: ${memecoinstepfont};
          color: ${memecoinsteptextcolor.length>0?memecoinsteptextcolor:'#fff'};
          font-size: ${memestepfontSize}px;
      ">${template2stepheading} ${memecoinsymbol}</h1>
          <img src="${memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}" height="60px" width="60px" alt="" />
          </div>
          <div class="welcome-text howtobuy-text" 
           style="
          font-family: ${memecoinstepfont};
          color: ${memecoinsteptextcolor};
          font-size: ${memestepfontSize}px;
      "
          >
            <p>
              <span style=" color: ${memecoinsteptextcolor.length>0?memecoinsteptextcolor:'#ffcd4e'};">${template2stepheading1}</span>${template2step1}
            </p>
            <p>
              <span style="  color: ${memecoinsteptextcolor.length>0?memecoinsteptextcolor:'#ffcd4e'};">${template2stepheading2} </span> ${template2step2}
            </p>
            <p>
              <span  style="  color: ${memecoinsteptextcolor.length>0?memecoinsteptextcolor:'#ffcd4e'};">${template2stepheading3}</span>${template2step3}
            </p>
            <p>
              <span  style="  color: ${memecoinsteptextcolor.length>0?memecoinsteptextcolor:'#ffcd4e'};">${template2stepheading4.concat(memecoinsymbol)} </span>${template2step4}
            </p>
          </div>
        </aside>
      </article>
    </section>
    <!-- How to Buy End -->

    <!-- Footer start -->
    <section 
     style="
      background-image: ${memefootrbackground == 1 ? (memefooterbgimage.length !== 0 ? `url(${memefooterbgimage})` : '') : ''};
      background-repet:norepet;
      object-fit: cover;
      background-size: cover;
      background-position:center;
      background-color: ${memefootrbackground == 0 ? (memefooterbgcolor.length == 0 ? '#fe9c34' : memefooterbgcolor) : ''};
  "
    >
      <article class="footer-section">
        <aside>
          <img src="${memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}" width="200px" height="200px" class="center-img" alt="" />
        </aside>
        <aside>
          <div class="footer-heading" style="color: #fff">
            <p style="
          color: ${memefootertextcolor};
          font-family: ${memefootertextfont};
          
      " >${template2footeringo}</p>
            <p class="text2" style="
          color: ${memefootertextcolor};
          font-family: ${memefootertextfont};
          
      ">
               ${test2}
            </p>
          </div>
        </aside>
      </article>
    </section>
    <!-- Footer End -->
  </body>
  <script>
    let googletransalte = ${googletransalte};

    function toggleGoogleTranslate() {
        googletransalte = !googletransalte;
        document.getElementById('google_translate_element').style.display = googletransalte ? 'block' : 'none';
    }
</script>
      <script type="text/javascript">
        function googleTranslateElementInit() {
            new google.translate.TranslateElement(
                { pageLanguage: 'en' },
                'google_translate_element'  
            );
        }
    </script>
    <script
        type="text/javascript"
        src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    ></script>

</html>

    
`

        const NewmemeTemplate3 = `
    <!DOCTYPE html>
    <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>${memecoinsymbol}</title>
    <style>
    @import url("https://fonts.googleapis.com/css2?family=Finger+Paint:wght@400&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.main-page {
  height: auto;
  background-image: url(./images/chess1.jfif);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.main-page .cartoonparent {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.first-aside-btn {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding-top:80px
}
.cartoonparent img {
  width: 70%;
}
.second-section {
  
}
.second-section .first-aside {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.welgome {
  margin-top: 100px;
  height: auto;
  border: 10px solid #fff;
  border-radius: 20px;
  background-color: #844f36;
  max-width: 791.3px;
  font-size: 49.36px;
  letter-spacing: 7.04px;
  font-family: Inter;
  color: #fff;
  text-align: center;
  wordwrap: break-word;
}

.first-aside .innovadion {
  margin-top: 46px;
  max-width: 992px;
  font-size: 33.6px;
  line-height: 47px;
  font-family: "Finger Paint";
  color: #fff;
  text-align: center;
}
.memecoin {
  margin-top: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  background-image: url("./images/rhombus.png");
  background-position: bottom center;
  background-repeat: no-repeat;
}
.memecoin img {
  width: 50%;
}

/* Third section css starts here */

.third-section {
  
  /* margin-bottom: 100px; */
}
.top-aside,
.bottom-aside {
  width: 100%;
  padding: 50px;
}
.ghoose-parent {
  width: 60%;
  background-color: #fff;
  margin: 50px auto;
  padding: 40px;
  border-radius: 20px;
}
.ghoose-child {
  font-size: 40px;
  color: #ffffff;
  text-align: center;
  background-color: #844f36;
  word-break: keep-all;
}
.four-cards-parent {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 40px 10px;
  flex-wrap: wrap;
  margin: auto;
}
.four-card {
  width: 48%;
  /* width: 289.23px; */
  /* min-width: 289.23px; */
  height: 280px;

  padding: 20px;
  background-color: #98573a;
  color: #fff;
  font-size: 18px;
  border: 5px solid #fff;
  border-radius: 12px;
  overflow: hidden;
}
.four-card-heafding-text {
  font-weight: 300;
  font-family: Inter;
  font-size: 15.95px;
  font-weight: 400;
  line-height: 34px;
  text-align: left;
}
.four-card-para {
  font-family: Finger Paint;
  font-size: 17.8px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
}

/* Fourth section css start here  */
.fourth-section,
.Fifth-section {
  width: 100%;
 
  padding-top: 100px;
}
.fourth-section {
  width: 100%;
  background-size: cover;
  background-position: center;

  /* padding-top: 50px; */
  color: white;
}

.Fourth-first-aside,
.Fifth-first-aside {
  /* padding-top: 50px; */
  width: 80%;
  display: flex;
  margin: auto;
}

.Fourth-first-aside .snupped-image {
  width: 40%;
}
.snupped-image img {
  width: 80%;
  height: 80%;
}
.snupped-content {
  width: 100%;
}
.snupped-heading {
  font-family: Inter;
  font-size: 67.31px;
  font-weight: 400;
  line-height: 85px;
  letter-spacing: -1.9199999570846558px;
  text-align: left;
  color: #fff;
}
.snupped-para {
  font-family: Finger Paint;
  font-size: 34.7px;
  font-weight: 400;
  line-height: 52px;
  text-align: left;
  color: #fff;
}

/* Fifth section css starts here */
.dog-content {
  width: 60%;
}
.dog-image {
  width: 40%;
}
.dog-image img {
  height: 80%;
  width: 80%;
}

.sixth-section {
  display: flex;
  align-items: center;
  flex-direction: column;

  gap: 50px 0;
  background-image: url(./images/chess1.jfif);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.btn {
  font-family: Inter;
  color: #fff;
  font-size: 2rem;
  font-weight: 400;
  border: 5px solid black;
  padding: 6px 24px;
  border-radius: 12px;
  margin-top: 50px;
  text-align: center;
  background-color: #844f36;
}
.footerpara {
  width: 50%;
  height: auto;
  margin: auto;
  word-break: break-all;
  padding: 10px;
  color: #fff;
  background-color: #844f36;
  border-radius: 12px;
  border: 5px solid black;
  font-family: Finger Paint;
  font-size: 20px;
  font-weight: 400;

  text-align: center;
}

.media-btn {
  min-width: 220px;
  padding: 4px;
  margin: 20px;
  border: 3px solid black;
  border-radius: 12px;
  font-family: Inter;
  font-size: 32.95px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
  box-shadow: 0px 3px 0px #98573a;
}
.spurdo {
  width: 70%;
  margin: auto;
  display: flex;
  align-content: center;
}
.spurdo img {
  width: 70%;
}
.footer-text {
  padding: 4px;
  margin: 20px;
  border: 3px solid black;
  border-radius: 12px;
  font-family: Inter;
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
  color: #fff;
  word-break: break-all;
  background-color: #844f36;
}
@media (max-width: 908px) {
  .four-card {
    height: auto;
  }
  .four-card,
  .dog-content {
    width: 100%;
    overflow: hidden;
  }
  .snupped-heading {
    text-align: center;
    font-size:2rem;
  }
  .Fourth-first-aside,
  .Fifth-first-aside {
    flex-direction: column;
    align-items: center;
  }
  .Fourth-first-aside .snupped-image {
    width: 70%;
  }
  .snupped-para,
  .first-aside .innovadion {
    font-size: 30px;
    word-break: break-all;
  }
  .welgome,
  .footerpara {
    font-size: 2rem;
  }
  .welgome {
    margin: 50px 20px;
  }
  .first-aside .innovadion {
    margin: 0px 20px;
  }
}

@media (max-width: 520px) {
  .snupped-para,
  .first-aside .innovadion {
    font-size: 15px;
    word-break: break-all;
  }
  .spurdo {
    width: 70%;
  }
  .footerpara {
    width: auto;
    font-size:1.5rem;
  }
    .ghoose-child{
    font-size: 1rem;
    }
    .btn {
    font-size: 1rem;
    } 
}
@media (max-width: 762px) {
  .first-aside-btn {
    flex-direction: column;
    align-items: center;
  }
  .media-btn {
    max-width: max-content;
  }
    .welgome,
  .footerpara {
    font-size: 1rem;
  }
}




   
#google_translate_element {
    width: 300px;
    float: right;
    text-align: right;
    display: block;
}
.goog-te-banner-frame.skiptranslate {
    display: none !important;
}
body {
    top: 0px !important;
}
#goog-gt-tt {
    display: none !important;
    top: 0px !important;
}
.goog-tooltip skiptranslate {
    display: none !important;
    top: 0px !important;
}
.activity-root {
    display: hide !important;
}
.status-message {
    display: hide !important;
}
.started-activity-container {
    display: hide !important;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
    display: none;
}

.VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q {
    display: none;
    background-color: unset !important;
    box-shadow: none !important;
}



    </style>
  </head>
  <body>
    <!-- first section start -->
    <section 
     style="
background-image: ${memeherobackground == 0 ? (memecoinaboutbgImage.length > 0 ? `url(${memecoinaboutbgImage})` : `url(${Template3BG})`) : ''};
background-color: ${memeherobackground == 1 ? memeherobgcolor : ''};
background-size: cover;
background-position: center;
background-repeat: no-repeat;
">
        <div style="position: absolute; right: 10px; top: 5px; cursor: pointer;color:white">
    <div onclick="toggleGoogleTranslate()">EN</div>

    <div
        style="background-color: transparent; 
               padding: 0px 8px; 
               margin-right: 10px; 
               border-radius: 8px; 
               display: ${googletransalte == false ? 'none' : 'block'};"
        id="google_translate_element">
    </div>
</div>
      <article class="main-page">
        <aside class="first-aside-btn">
          <button class="media-btn" style='font-family: ${memecoinanvfont.length > 0 ? `${memecoinanvfont}` : 'Finger Paint'};
        color: ${memenavtextcolor}; position: relative; background-image: ${memebackground == 0 ? `${memenavbgcolor.length > 0 ? `url(${memenavbgcolor})` : ``}` : ''}; object-fit: cover; background-size: cover;background-color: ${memebackground == 1 ? `${memenavbarbgcolor}` : 'white'};background-repeat: no-repeat;background-position: center;'>${Navbar[0]}</button>
          <button class="media-btn" style='font-family: ${memecoinanvfont.length > 0 ? `${memecoinanvfont}` : 'Finger Paint'};
        color: ${memenavtextcolor};position: relative; background-image: ${memebackground == 0 ? `${memenavbgcolor.length > 0 ? `url(${memenavbgcolor})` : ``}` : ''}; object-fit: cover; background-size: cover;background-color: ${memebackground == 1 ? `${memenavbarbgcolor}` : 'white'};background-repeat: no-repeat;background-position: center;'>${Navbar[1]}</button>
        </aside>
        <aside class="cartoonparent">
          <img  src="${memecoinAminitionImage.length > 0 ? memecoinAminitionImage : Template3Sunppd}" alt="" />
        </aside>
      </article>
    </section>
    <!-- first section End -->

    <!-- Second section start -->
    <section 
         style="
        background-color: 
            ${ memeaboutbackground == 0 ? (memeaboutbgcolor.length == 0 ? '#07060d' : memeaboutbgcolor) : '' };
        background-image: 
            ${ memeaboutbackground == 1 ? (memeaboutbgimage.length !== 0 ? `url(${memeaboutbgimage})` : '') : '' };
        height: 100%;
        width: 100%;
        object-fit: cover;
        background-size: cover;
    "

    >
      <article class="second-section" >
        <aside class="first-aside">
          <div class="welgome"
           style='   background-color:${statsbackgroundcolorT3}; border: ${statsborder.length > 0 ? statsborder : '10'}px solid ${statsbordercolor}; border-radius: ${statsborderradius.length > 0 ? statsborderradius : '30'}px; box-shadow: ${statsshowdowrange ? ` 0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};'
          >
            <p className="welgome-do-da" style=' font-family: ${memecoinvisionfont.length > 0 ? memecoinvisionfont : 'Finger Paint'};
            color: ${memecoinvisiontextcolor};
             
            '>${template3heading}</p>
           
          </div>
          <p class="innovadion" style='  font-family: ${memecoinvisionfont.length > 0 ? memecoinvisionfont : 'Finger Paint'};
            color: ${memecoinvisiontextcolor}; word-break: keep-all'>
          ${memecoinAbout}
          </p>
          <div class="memecoin">
            <img  src="${memecoinavisionleftimage.length > 0 ? memecoinavisionleftimage : NewTemplate3Maks}" alt="" />
          </div>
        </aside>
      </article>
    </section>
    <!-- Second section end -->

    <!-- Third section start -->
    <section 
     style="
background-image: ${memetokenomicsbackground == 0 && memecointokonomicsbgimage.length > 0 ? `url(${memecointokonomicsbgimage})` : ''}; 
width: 100%; 
height: 100%; 
object-fit: cover; 
background-size: cover; 
background-color: ${memetokenomicsbackground == 1 ? memetokenomicsbgcolor : memecointokonomicsbgimage.length == 0 ? '#07060d' : ''};
"
    >
      <article class="third-section">
        <aside class="top-aside">
         
            <div class="ghoose-child" style=' font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Finger Paint'}; 
    color: ${memecointoketextcolor}; 
        background-color:${statsbackgroundcolorT3}; border: ${statsborder.length > 0 ? statsborder : '10'}px solid ${statsbordercolor}; border-radius: ${statsborderradius.length > 0 ? statsborderradius : '30'}px; box-shadow: ${statsshowdowrange ? ` 0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};width:auto; height: auto; margin: 0px auto; padding: 20px; text-align: center;'> ${tokenheadingt3} ${memecoinname}?</div>
         
        </aside>
        <aside class="bottom-aside">
          <div class="four-cards-parent">
            <div class="four-card" style="
    font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Finger Paint'}; 
    color: ${memecointoketextcolor}; 
    font-size: ${memetokenfontSize}px;
    background-color:${statsbackgroundcolorT3}; border: ${statsborder.length > 0 ? statsborder : '10'}px solid ${statsbordercolor.length > 0 ? statsbordercolor : 'white'}; border-radius: ${statsborderradius.length > 0 ? statsborderradius : '30'}px; box-shadow: ${statsshowdowrange ? ` 0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};
">
              <p class="four-card-heafding-text" style=' font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Finger Paint'}; 
    color: ${memecointoketextcolor}; '>
               ${tokenheading1T3}
              </p>
              <p class="four-card-para" style=' font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Finger Paint'}; 
    color: ${memecointoketextcolor}; '>
                ${template3section1} ${memecoinsymbol}
              </p>
            </div>
            <div class="four-card" style="
    font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Finger Paint'}; 
    color: ${memecointoketextcolor}; 
    font-size: ${memetokenfontSize}px;
    background-color:${statsbackgroundcolorT3}; border: ${statsborder.length > 0 ? statsborder : '10'}px solid ${statsbordercolor.length > 0 ? statsbordercolor : 'white'}; border-radius: ${statsborderradius.length > 0 ? statsborderradius : '30'}px; box-shadow: ${statsshowdowrange ? ` 0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};
">
              <p class="four-card-heafding-text" style=' font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Finger Paint'}; 
    color: ${memecointoketextcolor}; '>${tokenheading2T3}</p>
              <p class="four-card-para" style=' font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Finger Paint'}; 
    color: ${memecointoketextcolor}; '>
               ${template3Section2}
              </p>
            </div>
            <div class="four-card" style="
    font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Finger Paint'}; 
    color: ${memecointoketextcolor}; 
    font-size: ${memetokenfontSize}px;
    background-color:${statsbackgroundcolorT3}; border: ${statsborder.length > 0 ? statsborder : '10'}px solid ${statsbordercolor.length > 0 ? statsbordercolor : 'white'}; border-radius: ${statsborderradius.length > 0 ? statsborderradius : '30'}px; box-shadow: ${statsshowdowrange ? ` 0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};
">
              <p class="four-card-heafding-text" style=' font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Finger Paint'}; 
    color: ${memecointoketextcolor}; '>${tokenheading3T3}</p>
              <p class="four-card-para" style=' font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Finger Paint'}; 
    color: ${memecointoketextcolor}; '>
              ${template3Section3}
              </p>
            </div>
            <div class="four-card" style="
    font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Finger Paint'}; 
    color: ${memecointoketextcolor}; 
    font-size: ${memetokenfontSize}px;
    background-color:${statsbackgroundcolorT3}; border: ${statsborder.length > 0 ? statsborder : '10'}px solid ${statsbordercolor.length > 0 ? statsbordercolor : 'white'}; border-radius: ${statsborderradius.length > 0 ? statsborderradius : '30'}px; box-shadow: ${statsshowdowrange ? ` 0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};
">
              <p class="four-card-heafding-text" style=' font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Finger Paint'}; 
    color: ${memecointoketextcolor}; '>${tokenheading4T3}</p>
              <p class="four-card-para" style=' font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Finger Paint'}; 
    color: ${memecointoketextcolor}; '>
              ${template3Section4}
              </p>
            </div>
          </div>
        </aside>
      </article>
    </section>
    <!-- Third section End -->

    <!-- Fourth section start -->
    <div style="
background-image: ${memestepbackgroundt3 == 0 ? `url(${template3stepsection2bg})` : ''}; 
height: 100%; 
width: 100%; 
background-size: cover; 
object-fit: cover; 
background-color: ${memestepbackgroundt3 == 1 ? template3stepsection2color : template3stepsection2bg.length === 0 ? '#834d3b' : ''};
background-position: center;background-repeat: no-repeat;
">
    <section 
      
    >
      <article class="fourth-section">
        <aside class="Fourth-first-aside">
          <div class="snupped-image">
            <img src="${memecoinlogoImage.length > 0 ? memecoinlogoImage : Template3SectionSnuppd}"/>
          </div>
          <div class="snupped-content" >
            <h1 class="snupped-heading" style="
font-family: ${memecoinstepfont.length > 0 ? memecoinstepfont : 'Finger Paint'}; 
color: ${memecoinsteptextcolor}; 
font-size: ${memestepfontSize}px;
">${template3stepquestion}  ${memecoinsymbol}?</h1>
            <p class="snupped-para" style="
font-family: ${memecoinstepfont.length > 0 ? memecoinstepfont : 'Finger Paint'}; 
color: ${memecoinsteptextcolor}; 
font-size: ${memestepfontSize}px;
word-break: Keep-all;
">
            ${memecoinname} ${template3stepsection1}
            </p>
          </div>
        </aside>
        <aside class="Fourth-second-aside"></aside>
      </article>
    </section>
    <!-- Fourth section End -->
    <!-- Fifth section start -->
    <section 
      
    >
      <article class="Fifth-section">
        <aside class="Fifth-first-aside">
          <div class="dog-content" >
            <h1 class="snupped-heading" style="
font-family: ${memecoinstepfont.length > 0 ? memecoinstepfont : 'Finger Paint'}; 
color: ${memecoinsteptextcolor}; 
font-size: ${memestepfontSize}px;
"> ${template3step3section2heading}</h1>
            <p class="snupped-para" style="
font-family: ${memecoinstepfont.length > 0 ? memecoinstepfont : 'Finger Paint'}; 
color: ${memecoinsteptextcolor}; 
font-size: ${memestepfontSize}px;
word-break: Keep-all;
">
            ${template3stepsection2}
            </p>
          </div>
          <div class="dog-image">
            <img src="${template3stepimage.length > 0 ? template3stepimage : Template3DogImage}" />
          </div>
        </aside>
      </article>
    </section>
    </div>
    <!-- Fifth section End -->
    <!-- Footer section start -->
    <section 
     style="
background-image: ${memefootrbackground == 1 ? (memefooterbgimage.length !== 0 ? `url(${memefooterbgimage})` : '') : (memefooterbgcolor.length === 0 ? `url(${Template3BG})` : '')}; 
background-position: center; 
background-size: cover; 
background-repeat: no-repeat; 
background-color: ${memefootrbackground == 0 ? (memefooterbgcolor.length == 0 ? '' : memefooterbgcolor) : ''};
"
    >
      <article class="sixth-section">
        <aside >
          <button class="btn" style='background-color:${statsbackgroundcolorT3}; border: ${statsborder.length > 0 ? statsborder : '8'}px solid ${statsbordercolor != 'white' ? statsbordercolor : 'black'}; border-radius: ${statsborderradius.length > 0 ? statsborderradius : '10'}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};color:${memefootertextcolor};font-family:${memefootertextfont};'> ${template3footerheading}</button>
        </aside>
        <aside>
          <p class="footerpara" style='background-color:${statsbackgroundcolorT3}; border: ${statsborder.length > 0 ? statsborder : '8'}px solid ${statsbordercolor != 'white' ? statsbordercolor : 'black'}; border-radius: ${statsborderradius.length > 0 ? statsborderradius : '10'}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};color:${memefootertextcolor};font-family:${memefootertextfont};word-break: Keep-all;'>
          ${template3footerinfo}
          </p>
        </aside>
        <aside class="media">
          <div><button class="media-btn" style='color:${memefootertextcolor.length > 0 ? memefootertextcolor : 'white'};font-family:${memefootertextfont.length > 0 ? memefootertextfont : 'Finger Paint'};font-size:${memefooterfontSize}px;background-color:${statsbackgroundcolorT3}; border: ${statsborder.length > 0 ? statsborder : '8'}px solid ${statsbordercolor != 'white' ? statsbordercolor : 'black'}; border-radius: ${statsborderradius.length > 0 ? statsborderradius : '10'}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};cursor: pointer;' onclick="window.open('${template3twitterlink}')">${twittertextt4}</button></div>
          <div><button class="media-btn" style='color:${memefootertextcolor.length > 0 ? memefootertextcolor : 'white'};font-family:${memefootertextfont.length > 0 ? memefootertextfont : 'Finger Paint'};font-size:${memefooterfontSize}px;background-color:${statsbackgroundcolorT3}; border: ${statsborder.length > 0 ? statsborder : '8'}px solid ${statsbordercolor != 'white' ? statsbordercolor : 'black'}; border-radius: ${statsborderradius.length > 0 ? statsborderradius : '10'}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};cursor: pointer;' onclick="window.open('${template3telegramlink}')">${telegramtext}</button></div>
          <div><button class="media-btn" style='color:${memefootertextcolor.length > 0 ? memefootertextcolor : 'white'};font-family:${memefootertextfont.length > 0 ? memefootertextfont : 'Finger Paint'};font-size:${memefooterfontSize}px;background-color:${statsbackgroundcolorT3}; border: ${statsborder.length > 0 ? statsborder : '8'}px solid ${statsbordercolor != 'white' ? statsbordercolor : 'black'}; border-radius: ${statsborderradius.length > 0 ? statsborderradius : '10'}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};cursor: pointer;' onclick="window.open('${template3uniswaplink}')">${Dextoolstext}</button></div>
        </aside>
        <aside class="spurdo">
          <img  class="spurdo" src="${template1footerImage.length > 0 ? template1footerImage : Template3FooterSunppd}" alt="" />
        </aside>
        <div class="footer-text" style='background-color:${statsbackgroundcolorT3}; border: ${statsborder.length > 0 ? statsborder : '8'}px solid ${statsbordercolor != 'white' ? statsbordercolor : 'black'}; border-radius: ${statsborderradius.length > 0 ? statsborderradius : '10'}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};color:${memefootertextcolor};font-family:${memefootertextfont};'>
          ${template3memeaddress}
        </div>
      </article>
    </section>
    <!-- Footer section End -->
  </body>

  <script>
    let googletransalte = ${googletransalte};

    function toggleGoogleTranslate() {
        googletransalte = !googletransalte;
        document.getElementById('google_translate_element').style.display = googletransalte ? 'block' : 'none';
    }
</script>
      <script type="text/javascript">
        function googleTranslateElementInit() {
            new google.translate.TranslateElement(
                { pageLanguage: 'en' },
                'google_translate_element'  
            );
        }
    </script>
    <script
        type="text/javascript"
        src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    ></script>
</html>`

        const NewmemeTemplate5 = `
    <!DOCTYPE html>
    <html lang="en" >
     <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0"
    <head>
       <title>${memecoinname}</title>
    <style>
              





    .wrapper {
      width: 100%;
      overflow-x: hidden;
     position: relative;
  
    }
    
   
    .header {
      text-align: center;
      
      padding: 50px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      
    }
    
    .logo {
      width: 50%;
      height: 50%;
      margin-bottom: 20px;
    }
    
    .button {
      background-color: #f44336;
     
      padding: 10px 20px;
      border: none;
      cursor: pointer;
      font-size: 16px;
      margin-top: 20px;
      width: 150px;
      overflow: hidden;
     
    }
    
    .button:hover {
      background-color: #d32f2f;
    }
    
   
    .exchanges {
      text-align: center;
      background-color: #121212;
      padding: 50px 0;
      color: white;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
    
    .exchangesGrid {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      width: 65%;
      height: 100%;
      align-items: start;
    }
    
    .exchangeIcon {
      width: 250px;
      height: 100px;
      margin: 20px;
    }
    
    
    .howToBuy {
      text-align: center;
     
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
    .buySteps {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-items: center;
       width: 100%;
       height: 100%;
    }
    
    .buyStep {
      width: 23%;
      position: relative;
      background-color: #1d1d1d;
      height: 254px;
      text-align: left;
      font-size: 30px;
      color: #fff;
      font-family: 'Luckiest Guy';
      display: flex ;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      }
    
    
   
    .helmet {
      text-align: center;
      
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
    .helmetImage {
      width: 200px;
      margin-bottom: 20px;
    }
    
    
    .tokenomics {
      text-align: center;
      
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: white;
    }
      
    
   
    .socials {
      text-align: center;
      
     display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
    .socialIcons {
      display: flex;
      justify-content: center;
    }
    
    .socialIcon {
      width: 50px;
      margin: 10px;
    }
    
    
    @media (max-width: 768px) {
      .header, .exchanges, .howToBuy, .helmet, .tokenomics, .socials {
        padding: 30px 0;
      }
    
      .buySteps {
        flex-direction: column;
      }
    
      .buyStep {
        width: 100%;
      }
    
      .exchangesGrid {
        flex-direction: column;
      }
    
      .socialIcons {
        flex-direction: column;
      }
    }
  
  
    .poweredBySolana {
      width: 70%;
      position: relative;
      font-size: 20px;
      line-height: 28px;
      
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: fit-content;
     
      overflow: hidden;
  }

     
  
     #google_translate_element {
  width: 300px;
  float: right;
  text-align: right;
  display: block;
}
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
body {
  top: 0px !important;
}
#goog-gt-tt {
  display: none !important;
  top: 0px !important;
}
.goog-tooltip skiptranslate {
  display: none !important;
  top: 0px !important;
}
.activity-root {
  display: hide !important;
}
.status-message {
  display: hide !important;
}
.started-activity-container {
  display: hide !important;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none;
}

.VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q {
  display: none;
  background-color: unset !important;
  box-shadow: none !important;
}

.Icons{
position: relative;
 width: 70%;
  height: 100%; 
  display: flex; 
  justify-content: space-around;
 align-items: center;
}

.Helemtdiv{
 width: 100%;
  height: 100%; 
  position: relative;
   display: flex; 
   align-items: center;
    justify-content: center;
}
@media screen and (max-width: 725px) {
.Icons {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}

.Helemtdiv {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
}


.SectionTwo{
   display: flex; 
   justify-content: center;
     align-items: center; 
}
.HemeltText{
 font-size: 90px;
}
 .TokenText{
  font-size: 90px;
 }
  .TokenImageDIv{
 
  
  display: flex;
   align-items: center;
    justify-content: center;
 }

 .howtobuy{
  font-size: 86px;
 }
  .Socialtext{
   font-size: 90px;
  }

  .SocailIcon{
   
   display: flex; 
   align-items: center;
    justify-content: space-evenly;
   }

   .abouttext{
    width: 90%;
   }
    .TokenText1{
  font-size: 25px;
 }

 .logoT5{
     width:30%;
     height:30%;
   }

@media screen and (max-width: 725px) {
    .SectionTwo{
   display: flex; 
   justify-content:center; 
   align-items:center; 
   flex-direction: column;
}
   .HemeltText{
 font-size: 30px;
}
   .TokenText{
  font-size: 30px;
 }
  .TokenText1{
  font-size: 15px;
 }
 .TokenImageDIv{
  flex-direction: column;
  display: flex;
   align-items: center;
    justify-content: center;
 }
    .howtobuy{
  font-size: 30px;
 }
  .Socialtext{
   font-size: 30px;
  }
   .SocailIcon{
   
   display: flex; 
   align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
   }

   .abouttext{
    width: 90vw;
   }



}

@media screen and (max-width: 400px) {
   .TokenText1{
  font-size: 15px;
 }
}

@media screen and (max-width:428px){
   .logoT5{
     width:90%
   }
}





  
  
    

    </style>
    </head>
    <body>
   
           <div class='wrapper'>
                       <section class="header"
          style="background-color: ${memebackground == 0 ? memenavbarbgcolor.length == 0 ? '#f6b400' : memenavbarbgcolor : ''}; 
              background-image: ${memebackground == 1 ? memenavbgcolor.length !== 0 ? `url(${memenavbgcolor})` : '' : ''}; 
              height: 100%; width: 100%; object-fit: cover; background-size: cover;background-position: center;">
              <div style="position: absolute; right: 20px; top: 5px; cursor: pointer;">
  <div onclick="toggleGoogleTranslate()">EN</div>

  <div
      style="background-color: transparent; 
             padding: 0px 8px; 
             margin-right: 10px; 
             border-radius: 8px; 
             display: ${googletransalte == false ? 'none' : 'block'};"
      id="google_translate_element">
  </div>
</div>


  <img src="${memecoinlogoImage.length > 0 ? memecoinlogoImage : T5image1}" alt="Ponke Logo" class="logoT5" />
  
  <h1 class="poweredBySolana" 
      style="color: ${memenavtextcolor.length > 0 ? memenavtextcolor : '#000'}; 
             font-family: ${memecoinanvfont.length > 0 ? memecoinanvfont : 'Helvetica Neue'};">
      ${t5Nav1}
  </h1>
  
  <button 
  class="button" 
  style="
    color: ${memenavtextcolor.length > 0 ? memenavtextcolor : 'white'};
    font-family: ${memecoinanvfont.length > 0 ? memecoinanvfont : 'Helvetica Neue'};
    background-color: ${memebuybuttonbackground == 0 ? (memebuybuttonbackground.length == 0 ? '#f6b400' : buybuttonbgcolor) : ''};
    background-image: ${memebuybuttonbackground == 1 ? (buybuttnbgImage.length !== 0 ? `url(${buybuttnbgImage})` : '') : ''};
  " 
  onclick="window.open('${template2cmc}')"
>
  ${t5nav2} ${memecoinname}
</button>

</section>
          <div class="SectionTwo" style="position: relative; width: 100%; height: 100%; 
          background-color: ${memeherobackground == 0 ? memeherobgcolor.length == 0 ? '#f6b400' : memeherobgcolor : ''}; 
          background-image: ${memeherobackground == 1 ? memecoinaboutbgImage.length !== 0 ? `url(${memecoinaboutbgImage})` : '' : ''}; 
          background-repeat: no-repeat; background-size: cover; background-position: center;">
  
  <div style="position: relative; width: 50%; height: 100%; display: flex; justify-content: center; align-items: center;">
      <img src="${memecoinAminitionImage.length > 0 ? memecoinAminitionImage : T5image2}" alt="Ponke Logo" height="100%" width="90%">
  </div>
  
  <div style="position: relative; width: 90%; height: 100%;  ">
      <div class="abouttext" style=" 
          font-size: 28.8px; 
          color: ${memecoinabouttextcolor.length > 0 ? memecoinabouttextcolor : '#000'}; 
          display: flex; 
          align-items: center; 
          justify-content: center; 
          overflow: hidden; 
          word-wrap: break-word; 
          font-family: ${memecoinaboutfont}; 
          line-height: 1.5;">
  ${memecoinname} ${memecoinAbout}
</div>

      
      <div class='Icons'>
         
          <img src="${twitterimage.length > 0 ? twitterimage : T5image4}" alt="Ponke Logo" height="50px"  width="60px"  style="cursor: pointer;" onclick="window.open('${memetwitterlink}')">
          <img src="${telegraminage.length > 0 ? telegraminage : T5image5}" alt="Ponke Logo" height="50px"  width="60px"  style="cursor: pointer;" onclick="window.open('${memetelegramlink}')">
          <img src="${instagramimage.length > 0 ? instagramimage : T5image6}" alt="Ponke Logo" height="50px"  width="60px"  style="background-color:${instagramimage.length > 0 ? '' : '#000'} ; border-radius:${instagramimage.length > 0 ? '' : '50%'}; padding:${instagramimage.length > 0 ? '' : '8px'}; cursor: pointer;" onclick="window.open('${memeinstagramlink}')">
      </div>
  </div>
</div>
                 <section class="helmet" 
                  style="background-color: ${memeaboutbackground == 0 ? memeaboutbgcolor.length == 0 ? '#f6b400' : memeaboutbgcolor : ''}; 
              background-image: ${memeaboutbackground == 1 ? memeaboutbgimage.length !== 0 ? `url(${memeaboutbgimage})` : '' : ''}; 
              height: fit-content; width: 100%; object-fit: cover; background-size: cover;background-position: center;">
  
  <div>
      <h2 class="HemeltText" style="width: 100%; position: relative;  text-align: center; display: flex; align-items: center; 
                 justify-content: center; height: fit-content; overflow: hidden; 
                 font-family: ${memecoinvisionfont.length > 0 ? memecoinvisionfont : 'Helvetica Neue'}; 
                 color: ${memecoinvisiontextcolor.length > 0 ? memecoinvisiontextcolor : '#000'};">
          ${t5about1}
      </h2>
  </div>
  
  <div class='Helemtdiv'>
      <div style="width: 50%; height: 100%; position: relative; display: flex; align-items: center; justify-content: center; padding: 5%;">
          <img src="${memecoinavisionrightimage.length > 0 ? memecoinavisionrightimage : T5image18}" alt="T5image18" height="80%" width="350px">
      </div>
      
      <div style=" height: 100%; position: relative; display: flex; align-items: center; justify-content: center; flex-direction: column; padding-left: -5%;">
          <h3 style=" position: relative; font-size: 30px; text-align: center; display: flex; align-items: center; justify-content: center; 
                     height: fit-content; overflow: hidden;  
                     font-family: ${memecoinvisionfont.length > 0 ? memecoinvisionfont : 'Helvetica Neue'}; 
                     color: ${memecoinvisiontextcolor.length > 0 ? memecoinvisiontextcolor : '#000'};">
              ${t5about2}
          </h3>
          <img src="${buy1.length > 0 ? buy1 : T5image19}" alt="T5image19" width="300px" height="100px" style="cursor: pointer;" onclick="window.open('${template2cmc}')">
      </div>
  </div>
</section>
                      
                     <section class="tokenomics" 
       style="background-image: ${memetokenomicsbackground == 1 ? memecointokonomicsbgimage.length > 0 ? `url(${memecointokonomicsbgimage})` : '' : ''}; 
              width: 100%; height: fit-content; object-fit: cover; background-size: cover; background-position: center;
              background-color: ${memetokenomicsbackground == 0 ? memetokenomicsbgcolor.length == 0 ? '#121212' : memetokenomicsbgcolor : ''};">
  
  <div style="width: 100%; height: 100%; position: relative; display: flex; align-items: center; justify-content: center; flex-direction: column;">
      <h3 class="TokenText" style=" width: 90%; height: fit-content; display: flex; align-items: center; justify-content: center; 
                 overflow: hidden; text-align: center; position: relative; 
                 font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Helvetica Neue'}; 
                 color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : '#f6b400'};">
          ${t5Token1}
      </h3>
      
      <p style="font-size: 30px; width: 90%; height: fit-content;  display: flex; align-items: center; justify-content: center; 
                overflow: hidden; text-align: center; position: relative; 
                font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Helvetica Neue'}; 
                color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : '#FFFFFF'};">
        ${'$' + memecoinname} ${t5Token3}
      </p>
      
      <p  style="font-size: 30px; width: 90%; height: fit-content;  display: flex; align-items: center; justify-content: center; 
                overflow: hidden; text-align: center; position: relative; 
                font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Helvetica Neue'}; 
                color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : '#FFFFFF'};">
          ${t5Token4}
      </p>
  </div>
  
  <div class="TokenImageDIv"style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
      <div style="width: 50%; height: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center; padding: 5%; gap: 10px;">
          <img src="${memecointokenomicsimage.length > 0 ? memecointokenomicsimage : T5image20}" height="90%" width="90%">
          
      </div>
      
      <div style="width: 50%; height: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column; gap: 10px;">
          <img src="${newiconsImage.length > 0 ? newiconsImage : T5image22}" width="40%" height="20%">
          
          <div class="TokenText1"style="background-color: #f6b400; width: 90%; border: 4px solid #000; height: 100%; 
                      overflow: hidden; display: flex; align-items: center; flex-direction: column; justify-content: center; 
                       text-align: center; position: relative; padding: 10px; 
                      font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Helvetica Neue'}; 
                      color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : '#000'};
                      background:${t4statsbackgrounsd.length ? t4statsbackgrounsd : '#f6b400'}; border: ${statsborder}px solid ${statsbordercolor}; border-radius: ${statsborderradius}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21 ` : ` 0px 19px 30px -10px rgba(21, 21, 25, 0.21)`};
                      ">
              <p>${t1token}</p>
              <p >${t5Token5}${memecoinname}</p>
          </div>
          
          <div class="TokenText1" style="background-color: #f6b400; width: 90%; border: 4px solid #000; height: 100%;
                      overflow: hidden; display: flex; align-items: center; flex-direction: column; justify-content: center; 
                      text-align: center; position: relative; padding: 10px; 
                      font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Helvetica Neue'}; 
                      color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : '#000'};
                      background:${t4statsbackgrounsd.length ? t4statsbackgrounsd : '#f6b400'}; border: ${statsborder}px solid ${statsbordercolor}; border-radius: ${statsborderradius}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21 ` : ` 0px 19px 30px -10px rgba(21, 21, 25, 0.21)`};

                      ">
              <p>${t1token1}</p>
              <p style="line-break: anywhere;">${t5Token6}</p>
          </div>
      </div>
  </div>
</section>
                    <section class="howToBuy" 
       style="background-image: ${memestepbackground == 1 ? `url(${memestepbgimage})` : ''}; 
              height: 100%; width: 100%; background-size: cover; object-fit: cover; 
              background-color: ${memestepbackground == 0 ? memestepbgcolor.length == 0 ? '#f6b400' : memestepbgcolor : ''};background-position: center;">
  
  <h2 class="howtobuy" style="width: 70%; position: relative;  text-align: center; display: flex; align-items: center; justify-content: center; 
             height: fit-content; overflow: hidden; word-break: break-word; 
             font-family: ${memecoinstepfont.length > 0 ? memecoinstepfont : 'Helvetica Neue'}; 
             color: ${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#000'};">
      ${stepheading}
  </h2>
  
  <span style=" position: relative; font-size: 30px; text-align: center; display: flex; align-items: center; justify-content: center; 
               height: fit-content; overflow: hidden;  word-break: break-word; 
               font-family: ${memecoinstepfont.length > 0 ? memecoinstepfont : 'Helvetica Neue'}; 
               color: ${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#000'}; padding:30px">
        ${t1stepheading} ${memecoinname} 
  </span>
  
  <div class="buySteps">
      
      <div class="buyStep" 
       style="background: ${t4statsbackgrounsd.length ? t4statsbackgrounsd : '#1d1d1d'};
    #1d1d1d); 
    border: ${statsborder}px solid ${statsbordercolor};
    border-radius: ${statsborderradius}px;
    box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21)` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};
  "
      >
          <h3 style="width: 100%; position: relative; font-size: 15px;  display: flex; align-items: center;  
                      overflow: hidden;  height:50%; text-transform: uppercase;text-align: center;justify-content: center;
                     font-family: ${memecoinstepfont.length > 0 ? memecoinstepfont : 'Helvetica Neue'}; 
                     color: ${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff'};">
              ${step1heading}
          </h3>
          <p style="width: 95%; position: relative; font-size: 14px;  display: flex; align-items: center;  
                     overflow: hidden; padding: 10px; height:50%;text-align: center;justify-content: center;
                    font-family: ${memecoinstepfont.length > 0 ? memecoinstepfont : 'Helvetica Neue'}; 
                    color: ${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : 'rgba(255, 255, 255, 0.8)'};">
              ${t5step1}
          </p>
      </div>
      
      <div class="buyStep" 
              style="background: ${t4statsbackgrounsd.length ? t4statsbackgrounsd : '#1d1d1d'}; 
    border: ${statsborder}px solid ${statsbordercolor};
    border-radius: ${statsborderradius}px;
    box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21)` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};
  "
      >
          <h3 style="width: 100%; position: relative; font-size: 15px;  display: flex; align-items: center;
                      overflow: hidden;  height:50%;text-transform: uppercase;text-align: center;justify-content: center;
                     font-family: ${memecoinstepfont.length > 0 ? memecoinstepfont : 'Helvetica Neue'}; 
                     color: ${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff'};">
                     ${step2heading}
          </h3>
          <p style="width: 95%; position: relative; font-size: 14px;  display: flex; align-items: center;  
                     padding: 10px; overflow: hidden; height:50%;text-align: center;justify-content: center;
                    font-family: ${memecoinstepfont.length > 0 ? memecoinstepfont : 'Helvetica Neue'}; 
                    color: ${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : 'rgba(255, 255, 255, 0.8)'}; ">
              ${t5step2}
          </p>
      </div>
      
      <div class="buyStep"
        style="background: ${t4statsbackgrounsd.length ? t4statsbackgrounsd : '#1d1d1d'}; 
    border: ${statsborder}px solid ${statsbordercolor};
    border-radius: ${statsborderradius}px;
    box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21)` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};
  "
      >
          <h3 style="width: 100%; position: relative; font-size: 15px;  display: flex; align-items: center; 
                      overflow: hidden; height:50%;text-transform: uppercase;text-align: center;justify-content: center;
                     font-family: ${memecoinstepfont.length > 0 ? memecoinstepfont : 'Helvetica Neue'}; 
                     color: ${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff'};padding: 30px">
             ${step3heading} ${memecoinname}
          </h3>
          <p style="width: 95%; position: relative; font-size: 14px;  display: flex; align-items: center; 
                    padding: 10px; overflow: hidden; height:50%; text-align: center;justify-content: center;
                    font-family: ${memecoinstepfont.length > 0 ? memecoinstepfont : 'Helvetica Neue'}; 
                    color: ${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : 'rgba(255, 255, 255, 0.8)'};">
              ${t5step3}${memecoinname}
          </p>
      </div>
      
      <div class="buyStep"
        style="background: ${t4statsbackgrounsd.length ? t4statsbackgrounsd : '#1d1d1d'};
    #1d1d1d); 
    border: ${statsborder}px solid ${statsbordercolor};
    border-radius: ${statsborderradius}px;
    box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21)` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};
  "
      >
          <h3 style="width: 100%; position: relative; font-size: 15px;  display: flex; align-items: center; 
                      overflow: hidden; height:50%;text-transform: uppercase;text-align: center;justify-content: center;
                     font-family: ${memecoinstepfont.length > 0 ? memecoinstepfont : 'Helvetica Neue'}; 
                     color: ${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff'};">
                     ${step4heading}${memecoinname}
          </h3>
          <p style="width: 95%; position: relative; font-size: 14px;  display: flex; align-items: center; 
                     padding: 10px; overflow: hidden; height:50%;text-align: center;justify-content: center;
                    font-family: ${memecoinstepfont.length > 0 ? memecoinstepfont : 'Helvetica Neue'}; 
                    color: ${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : 'rgba(255, 255, 255, 0.8)'};">
              ${t5step4}${memecoinname}
          </p>
      </div>
      
  </div>
</section>

                  <section class="socials" 
       style="background-image: ${memefootrbackground == 1 ? memefooterbgimage.length !== 0 ? `url(${memefooterbgimage})` : '' : ''}; 
              object-fit: cover; background-size: cover;background-position: center; 
              background-color: ${memefootrbackground == 0 ? memefooterbgcolor.length == 0 ? '#f6b400' : memefooterbgcolor : ''};">
  
  <div style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column;">
      <h3 class="Socialtext" style=" font-family: 'Helvetica Neue';  height: fit-content;  overflow: hidden;justify-content: center;
      font-family: ${memefootertextfont.length > 0 ? memefootertextfont : 'Helvetica Neue'}; 
                     color: ${memefootertextcolor.length > 0 ? memefootertextcolor : '#000'};">
      
          ${t1social}
      </h3>
      <p style=" font-family: 'Helvetica Neue'; font-size: 30px; height: fit-content;  overflow: hidden;
        font-family: ${memefootertextfont.length > 0 ? memefootertextfont : 'Helvetica Neue'}; 
                     color: ${memefootertextcolor.length > 0 ? memefootertextcolor : '#000'};justify-content: center;
      ">
      ${t1social1}${memecoinname}
      </p>
  </div>
  
  <div  class="SocailIcon"style="width: 50%; height: 100%; ">
      <img src="${twitterimage.length > 0 ? twitterimage : T5image4}" alt="Ponke Logo" height="50px" style="cursor: pointer;" onclick="window.open('${memetwitterlink}')">
      <img src="${telegraminage.length > 0 ? telegraminage : T5image5}" alt="Ponke Logo" height="50px" style="cursor: pointer;" onclick="window.open('${memetelegramlink}')">
      <img src="${instagramimage.length > 0 ? instagramimage : T5image6}" alt="Ponke Logo" height="50px" style="background-color:${instagramimage.length > 0 ? '' : '#000'} ; border-radius:${instagramimage.length > 0 ? '' : '50%'}; padding: 8px; cursor: pointer;" onclick="window.open('${memeinstagramlink}')">
  </div>
  
  <div style="display: flex; width: 100%; align-items: center; justify-content: space-around; gap: 30px; padding: 2%;">
      <div style="display: flex; width: 50%; align-items: center; justify-content: center; height: 100%;">
          <img src="${memecoinAminitionImage.length > 0 ? memecoinAminitionImage : T5image2}" height="90%" width="70%">
      </div>
      <div style="display: flex; width: 50%; align-items: center; justify-content: center; height: 100%; flex-direction: column;">
          
      </div>
  </div>

</section>



           </div>

              
             
            
        
              
    </body>
       <script>
  let googletransalte = ${googletransalte};

  function toggleGoogleTranslate() {
      googletransalte = !googletransalte;
      document.getElementById('google_translate_element').style.display = googletransalte ? 'block' : 'none';
  }
</script>
    <script type="text/javascript">
      function googleTranslateElementInit() {
          new google.translate.TranslateElement(
              { pageLanguage: 'en' },
              'google_translate_element'  
          );
      }
  </script>
  <script
      type="text/javascript"
      src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  ></script>
    </html>
    `

        const NewmemeTemplate6 = `
      <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>${memecoinname}</title>
    <style>
    @import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

section {
  width: 100%;
  height: auto;
}

section .main-article,
section .doland-tremp-parent,
section .How-to-buystart,
section .footer-article {
  display: flex;
}

/* <!-- Navbar-start --> */
section nav {
  width: inherit;
  height: 104px;
  padding: 0.5em 1em;
  display: flex;
  align-items: center;
  border-bottom: 3px solid #d93731;
}

section nav .logo img {
  display: block;
  height: inherit;
  width: 11.25em;
  margin-left: 2em;
  height: 5em;
}

nav .links {
  width: 100%;
  padding: 0em 1em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

nav .links ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
}

.links ul li {
  margin: 0em 0.6em;
}

.links ul li a {
  text-decoration: none;
  font-size: 1em;
  letter-spacing: -0.21px;
  line-height: 1.5em;
  text-transform: uppercase;
  font-weight: 800;
  font-family: Inter;
  color: #254a9a;
  padding: 0.5em 1em;
}

.buy-permit {
  letter-spacing: -0.18px;
  text-transform: uppercase;
  font-size: 1em;
  padding: 0.8em 3em;
  border-radius: 0.5em;
  border: transparent;
  background-color: #d93731;
  color: #fff;
  font-family: "Inter", sans-serif;
}

/* <!-- Navbar-End --> */

/* <!-- Main-section-start --> */
.main-section {
  background-repeat: no-repeat;
  background-size: cover;
}
.common-article {
  margin: 0em 7em;
  height: auto;
}

section article .main-left-side {
  width: 70%;
}

article .main-left-side h1 {
  margin-top: 1.2em;
  width: 70%;
  text-transform: uppercase;
  font-weight: 800;
  font-family: Inter;
  color: #fff;
  font-size: 4em;
  letter-spacing: -1.16px;
  line-height: 107.11px;
}

article .main-left-side p {
  width: 100%;
  word-break: break-all;
  margin-top: 1em;
  font-size: 1em;
  letter-spacing: -0.19px;
  line-height: 15.7px;
  font-family: Inter;
  color: #fff;
}

article .main-left-side .buy-permit-Parent {
  display: flex;
  flex-wrap: wrap;
  gap: 0em 1em;
  margin: 2em 0em 2em 0em;
}

.buy-pc {
  padding: 1em 2em;
  font-size: 1em;
  letter-spacing: -0.48px;
  text-transform: uppercase;
}

#PREMT-ON-X {
  background-color: #254a9a;
}

section article .main-right-side {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* <!-- Main-section-End --> */
.main-section-last {
  width: 100%;
  height: 4.8em;
  background-color: #d93731;
}

/* <!-- doland tremp start --> */
.doland-tremp-left,
.doland-tremp-right,
.how-to-buystart-left,
.how-to-buystart-right {
  width: 50%;
  height: auto;
  margin: 5em 0em 5em 0em;
}

.doland-tremp-left h1 {
  font-size: 2em;
  letter-spacing: -0.68px;
  line-height: 71.64px;
  text-transform: uppercase;
  font-weight: 800;
  font-family: Inter;
  color: #254a9a;
}

.doland-tremp-left p {
  font-size: 1.15em;
  letter-spacing: -0.19px;
  line-height: 29.18px;
  width: 70%;
}

.doland-tremp-left .premt-is-a {
  font-size: 1.15em;
  letter-spacing: -0.19px;
  line-height: 29.18px;
}

.doland-tremp-left .social-media,
.footer-right .social-media {
  display: flex;
  flex-wrap: wrap;
  gap: 0em 2em;
  margin-top: 1em;
}

.doland-tremp-right img {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
}

/* <!-- doland tremp End --> */
/* <!-- How to buystart Start--> */

.how-to-buystart-left {
  width: 60%;
}

.how-to-buystart-left,
.how-to-buystart-right {
  margin-bottom: 0em;
}

.how-to-buystart-left h1 {
  font-size: 5em;
  letter-spacing: -1.16px;
  line-height: 107.11px;
  text-transform: uppercase;
  font-weight: 800;
}

.last-three {
  margin-top: 1em;
}

.buystart-right-child h1 {
  font-size: 1.1em;
  letter-spacing: -0.25px;
  line-height: 28.98px;
  text-transform: uppercase;
  font-weight: 500;
}

.buystart-right-child p {
  width: 90%;
  font-size: 14.3px;
  letter-spacing: -0.19px;
  line-height: 21.41px;
  margin-top: 1em;
}

.how-to-buystart-left img {
  margin: 3em 0em 0em 2.4em;
  width: 400px;
  height: 400px;
  margin-bottom:40px;
}

/* <!-- How to buystart End--> */
/* <!-- MEMES Start--> */
.memes,
.in-the-news {
  padding: 0em 10em;
  height: auto;
}

.memes h1 {
  
  text-align: center;
  font-size: 3.1em;
  letter-spacing: -0.68px;
  line-height: 71.64px;
  text-transform: uppercase;
  font-weight: 800;
}

.memes a {
  display: block;
  text-align: center;
  font-size: 1em;
  text-decoration: underline;
  letter-spacing: -0.19px;
  line-height: 21.41px;
}

.memes img {
  margin: 1em 0em 2em 0em;
  width: 100%;
}

/* MEMES END */

/* In-the-news Start */

.in-the-news h1 {
  padding-top: 1em;
  text-align: center;
  font-size: 3em;
  letter-spacing: -0.68px;
  line-height: 71.64px;
  text-transform: uppercase;
  font-weight: 600;
}

.in-the-news aside {
  display: flex;
  padding-bottom: 2em;
  margin-top: 2em;
  gap: 0em 2em;
}

.in-the-news aside .news-content {
  text-align: center;
  font-size: 1em;
  letter-spacing: -0.19px;
  line-height: 140%;
}
/* <!-- Footere Start--> */

/* <!-- Footere End--> */

.footer {
  padding-bottom: 2em;
}
.footer-article {
  padding: 2em 0em 2em 0em;
}
.footer-left .logo img {
  height: 7em;
  width: 15em;
}
.footer-article .footer-right {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.footer-article .footer-right .social-media {
  justify-content: flex-end;
  align-items: start;
  margin: 0em;
}

.social-icon img {
  height: 4em;
  width: 4em;
}
.footer-right p {
  margin: 1em 2em 0em 0em;
  font-size: 14.3px;
  letter-spacing: -0.19px;
  line-height: 21.41px;
  text-align: right;
}
.footer .All-rights-reserved {
  margin-top: 2em;
  font-size: 0.7em;
  letter-spacing: -0.19px;
  line-height: 15px;
  text-align: left;
  text-align: center;
}
/* Tablet view: Adjust padding and font sizes */
@media (max-width: 966px) {
  section nav {
    display: flex;
    justify-content: center;
    height: auto;
    padding: 1em;
    flex-wrap: wrap;
  }

  section nav .logo img {
    width: 9em;
    margin-left: 1em;
  }

  nav .links {
    padding: 1em 0;
    justify-content: center;
  }

  nav .links ul {
    flex-wrap: wrap;
    justify-content: center;
  }

  .links ul li {
    margin: 0.5em 0;
  }

  .buy-permit {
    padding: 0.5em 2em;
  }
}

@media (max-width: 768px) {
  article .main-left-side h1 {
    width: 100%;
    text-align: center;
    font-size: 2rem;
  }
  section article .main-right-side {
    width: 100%;
  }
  section article .main-right-side img {
    width: 100%;
  }
  article .main-left-side .buy-permit-Parent {
    justify-content: center;
    gap: 1em;
  }
  section .main-article,
  section .doland-tremp-parent,
  section .How-to-buystart,
  section .footer-article {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .common-article {
    margin: 0em 3em;
  }
  .how-to-buystart-left h1 {
    text-align: center;
  }
  .how-to-buystart-right {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .doland-tremp-left .social-media{
    justify-content: center;
  }
  .how-to-buystart-left img {
    margin: 0;
    width: 100%;
  }
  .doland-tremp-left p,
  section article .main-left-side,
  .doland-tremp-left,
  .doland-tremp-right,
  .how-to-buystart-left {
    width: 100%;
    font-size: 0.8rem;
  }
  .doland-tremp-right {
    margin: 0em 0em 3em 0em;
  }
  article .main-left-side p {
    text-align: center;
  }
  .in-the-news,
  .memes {
    padding: 0em 2em;
    font-size: 0.8rem;
  }
  .in-the-news aside {
    flex-direction: column;

    gap: 20px;
  }
  .last-three {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .footer .All-rights-reserved {
    padding: 1.5em;
  }
  .in-the-news aside .news-content {
    text-align: start;
  }

  .in-the-news aside .news-content {
    width: 100%;
  }
  .footer-article .footer-right .social-media {
    justify-content: center;
  }
  .footer-right p {
    text-align: center;
  }
  .links ul {
    display: none;
  }
}
  #google_translate_element {
    width: 300px;
    float: right;
    text-align: right;
    display: block;
}
.goog-te-banner-frame.skiptranslate {
    display: none !important;
}
body {
    top: 0px !important;
}
#goog-gt-tt {
    display: none !important;
    top: 0px !important;
}
.goog-tooltip skiptranslate {
    display: none !important;
    top: 0px !important;
}
.activity-root {
    display: hide !important;
}
.status-message {
    display: hide !important;
}
.started-activity-container {
    display: hide !important;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
    display: none;
}

.VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q {
    display: none;
    background-color: unset !important;
    box-shadow: none !important;
}



    </style>

  </head>

  <body>
    
    <!-- Navbar-start -->
    <!-- background-color,bg-img, -->
    <section style="position: relative; background-image: ${memebackground == 0 ? `${memenavbgcolor.length > 0 ? `url(${memenavbgcolor})` : ``}` : ''}; object-fit: cover; background-size: cover;background-color: ${memebackground == 1 ? `${memenavbarbgcolor}` : ''};background-repeat: no-repeat;background-position: center;">
     <div style=" right: 20px; top: 5px; cursor: pointer;color:white">
    <div ></div>

    <div
        style="background-color: transparent; 
               padding: 0px 8px; 
               margin-right: 10px; 
               border-radius: 8px; 
               display: ${googletransalte == false ? 'none' : 'block'};"
        id="google_translate_element"><span onclick="toggleGoogleTranslate()">EN<span>
    </div>
</div>
      <nav >
              
        <aside class="logo">
          <img src="${memecoinlogoImage.length > 0 ? memecoinlogoImage : T4Navlogo}"alt="logo" />
        </aside>
        <aside class="links">
          <ul>
            <li><a href="" style="font-family: '${memecoinanvfont}'; color: ${memenavtextcolor}; font-size: '${memenavfontsize}px';overflow-wrap: anywhere;">${Navbar[0]}</a></li>
            <li><a href="" style="font-family: '${memecoinanvfont}'; color: ${memenavtextcolor}; font-size: '${memenavfontsize}px';overflow-wrap: anywhere;">${Navbar[1]}</a></li>
            <li><a href="" style="font-family: '${memecoinanvfont}'; color: ${memenavtextcolor}; font-size: '${memenavfontsize}px';overflow-wrap: anywhere;">${Navbar[2]}</a></li>
            <li><a href="" style="font-family: '${memecoinanvfont}'; color: ${memenavtextcolor}; font-size: '${memenavfontsize}px';overflow-wrap: anywhere;">${Navbar[3]}</a></li>
            <button onclick="window.open('${template2cmc}')"  class="buy-permit" style="font-family: '${memecoinanvfont}'; color: ${memenavtextcolor}; font-size: '${memenavfontsize}px';cursor: pointer;  background-color:  ${t4statsbackgrounsd.length > 0 ? t4statsbackgrounsd : '#d93731'}; 
       border: ${statsborder}px solid ${statsbordercolor};
       border-radius:${statsborderradius.length > 0 ? `${statsborderradius}px` : '0.5rem'};
         box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21)` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};
 ">
             ${t4navbutton} ${memecoinsymbol}
            </button>
          </ul>
        </aside>
      </nav>
    </section>
    <!-- Navbar-End -->

    <!-- Main-section-start -->
    <section
      class="main-section"
      style="background-image: url('${memecoinaboutbgImage.length > 0 ? memecoinaboutbgImage : T4MainSectionImage}');background-repeat: no-repeat;background-size: cover;background-position: center;"
    >
      <main style="background-image:${memeherobackground == 0 ? `${memecoinaboutbgImage.length > 0 ? `url(${memecoinaboutbgImage})` : ``}` : ""};background-color:${memeherobackground == 1 ? `${memeherobgcolor}` : ""} ;background-repeat: no-repeat;background-size: cover;background-position: center;">
        <article class="common-article main-article">
          <aside class="main-left-side">
            <h1  style="color: ${memecoinabouttextcolor};font-family: ${memecoinaboutfont};font-size: ${memeMainfontsize}px;">${t4caption}</h1>
            <p style="color: ${memecoinabouttextcolor};font-family: ${memecoinaboutfont};font-size: ${memeMainfontsize}px;">${t4memeaddress}</p>
            <div class="buy-permit-Parent">
              <button class="buy-permit buy-pc" style="color: ${memecoinabouttextcolor};font-family: ${memecoinaboutfont};font-size: ${memeMainfontsize}px;cursor:pointer;
                 background-color:  ${t4statsbackgrounsd.length > 0 ? t4statsbackgrounsd : '#d93731'}; 
       border: ${statsborder}px solid ${statsbordercolor};
       border-radius:${statsborderradius.length > 0 ? `${statsborderradius}px` : '0.5rem'};
         box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21)` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};
              " onclick="window.open('${template2cmc}')" >${t4mainbutton1} ${memecoinsymbol}</button>
              <button class="buy-permit buy-pc" id="PREMT-ON-X" style="color: ${memecoinabouttextcolor};font-family: ${memecoinaboutfont};font-size: ${memeMainfontsize}px;cursor:pointer;
                background-color:  ${t4statsbackgrounsd.length > 0 ? t4statsbackgrounsd : '#254a9a'}; 
       border: ${statsborder}px solid ${statsbordercolor};
       border-radius:${statsborderradius.length > 0 ? `${statsborderradius}px` : '0.5rem'};
         box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21)` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};
             " onclick="window.open('${memetwitterlink}')">
                ${memecoinsymbol} ${t4mainbutton2}
              </button>
            </div>
          </aside>
          <aside class="main-right-side">
            <img src="${memecoinAminitionImage.length > 0 ? memecoinAminitionImage : T4Memelogo}" alt="" height="400px" width="400px" />
          </aside>
        </article>
      </main>
    </section>
    <!-- Main-section-End -->

    

    <!-- doland tremp start -->
    <section class="common-section"
      style="background-color: ${memeaboutbackground == 0 ? (memeaboutbgcolor.length == 0 ? 'white' : memeaboutbgcolor) : ''};
      background-image: ${memeaboutbackground == 1 ? (memeaboutbgimage.length !== 0 ? `url(${memeaboutbgimage})` : '') : ''};
      background-repeat: no-repeat;background-size: cover;background-position: center"
     >
      <article class="common-article doland-tremp-parent">
        <aside class="doland-tremp-left">
          <h1 style="font-family: Inter;  color: ${memecoinvisiontextcolor.length > 0 ? memecoinvisiontextcolor : '#254a9a'};font-family: ${memecoinvisionfont};">${memecoinname}</h1>
          <p style="font-family: Inter; color:${memecoinvisiontextcolor.length > 0 ? memecoinvisiontextcolor : '#0b0f3a'};font-family: ${memecoinvisionfont};">
            ${memecoinAbout}
          </p>
          <p class="premt-is-a" style="font-family: Inter; color: ${memecoinvisiontextcolor.length > 0 ? memecoinvisiontextcolor : '#0b0f3a'};font-family: ${memecoinvisionfont};">
            ${memecoinsymbol}
          </p>
          <div class="social-media">
            <div class="social-icon">
              <a href=""><img src="${twitterimage.length > 0 ? twitterimage : T4twitter}" style="cursor: pointer;" onclick="window.open('${memetwitterlink}')"  alt="" /></a>
            </div>
            <div class="social-icon">
              <a href=""><img  src="${telegraminage.length > 0 ? telegraminage : T4telegram}" style="cursor: pointer;" onclick="window.open('${memetelegramlink}')"  alt="" /></a>
            </div>
            <div class="social-icon">
              <a href=""><img src="${instagramimage.length > 0 ? instagramimage : T4Instagram}" style="cursor: pointer;" onclick="window.open('${memeinstagramlink}')" alt="" /></a>
            </div>
            <div class="social-icon">
              <a href=""><img src="${warpcastimage.length > 0 ? warpcastimage : T4trend}" style="cursor: pointer;" onclick="window.open('${memewarpcastlink}')" alt="" /></a>
            </div>
          </div>
        </aside>
        <aside class="doland-tremp-right">
          <img src="${memecoinavisionleftimage.length > 0 ? memecoinavisionleftimage : T4MemeImage1}"  alt="" />
        </aside>
      </article>
    </section>
    <!-- doland tremp End -->

    <!-- How to buystart Start-->
    <section style="
      background-image: ${memestepbackground == 1 ? `url(${memestepbgimage})` : ''}; 
              height: 100%; width: 100%; background-size: cover; object-fit: cover; 
              background-color: ${memestepbackground == 0 ? memestepbgcolor.length == 0 ? '#254a9a' : memestepbgcolor : ''};background-position: center;background-repeat: no-repeat;
    ">
      <article class="common-article How-to-buystart">
        <aside class="how-to-buystart-left">
          <h1 style=" font-family:${memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter'};color:${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff'}; overflow-wrap: anywhere;"> ${t4step1}</h1>
          <img src="${t4stepimage.length > 0 ? t4stepimage : T4MemeImage2}" alt="" />
        </aside>
        <aside class="how-to-buystart-right">
          <div class="buystart-right-child">
            <h1 style=" font-family:${memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter'};color:${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff'}; overflow-wrap: anywhere;">${t4stepheading1}</h1>
            <p style=" font-family:${memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter'};color:${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff'}; overflow-wrap: anywhere;">
              ${t4step2}
            </p>
          </div>
          <div class="buystart-right-child last-three">
            <h1 style=" font-family:${memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter'};color:${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff'}; overflow-wrap: anywhere;">${t4stepheading2}</h1>
            <p style=" font-family:${memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter'};color:${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff'}; overflow-wrap: anywhere;">
              ${t4step3}
            </p>
          </div>

          <div class="buystart-right-child last-three">
            <h1 style=" font-family:${memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter'};color:${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff'}; overflow-wrap: anywhere;">${t4stepheading3}</h1>
            <p style=" font-family:${memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter'};color:${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff'}; overflow-wrap: anywhere;">
              ${t4step4}
            </p>
          </div>
          <div class="buystart-right-child last-three">
            <h1 style=" font-family:${memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter'};color:${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff'}; overflow-wrap: anywhere;">${t4stepheading4}</h1>
            <p style=" font-family:${memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter'};color:${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff'}; overflow-wrap: anywhere;">
              ${t4step5}
            </p>
          </div>
        </aside>
      </article>
    </section>
    <!-- How to buystart End-->

    <!-- MEMES Start-->
    <section>
      <article class="memes" style="background-image:${memetokenomicsbackground == 0 ? `${memecointokonomicsbgimage.length > 0 ? `url(${memecointokonomicsbgimage})` : ''}` : ''}; background-size: cover;background-color:${memetokenomicsbackground == 1 ? `${memetokenomicsbgcolor}` : ''};background-position: center;background-repeat: no-repeat;">
        <h1 style="font-family: ${memecointokenfont.length > 0 ? `${memecointokenfont}` : "Inter"}; color: ${memecointoketextcolor != 'white' ? memecointoketextcolor : '#0B0F3A'};">${t4token}</h1>
        <img  src="${memecointokenomicsimage.length > 0 ? memecointokenomicsimage : T4MemeImage3}"alt="" />
      </article>
    </section>
    <!-- MEMES End-->

    <!-- In the News Start-->

    <!-- In the News End-->

    <!-- Footere Start-->

    <section class="footer" style="
   
     background-image:${memefootrbackground == 1 ? `${memefooterbgimage.length !== 0 ? `url(${memefooterbgimage})` : ''}` : ''};backgorund-position:center;background-size:cover;background-color: ${memefootrbackground == 0 ? `${memefooterbgcolor.length == 0 ? '#254a9a' : memefooterbgcolor}` : ''};background-repeat: no-repeat;
    ">
      <article
        class="common-article footer-article"
        style="border-bottom: 1px solid #fff"
      >
        <aside class="footer-left">
          <div class="logo">
            <img src="${memecoinlogoImage.length > 0 ? memecoinlogoImage : T4Navlogo}" alt="" />
          </div>
        </aside>
        <aside class="footer-right">
          <div class="social-media">
            <div class="social-icon">
              <a href=""><img src="${telegraminage.length > 0 ? telegraminage : T4footertelegram}" style="cursor:pointer"  onclick="window.open('${memetwitterlink}')" alt="" /></a>
            </div>
            <div class="social-icon">
              <a href=""><img src="${twitterimage.length > 0 ? twitterimage : T4footerTwitter}" style="cursor:pointer" onclick="window.open('${memetelegramlink}')" alt="" /></a>
            </div>
            <div class="social-icon">
              <a href=""><img src="${instagramimage.length > 0 ? instagramimage : T4footerInstagram}" style="cursor:pointer" onclick="window.open('${memeinstagramlink}')" alt="" /></a>
            </div>
            <div class="social-icon"> 
              <a href=""><img src="${warpcastimage.length > 0 ? warpcastimage : T4footertrend}"  style="cursor:pointer"  onclick="window.open('${memewarpcastlink}')"alt="" /></a>
            </div>
          </div>
          <p style="color:${memefootertextcolor.length > 0 ? `${memefootertextcolor}` : "white"}; font-family:${memefootertextfont}">
          ${t4footer3}
          </p>
        </aside>
      </article>
      <p class="All-rights-reserved" style="color:${memefootertextcolor.length > 0 ? `${memefootertextcolor}` : "white"}; font-family:${memefootertextfont}">
        ${t4footer2} <br />
        <span>${t4footer3}</span>
      </p>
    </section>

    <!-- Footere End-->
  </body>
  <script>
    let googletransalte = ${googletransalte};

    function toggleGoogleTranslate() {
        googletransalte = !googletransalte;
        document.getElementById('google_translate_element').style.display = googletransalte ? 'block' : 'none';
    }
</script>
      <script type="text/javascript">
        function googleTranslateElementInit() {
            new google.translate.TranslateElement(
                { pageLanguage: 'en' },
                'google_translate_element'  
            );
        }
    </script>
    <script
        type="text/javascript"
        src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    ></script>
</html>`
        console.log("randomId", randomId)

        var htmlContent1 = NavbarTemplate + HeroTemplate + TrustTemplate + ServiceTemplate + StatsTemplate + FaqTemplate + FooterTemplate;
        // var htmlContent2 = selectMemeCoinTemplates==1?MemeCoin:selectMemeCoinTemplates==1?NewmemeTemplate1:selectMemeCoinTemplates==2?NewmemeTemplate2
        // var htmlContent2 = selectMemeCoinTemplates === 1
        //     ? MemeCoin
        //     : selectMemeCoinTemplates === 2
        //         ? NewmemeTemplate1
        //         : selectMemeCoinTemplates === 4 ? NewmemeTemplate3 : NewmemeTemplate2;

        // var htmlContent2 = selectMemeCoinTemplates === 1
        //     ? MemeCoin
        //     : selectMemeCoinTemplates === 2
        //         ? NewmemeTemplate1
        //         : selectMemeCoinTemplates === 4
        //             ? NewmemeTemplate3
        //             : selectMemeCoinTemplates === 6
        //                 ? NewmemeTemplate6
        //                 : NewmemeTemplate2;


        var htmlContent2 = selectMemeCoinTemplates === 1
            ? MemeCoin
            : selectMemeCoinTemplates === 2
                ? NewmemeTemplate1
                : selectMemeCoinTemplates === 4
                    ? NewmemeTemplate3
                    : selectMemeCoinTemplates === 5
                        ? NewmemeTemplate5
                        : selectMemeCoinTemplates === 6
                            ? NewmemeTemplate6
                            : NewmemeTemplate2;


        if (memecoin == 'false') {
            var htmlContent = htmlContent1
            return htmlContent
        };
        if (memecoin == 'true') {

            var htmlContent = htmlContent2
            return htmlContent
        }
    };






    return (
        <PublishContext.Provider value={{ publishWebsite }}>
            {children}
        </PublishContext.Provider>
    );
}

export const usePublish = () => useContext(PublishContext);